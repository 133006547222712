<template>
  <img :src="imgSrc" :alt="brand" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'IconCardBranded',
  props: { brand: String },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    imgSrc() {
      const supportedBrands = [
        'american-express',
        'diners-club',
        'discover',
        'maestro',
        'mastercard',
        'visa'
      ]

      const cardSource = supportedBrands.includes(this.brand)
        ? this.brand
        : 'default'

      return `/img/card-${cardSource}.svg`
    }
  }
}
</script>
