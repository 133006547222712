export const addCard = {
  addCard: 'Add card',
  addressUsedForAllCards: 'This address is used for all cards.',
  billingAddress: 'Billing Address',
  cardDetails: 'Card details',
  error: {
    cardNumber: 'Please enter a valid card number.',
    cardNumberCustomer: ({ customerName }) =>
      `Please enter a valid ${customerName} card number.`,
    cardSave: 'An error occurred. Please try again.',
    cvv: 'Please enter a valid CVV.',
    expiry: 'Please enter a valid expiration date.',
    postalCode: 'Please enter a valid ZIP code.',
    required: 'Field is required.',
    state: 'Please enter a valid state.'
  },
  mustBeCustomerCard: ({ customerName }) => `Must be a ${customerName} card.`,
  save: 'Save',
  saving: 'Saving'
}
