import Tracker from '@openreplay/tracker'
import { isLocalDev } from '@/util/environment'

let trackerInstance

export default {
  start({ userId }) {
    if (trackerInstance) return

    trackerInstance = new Tracker({
      projectKey: '8EcFyLD5uhM8jBNyxffM',
      __DISABLE_SECURE_MODE: isLocalDev()
    })

    trackerInstance.start({
      userID: userId
    })
  },
  setMetadata(key, value) {
    trackerInstance?.setMetadata(key, value)
  },
  stop() {
    trackerInstance?.stop()
  }
}
