import { store } from '@/store'
import { ATOMIC_BRANDING } from '@/util/constants'
import { get } from 'lodash-es'

export function getBranding() {
  const activeConnector = store.state.company.activeConnector
  const company = store.getters['company/selectedCompany']
  const logoSanitizationEnabled = store.getters['main/useSanitizedLogos']

  if (logoSanitizationEnabled) {
    const companyBranding = _getForegroundBranding(
      _hasBranding(company) ? company : activeConnector
    )
    return {
      foreground: {
        color: companyBranding.foreground.color,
        url: _getLogoBasedOnTheme(),
        logoBackgroundColor: '#FFFFFF'
      }
    }
  }
  // If the company and connector name match or companies connetors authenticator wants to be called out,
  // show only the company branding
  else if (
    company.name === activeConnector.name ||
    activeConnector.skipConnectorCallout
  ) {
    return _getForegroundBranding(
      _hasBranding(company) ? company : activeConnector
    )
  }
  // If the company and connector has branding, and the connector does not want to be called out
  // show both brandings
  else if (
    _hasBranding(company) &&
    _hasBranding(activeConnector) &&
    !activeConnector.skipConnectorCallout
  ) {
    return {
      ..._getBackgroundBranding(company),
      ..._getForegroundBranding(activeConnector)
    }
  }
  // If the company has branding, and the connector does not, show the company branding
  else if (_hasBranding(company) && !_hasBranding(activeConnector)) {
    return _getForegroundBranding(company)
  }
  // If the connector has branding, and the connector doesn't want to be called out, show the
  // connector branding
  else if (
    _hasBranding(activeConnector) &&
    activeConnector.skipConnectorCallout
  ) {
    return _getForegroundBranding(activeConnector)
  }
  // If the connector has branding, and the company does not have branding
  // show the alternative logo and the connector branding
  else if (_hasBranding(activeConnector) && !_hasBranding(company)) {
    return {
      ..._getBackgroundBranding(company),
      ..._getForegroundBranding(activeConnector)
    }
  }
  // Otherwise fallback and show the secure lock icon
  else {
    return {
      foreground: {
        color: ATOMIC_BRANDING.BRAND_COLOR,
        url: '/img/connectors/default.svg'
      }
    }
  }
}

export function getBrandingColor(entity) {
  const useDarkMode = store.getters['theme/useDarkMode']
  const color = get(entity, 'branding.color')
  return useDarkMode
    ? checkForDarkBranding(color)
      ? ATOMIC_BRANDING.DARKMODE_BLACK_OVERRIDE
      : color
    : color
}

export function getReplacementLogoColors({ backgroundColor, replacementText }) {
  if (backgroundColor) return { backgroundColor }

  const colorList = [
    {
      text: 'rgba(71, 181, 212, 1)',
      bg: 'rgba(71, 181, 212, 0.2)'
    },
    {
      text: 'rgba(129, 72, 244, 1)',
      bg: 'rgba(129, 72, 244, 0.2)'
    },
    {
      text: 'rgba(25, 221, 139, 1)',
      bg: 'rgba(25, 221, 139, 0.2)'
    },
    {
      text: 'rgba(244, 62, 189, 1)',
      bg: 'rgba(244, 62, 189, 0.2)'
    }
  ]

  const colorListIndex = replacementText
    ? replacementText.charCodeAt(0) % colorList.length
    : 0

  const selectedColor = colorList[colorListIndex]

  return {
    bg: selectedColor.bg,
    text: selectedColor.text
  }
}

export function getLogoBackgroundColor(entity) {
  const useDarkMode = store.getters['theme/useDarkMode']
  const logoBackgroundColor =
    get(entity, 'branding.logo.backgroundColor') ||
    get(entity, 'branding.color')
  return useDarkMode
    ? checkForDarkBranding(logoBackgroundColor)
      ? ATOMIC_BRANDING.DARKMODE_BLACK_OVERRIDE
      : logoBackgroundColor
    : logoBackgroundColor
}

export function getBrandingUrl(entity) {
  return get(entity, 'branding.logo.url')
}

export function customerBrandedButtons({ style, color }) {
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_SOLID) {
    return {
      backgroundColor: color || ATOMIC_BRANDING.BRAND_COLOR,
      color: ATOMIC_BRANDING.BUTTON_TEXT_COLOR
    }
  }
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_HOLLOW) {
    return {
      backgroundColor: 'transparent',
      borderColor: color || ATOMIC_BRANDING.BRAND_COLOR,
      color: color || ATOMIC_BRANDING.BRAND_COLOR
    }
  }
}

export function taskBrandedButtons({ style, color }) {
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_SOLID) {
    return {
      backgroundColor: color || ATOMIC_BRANDING.BRAND_COLOR,
      color: ATOMIC_BRANDING.BUTTON_TEXT_COLOR
    }
  }
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_HOLLOW) {
    return {
      backgroundColor: 'transparent',
      borderColor: color || ATOMIC_BRANDING.BRAND_COLOR,
      color: color || ATOMIC_BRANDING.BRAND_COLOR
    }
  }
}

export function atomicBrandedButtons({ style }) {
  const useDarkMode = store.getters['theme/useDarkMode']
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_SOLID) {
    return {
      backgroundColor: ATOMIC_BRANDING.BRAND_COLOR,
      color: ATOMIC_BRANDING.BUTTON_TEXT_COLOR
    }
  }
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_HOLLOW) {
    return {
      backgroundColor: 'transparent',
      borderColor: useDarkMode ? 'rgba(51, 55, 77, 1)' : '#edeff5',
      color: useDarkMode ? 'rgba(255, 255, 255, 1)' : '#33374D'
    }
  }
}

export function customBrandedButtons({ style, colors }) {
  if (style === ATOMIC_BRANDING.BUTTON_STYLE_SOLID) {
    return {
      backgroundColor: colors.background,
      color: colors.foreground
    }
  }
}

export function linkStyles(color) {
  const useDarkMode = store.getters['theme/useDarkMode']
  const overrideColorDefaults = store.getters['theme/overrideColorDefaults']
  const customerBrandColor = store.getters['theme/customerBrandColor']
  const linkColor = store.getters['theme/linkColor']

  if (useDarkMode) {
    return { color: '#c1c4d6' }
  } else if (overrideColorDefaults && linkColor) {
    return { color: linkColor }
  } else if (overrideColorDefaults && !linkColor) {
    return { color: customerBrandColor }
  } else if (color) {
    return { color }
  } else {
    return {
      color: '#4d5373'
    }
  }
}

export function checkForDarkBranding(color) {
  const blackVariations = [
    '#000',
    '#000000',
    'rgb(0,0,0)',
    'rgb(0, 0, 0)',
    'rgba(0,0,0,1)',
    'rgba(0, 0, 0, 1)',
    '#030303'
  ]
  return blackVariations.includes(color)
}

function _hasBranding(entity) {
  return getBrandingColor(entity) && getBrandingUrl(entity)
}

function _getBackgroundBranding(entity) {
  return {
    background: {
      color: getBrandingColor(entity),
      url: getBrandingUrl(entity),
      logoBackgroundColor: getLogoBackgroundColor(entity)
    }
  }
}

function _getForegroundBranding(entity) {
  return {
    foreground: {
      color: getBrandingColor(entity),
      url: getBrandingUrl(entity),
      logoBackgroundColor: getLogoBackgroundColor(entity)
    }
  }
}

function _getLogoBasedOnTheme() {
  return (
    (store.getters['theme/useDarkMode'] &&
      store.state.main.features?.logoSanitization?.logos?.darkMode?.url) ||
    store.state.main.features?.logoSanitization?.logos?.lightMode?.url
  )
}

export function getBrandingLogo(branding) {
  return {
    src: _getBrandingLogoImgSrc(branding),
    background: _getBrandingLogoBackgroundColor(branding)
  }
}

function _getBrandingLogoImgSrc(branding) {
  return branding?.logo?.url
}

function _getBrandingLogoBackgroundColor(branding) {
  let backgroundColor = branding?.logo?.backgroundColor
    ? branding.logo.backgroundColor
    : branding?.color
  return _adjustBrandingBackgroundForDarkMode(backgroundColor)
}

function _adjustBrandingBackgroundForDarkMode(color) {
  return store.getters['theme/useDarkMode'] && checkForDarkBranding(color)
    ? ATOMIC_BRANDING.DARKMODE_BLACK_OVERRIDE
    : color
}
