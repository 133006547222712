<template>
  <span
    class="logo-wrap"
    :style="wrapStyleOverrides"
    :class="{
      border: showBorder,
      dark: useDarkMode,
      replacement: showReplacement
    }"
  >
    <transition name="logo-fade">
      <span
        v-show="loaded || !imgSrc"
        class="logo-container"
        :class="{ xSmall, small, smallPlus, medium, large }"
        aria-hidden="true"
      >
        <DecorativeImage
          v-if="imgSrc"
          class="logo"
          :src="imgSrc"
          :key="imgSrc"
          @load="loaded = true"
          aria-hidden="true"
        />
        <span
          :style="{ color: replacementColors.text }"
          v-else-if="replacementText"
        >
          {{ replacementText.charAt(0) }}
        </span>
        <span v-else></span>
      </span>
    </transition>
  </span>
</template>

<script>
import DecorativeImage from '@/components/Shared/DecorativeImage.vue'
import { getReplacementLogoColors } from '@/util/branding.js'
import { mapGetters } from 'vuex'

export default {
  name: 'LogoWrap',
  props: {
    imgSrc: String,
    backgroundColor: String,
    size: Number,
    // Todo: On design update, change to single prop paddingSize
    xSmall: Boolean,
    small: Boolean,
    smallPlus: Boolean,
    medium: Boolean,
    large: Boolean,
    borderRadius: Number,
    replacementText: String
  },
  components: {
    DecorativeImage
  },
  data: () => ({
    loaded: false
  }),
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    wrapStyleOverrides() {
      return {
        background: this.logoBackgroundColor,
        width: `${this.size}px`,
        height: `${this.size}px`,
        'min-width': `${this.size}px`,
        'min-height': `${this.size}px`,
        'border-radius': `${this.borderRadius || (10 / 40) * this.size}px`,
        'font-size': `${this.size / 2}px`
      }
    },
    logoBackgroundColor() {
      return this.imgSrc ? this.backgroundColor : this.replacementColors.bg
    },
    replacementColors() {
      return getReplacementLogoColors({ replacementText: this.replacementText })
    },
    showBorder() {
      if (this.showReplacement) return false

      return !this.useDarkMode && this.backgroundColor === '#FFFFFF'
    },
    showReplacement() {
      return this.imgSrc ? false : true
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-wrap {
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition: width 250ms, height 250ms;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);

  &.border {
    border: 1px solid var(--gray-200) !important;

    .dark & {
      border: 1px solid rgb(255 255 255 / 10%);
      box-shadow: 0px 1px 5px 2px rgb(16 24 40 / 15%);
    }
  }

  &.replacement {
    border: none !important;
  }

  .dark & {
    border: 1px solid rgb(255 255 255 / 10%);
    box-shadow: 0px 1px 5px 2px rgb(16 24 40 / 15%);
  }
}

span {
  display: block;
}

.logo-container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  height: 100%;

  &.xSmall {
    padding: 0;
  }

  &.small {
    padding: 4px;
  }

  &.smallPlus {
    padding: 6px;
  }

  &.medium {
    padding: 8px;
  }

  &.large {
    padding: 8px;
  }
}

.logo {
  display: block;
  height: 100%;
  object-fit: scale-down;
  position: relative;
  transition: none;
  width: 100%;
}

.logo-fade-enter-active,
.logo-fade-leave-active {
  transition: opacity 150ms linear;
}

.logo-fade-enter-from,
.logo-fade-leave-to {
  opacity: 0;
}

#listButton-undefined .logo-wrap {
  border: none;
  background: var(--gray-100) !important;

  .dark & {
    background: var(--gray-900) !important;
  }
}
</style>
