import { PROGRESS_STEP_STATUS, PROGRESS_STEP_TYPES } from '@/util/constants'

const defaultProgressData = () => ({
  progressHook: {},
  progressFlow: { steps: [] },
  initialCompletedProgressSteps: [],
  currentProgressStep: undefined,
  currentProgressStepIdx: 0,
  hookAnimationTimer: null
})

const state = {
  ...defaultProgressData()
}

const actions = {
  updateProgressFlowSteps({ commit }, steps) {
    commit('setProgressFlowSteps', steps)
  },
  updateCurrentProgressStepIdx({ commit }, currentProgressStepIdx) {
    commit('setCurrentProgressStepIdx', currentProgressStepIdx)
  },
  updateCurrentProgressStep({ commit, state }) {
    commit(
      'setCurrentProgressStep',
      state.progressFlow.steps[state.currentProgressStepIdx]
    )
  },
  updateCurrentProgressStepStatus({ commit }, status) {
    commit('setCurrentProgressStepStatus', status)
  },
  updateProgressHook({ commit }, progressHook) {
    commit('setProgressHook', progressHook)
  },
  updateProgressHookPulse({ commit }, pulseValue) {
    commit('setProgressHookPulse', pulseValue)
  },
  updateInitialCompletedProgressSteps(
    { commit },
    initialCompletedProgressSteps
  ) {
    commit('setInitialProgressCompletedSteps', initialCompletedProgressSteps)
  },
  handleInitialCompletedProgressSteps({ state, commit }) {
    state.initialCompletedProgressSteps.forEach((stepType) => {
      const existingStepIdx = state.progressFlow.steps.findIndex(
        (step) => step.type === stepType
      )
      if (existingStepIdx >= 0) {
        commit('setProgressFlowStepStatus', {
          stepIdx: existingStepIdx,
          status: PROGRESS_STEP_STATUS.COMPLETED
        })
      }
    })
  },
  startHookAnimationTimer({ commit, dispatch }, hookAnimationTimer) {
    dispatch('clearHookAnimationTimer')
    commit('setHookAnimationTimer', hookAnimationTimer)
  },
  clearHookAnimationTimer: ({ commit, state }) => {
    clearTimeout(state.hookAnimationTimer)
    commit('setHookAnimationTimer', undefined)
  },
  clearProgressData({ commit }) {
    commit('setProgressData')
  }
}

const getters = {
  hasActiveProgressStep: (state) => {
    return !!state.progressFlow.steps[state.currentProgressStepIdx]
  },
  isAuthenticated: (state) => {
    return !!(
      state.progressFlow.steps?.find(
        (step) => step.type === PROGRESS_STEP_TYPES.AUTHENTICATION
      )?.status === PROGRESS_STEP_STATUS.COMPLETED
    )
  }
}

const mutations = {
  setProgressHook: (state, progressHook) => {
    state.progressHook.track = progressHook.track
    state.progressHook.fill = progressHook.fill
    state.progressHook.state = progressHook.state
  },
  setProgressHookPulse: (state, pulseValue) => {
    state.progressHook.pulse = pulseValue
  },
  setProgressFlowSteps: (state, steps) => {
    state.progressFlow.steps = steps
  },
  setCurrentProgressStep: (state, currentProgressStep) => {
    state.currentProgressStep = currentProgressStep
  },
  setCurrentProgressStepIdx: (state, currentProgressStepIdx) => {
    state.currentProgressStepIdx = currentProgressStepIdx
  },
  setCurrentProgressStepStatus: (state, status) => {
    state.progressFlow.steps[state.currentProgressStepIdx].status = status
  },
  setProgressFlowStepStatus: (state, { stepIdx, status }) => {
    state.progressFlow.steps[stepIdx].status = status
  },
  setInitialProgressCompletedSteps: (state, initialCompletedProgressSteps) => {
    state.initialCompletedProgressSteps = initialCompletedProgressSteps
  },
  setProgressData: (state) => {
    Object.assign(state, defaultProgressData())
  },
  setHookAnimationTimer: (state, timer) => {
    state.hookAnimationTimer = timer
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
