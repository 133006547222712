export const sparkDriverManual = {
  title: 'Configura el depósito directo de Spark',
  step1: {
    title: 'Visita este enlace:',
    link: 'https://my.ddiwork.com/one/signup'
  },
  step2: {
    title: 'Inicia sesión en su cuenta de Spark Driver™.'
  },
  step3: {
    title: 'Seleccione Uno como su método de obtención de Spark Driver™.'
  },
  showAccountNumbers: 'Mostrar números de cuenta'
}
