const state = {
  authenticators: [],
  authenticatorId: '',
  authenticatorOptional: true,
  loadingAuthenticator: false,
  preCoAuthAuthenticatorId: ''
}

const actions = {
  preCoAuthBackup({ commit, state }) {
    commit('setPreCoAuthAuthenticatorId', state.authenticatorId)
  },

  preCoAuthRestore({ commit, state }) {
    commit('setAuthenticatorId', state.preCoAuthAuthenticatorId)
  },

  updateAuthenticatorId({ commit }, payload) {
    commit('setAuthenticatorId', payload)
  },

  updateAuthenticators({ commit }, payload) {
    commit('setAuthenticators', payload)
  },

  updateAuthenticatorOptional({ commit }, payload) {
    commit('setAuthenticatorOptional', payload)
  },

  updateLoadingAuthenticator({ commit }, payload) {
    commit('setLoadingAuthenticator', payload)
  }
}

const mutations = {
  setAuthenticatorId: (state, value) => {
    state.authenticatorId = value
  },
  setAuthenticators: (state, authenticators) => {
    state.authenticators = authenticators
  },
  setAuthenticatorOptional: (state, value) => {
    state.authenticatorOptional = value
  },
  setLoadingAuthenticator: (state, value) => {
    state.loadingAuthenticator = value
  },
  setPreCoAuthAuthenticatorId: (state, value) => {
    state.preCoAuthAuthenticatorId = value
  }
}

const getters = {
  selectedAuthenticator: (state) => {
    return state.authenticators.find(({ _id }) => _id === state.authenticatorId)
  },
  usingAuthenticator: (state) => {
    return !!state.authenticatorId
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
