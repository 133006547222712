export default async function ({ store, payload }) {
  const stringifiedPayload = JSON.stringify(payload)

  const matchers = [{ key: 'atomic', interpolator: _interpolateFromFormData }]

  let interpolatedPayload = stringifiedPayload

  matchers.forEach(({ key, interpolator }) => {
    let payloadMatches = stringifiedPayload.match(
      new RegExp(`{${key}}.*?{/${key}}`, 'g')
    )

    payloadMatches?.forEach((match) => {
      const value = interpolator({ store, match })
      interpolatedPayload = interpolatedPayload.replace(match, value)
    })
  })

  return JSON.parse(interpolatedPayload)
}

function _interpolateFromFormData({ store, match }) {
  const key = match.replace('{atomic}', '').replace('{/atomic}', '')
  return store.state.formFlow.formData?.auth[key]?.value
}
