import { invoke } from '@/util/native/invoke'
import { features } from '@/util/native/methods/features'
import { triggerOld } from '@/util/native/trigger-old'
import { store } from '@/store'
import { USER_DEVICE_AUTOMATION_EVENTS } from '@/util/constants'
import { getNetworkOnDeviceScript } from '@/util/client-side-automation/sdk-scripts'
import { userAgent } from '@/util/client-side-automation'

import * as events from '@/util/native/events'
import {
  convertHeadersToStrings,
  getHeader,
  runRequestBeforeHook
} from '@/util/native/helpers/request'

export async function request(options) {
  const { REQUEST } = await features()

  // Disabling until we need these again
  // Analytics.get().track({
  //   event: 'Client Uplink Request Received',
  //   payload: {
  //     traceId: options.traceId
  //   },
  //   internal: true
  // })

  let response

  options = await runRequestBeforeHook({ store, request: options })
  options.headers = convertHeadersToStrings(options.headers)

  if (!getHeader(options.headers, 'User-Agent'))
    options.headers['User-Agent'] = userAgent()

  if (!REQUEST) {
    response = await _requestOld(options)
  } else {
    response = await _requestNew(options)
  }

  // Disabling until we need these again
  // Analytics.get().track({
  //   event: 'Client Uplink Response',
  //   payload: {
  //     traceId: options.traceId
  //   },
  //   internal: true
  // })

  return response
}

async function _requestOld(options) {
  const script = getNetworkOnDeviceScript({
    axiosParameters: options
  })

  const payload = {
    stringifiedFunctionToEvaluate: `async () => { ${script} }`,
    functionParameters: {},
    script: `return (async () => { ${script} })()`
  }

  const message = await triggerOld({
    type: USER_DEVICE_AUTOMATION_EVENTS.EVALUATE_ON_DEVICE,
    payload
  })

  return (
    message.payload?.response?.response ||
    message.payload.response ||
    message.payload
  )
}

async function _requestNew(options) {
  const message = await invoke({
    type: events.REQUEST,
    payload: options
  })

  return message.payload
}
