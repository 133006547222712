import { detail } from './detail'
import { searchCompany } from './search-company'
import { addCard } from './add-card'
import { shared } from './shared'
import { home } from './home'
import { welcome } from './welcome'

export const payLink = {
  detail,
  addCard,
  searchCompany,
  shared,
  home,
  welcome
}
