import anime from 'animejs'

const FADE_OUT_SKELETON = {
  targets: '.skeleton-template-wrap',
  opacity: [1, 0],
  duration: 200
}

const SEND_SKELETON_BACKWARD = {
  targets: '.skeleton-template-wrap',
  zIndex: [2, -1],
  duration: 1
}

const SCALE_DOWN_SKELETON = {
  targets: '.skeleton-template-wrap',
  scale: [1, 0],
  duration: 1,
  easing: 'linear'
}

export function hideSkeletonLoader({ fadeDelay, store } = {}) {
  if (!store.state.animation.skeletonLoaderVisible) return

  anime
    .timeline({ easing: 'linear', delay: fadeDelay || 300 })
    .add(FADE_OUT_SKELETON)
    .add(SEND_SKELETON_BACKWARD)
    .add(SCALE_DOWN_SKELETON)

  store.dispatch('animation/updateSkeletonLoaderVisibility', false)
}
