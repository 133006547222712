export const PAY_LINK_USER_STATUS = {
  /** Brand new user, has not made any selections */
  NOT_STARTED: 'not-started',
  /** Customer has preselected all companies */
  PRESELECTED: 'preselected',
  /** Has made some selections */
  SELECTING: 'selecting',
  /** Has attempted a PayLink task */
  IN_PROGRESS: 'in-progress',
  /** Has completed a task for every selection */
  FINISHED: 'finished'
}
