<template>
  <BadgeBase :backgroundColor="badgeBackgroundColor">
    <IconCheck :size="16" :stroke="iconColor" />
  </BadgeBase>
</template>

<script>
import BadgeBase from '@/components/Badge/BadgeBase.vue'
import IconCheck from '@/components/Icons/Atomic/IconCheck.vue'
import { getGlobalCSSValue } from '@/util/general'

export default {
  name: 'BadgeSuccess',
  components: {
    BadgeBase,
    IconCheck
  },
  computed: {
    badgeBackgroundColor() {
      return getGlobalCSSValue('--success')
    },
    iconColor() {
      return getGlobalCSSValue('--white')
    }
  }
}
</script>
