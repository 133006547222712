export async function waitForTruthyFunctionResult({ func, options }) {
  let done = false
  let attempts = 0
  const { delay, retries } = { delay: 100, retries: 50, ...options }

  while (!(done = await func()) && !(retries && attempts++ > retries)) {
    if (delay > 0) await new Promise((resolve) => setTimeout(resolve, delay))
  }

  return done
}
