const shared = {
  title: 'Who do you pay?',
  suggestionsListLabel: 'Popular providers',
  zeroResultsFound: ({ searchText }) =>
    `Looks like we don’t yet support “${searchText}”`,
  zeroResultsFoundDescription: `We’re constantly working to increase coverage of providers.`
}

export const searchCompany = {
  switch: {
    ...shared,
    inputPlaceholder: 'Merchant or service provider'
  },
  present: {
    ...shared,
    inputPlaceholder: 'Merchants'
  }
}
