<template>
  <button
    class="text-button content-only focus-visible-width"
    :class="{ dark: useDarkMode }"
    :style="linkStyles"
    v-store-focused-element:click="handleClick"
    :data-test-id="dataTestId"
  >
    {{ text }}
  </button>
</template>
<script>
import { mapGetters } from 'vuex'
import { linkStyles } from '@/util/branding'

export default {
  name: 'TextButton',
  emits: ['handleClick'],
  props: {
    text: String,
    dataTestId: String
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    linkStyles() {
      return linkStyles()
    }
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    }
  }
}
</script>
<style lang="scss" scoped>
.text-button {
  cursor: pointer;
  margin-top: 16px;
  font-size: 1.6rem;
  line-height: 4.4rem;
  height: 44px;
  max-height: 44px;
  width: 100%;
  transition: opacity 250ms ease-in-out;

  &:hover,
  &:active {
    opacity: 0.7;
  }
}
</style>
