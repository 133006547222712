<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="mt-5"
      shape="box"
      shapeWidth="56px"
      shapeHeight="56px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="48px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="32px"
    />
    <SkeletonBlock
      class="mt-6 mx-auto image-container"
      shape="box"
      shapeWidth="100%"
      shapeHeight="220px"
    />
    <SkeletonBlock
      class="mt-auto"
      shape="box"
      shapeWidth="100%"
      shapeHeight="55px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'PayLinkPreLoginSkeleton',
  components: {
    SkeletonBlock,
    SkeletonTemplate
  }
}
</script>

<style scoped>
.image-container {
  max-width: 200px;
}
</style>
