export const searchCompany = {
  title: 'Encuentre su empleador',
  popularEmployersTitle: 'Empleadores populares',
  trySearching: 'Intenta buscar',
  inputPlaceholder: 'Buscar por empleador',
  minimalInputPlaceholder: 'Buscar por empleador',
  searchByState: 'Buscar por Estado',
  stateNotFound: 'No encuentra Estado',
  tryPayroll: 'Intenta buscar por empresa de nómina.',
  zeroResultsFound: ({ searchText }) =>
    `No pudimos encontrar resultados para "${searchText}" por lo que recomendamos usar la búsqueda por nómina en su lugar.`,
  payrollProvidersTitle: 'Proveedores de nómina',
  seeMore: 'Ver más',
  tags: {
    popular: 'Popular',
    payroll: 'Nómina',
    employer: 'Empleador',
    gig: 'Gig',
    govt: 'Govt',
    subscription: 'Suscripciones',
    cell: 'Móvil',
    cable: 'Cable y Internet',
    insurance: 'Seguro',
    card: 'Tarjetas'
  }
}
