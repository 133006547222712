export function getTextPhrase({ key, namespace, args, store }) {
  const phrase =
    _useDeprecatedCustomerPhrase({ key, store }) ||
    store?.getters['i18n/phrases']?.[namespace]?.[key]

  if (typeof phrase === 'function') {
    return phrase(args)
  }

  return phrase
}

function _useDeprecatedCustomerPhrase({ key, store }) {
  const language = store?.state?.i18n?.language

  return store?.state?.i18n?.custom?.[language]?.[key]
}
