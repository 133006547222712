export const configurableConnectorSearch = {
  findEmployerSearch: ({ connectorName }) =>
    `Iniciar sesión en ${connectorName}`,
  findEmployerSubtitle: 'Primero, encuentra su empleador',
  userProvidedOrgIdButton: ({ connectorName, fieldName }) =>
    `Intenta ${connectorName} ${fieldName}`, // "Try Workday Organization ID"
  requestCompanyButton: 'Solicitar empresa',
  noMatchTitle: ({ searchQuery }) =>
    `No pudimos encontrar un empleador que se llama "${searchQuery}".`,
  noMatchHelpOrgId: ({ connectorName, fieldName }) =>
    `Intenta urar el ${connectorName} ${fieldName} de su empleador`, //"Try using your employer's Workday Organization ID instead."
  noMatchHelpRequestCompany:
    'Envíe una solicitud y agregaremos a su empleador.',
  employerSearchPlaceholder: 'El nombre de su empleador',
  requestCompanyTitle: ({ companyName }) =>
    `Lo sentimos, necesitaremos un momento para agregar "${companyName}" a nuestra base de datos.`,
  requestCompanySubtitle:
    'Le sugerimos que vuelva a intentarlo más tarde después de que agreguemos a su empleador a nuestra lista.',
  findEmployerSearchResults: ({ searchQuery }) =>
    `Resultados de la búsqueda para "${searchQuery}"`
}
