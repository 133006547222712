import { v4 } from 'uuid'
import { sendInternalDeferredEvaluateAction } from '@/util/client-side-automation/deferred-action'
import openreplayMin from '@/util/client-side-automation/libraries/openreplay.min'

// @DEPRECATED
export function injectPasswordManagerInput({ store }) {
  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async () => {
        if (document.querySelector('[autocomplete="one-time-code"]')) {
          return
        }

        window.existingPasswordInput = document.querySelector(
          '#atomic-password-manager'
        )

        if (!window.existingPasswordInput) {
          window.existingPasswordInput = document.createElement('input')
          window.existingPasswordInput.id = 'atomic-password-manager'
          window.existingPasswordInput.type = 'password'
          window.existingPasswordInput.style.opacity = '0'
          window.existingPasswordInput.style.height = '0'
          window.existingPasswordInput.style.width = '0'
          document.body?.appendChild(window.existingPasswordInput)
        }
      }`
    }),
    store
  })
}

// @DEPRECATED
// This is used to force a DOM update on the page so that the DOM listeners
// on the native side fire and send us a DOM update
export function injectDomPollTrigger({ store }) {
  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async () => {
        window.domTriggerElement = document.createElement('div')
        window.domTriggerElement.id = 'atomic-dom-trigger'
        document.body?.appendChild(window.domTriggerElement)
      }`
    }),
    store
  })
}

// @DEPRECATED
export function injectOpenReplay({
  store,
  userId,
  companyId,
  connectorId,
  capturePayload
}) {
  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async ({ openreplayMin, companyId, openReplaySessionHash, connectorId }) => {
        if (!window.OpenReplay) {
          eval(openreplayMin)
          
          setTimeout(async () => {
            let checks = 0
            
            while (checks < 50 && !window.OpenReplay?.app?.session?.getSessionHash()) {
              checks++
              await new Promise(resolve => setTimeout(resolve, 100))
            }
            
            window.OpenReplay?.setMetadata('CompanyID', companyId)
            window.OpenReplay?.setMetadata('ConnectorID', connectorId)
            
            window.webkit?.messageHandlers['internal-message'].postMessage({
              type: 'internal-message',
              message: { openReplaySessionHash: window.OpenReplay?.app?.session?.getSessionHash(), checks }
            })
          }, 50)
        }
      }`,
      functionParameters: {
        openreplayMin: openreplayMin({
          userId,
          openReplaySessionHash:
            store.state.userDeviceAutomation.activeSession
              .openReplaySessionHash,
          capturePayload
        }),

        companyId,
        connectorId
      }
    }),
    store
  })
}

// @DEPRECATED
export function stopOpenReplayDeprecated({ store }) {
  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async () => {
        window.OpenReplay?.stop()
      }`
    }),
    store
  })
}

// @DEPRECATED
export function setOpenReplayMetadataDeprecated({ store, metadata }) {
  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async ({ metadata }) => {
        Object.entries(metadata).forEach(([key, value]) => {
          window.OpenReplay?.setMetadata(key, value)
        })
      }`,
      functionParameters: { metadata }
    }),
    store
  })
}

// @DEPRECATED
export function injectRequestInterceptor({ store }) {
  const { requestHook, responseHook } =
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']

  sendInternalDeferredEvaluateAction({
    action: _buildInjector({
      stringifiedFunctionToEvaluate: `async () => {
        if (!window.addedRequestInterceptor) {
          window.broadcastInterceptedRequest = (request) => {
            window.TransactEvents?.emit('internal-intercepted-request', JSON.stringify({ request }))
              
            window.webkit?.messageHandlers['internal-intercepted-request'].postMessage({
              type: 'internal-intercepted-request',
              request
            })
          }
          
          window.getLowerCasedHeaders = (headers = {}) => {
            let lowerCasedHeaders = {}
            
            Object.entries(headers).forEach(([key, value]) => {
              lowerCasedHeaders[key.toLowerCase()] = value
            })
            
            return lowerCasedHeaders
          }

          let requestHook
          let responseHook

          try {
            requestHook = ${requestHook}
            responseHook = ${responseHook}
          } catch (error) {
            console.error('Error loading interceptors', error)
          }
        
          window.originalFetch = window.fetch
        
          window.fetch = async (...args) => {
            const [resource, config] = args;
            const isRequestObject = resource instanceof Request
            let interceptedRequestData = {}
            let interceptedResponseData = {}
            let requestBody
            let requestHeaders = {}
            let lowerCasedRequestHeaders
            
            try {
              const headers = isRequestObject ? resource.headers : config?.headers

              if (headers instanceof Headers) {
                headers.forEach((value, key) => {
                  requestHeaders[key] = value
                })
              } else {
                requestHeaders = headers
              }

              lowerCasedRequestHeaders = window.getLowerCasedHeaders(requestHeaders)
            
              const body = isRequestObject ? await resource.clone().text() : config?.body
              requestBody =
                lowerCasedRequestHeaders['content-type']?.includes('application/json') &&
                typeof body === 'string'
                  ? JSON.parse(body)
                  : body
            } catch (error) {
              console.error('Error parsing fetch request', error)
            }

            try {
              interceptedRequestData =
                requestHook({
                  url: isRequestObject ? resource.url : resource,
                  method: isRequestObject ? resource.method : config?.method,
                  requestBody,
                  requestHeaders: lowerCasedRequestHeaders,
                  requestType: 'fetch',
                  rawRequest: resource instanceof Request ? resource : config
                }) ?? {}
            } catch (error) {
              console.error('Error running request interceptor', error)
            }
            
            const response = await window.originalFetch(resource, config)
            
            const responseHeaders = Object.fromEntries(response.headers)
            const lowerCasedResponseHeaders = window.getLowerCasedHeaders(responseHeaders)
                        
            try {
              const responseCopy = response.clone() // body can only be read once
              
              const responseBody = lowerCasedResponseHeaders['content-type']?.includes('application/json')
                ? await responseCopy.json()
                : await responseCopy.text()

              interceptedResponseData = responseHook({
                url: response.url,
                method: isRequestObject ? resource.method : config?.method,
                status: response.status,
                requestBody,
                responseBody,
                requestHeaders: lowerCasedRequestHeaders,
                responseHeaders: lowerCasedResponseHeaders,
                requestType: 'fetch',
                rawResponse: response,
              }) ?? {} 
            } catch (error) {
              console.error('Error running response interceptor', error)
            }
            
            window.broadcastInterceptedRequest({
              requestHeaders: lowerCasedRequestHeaders,
              responseHeaders: lowerCasedResponseHeaders,
              interceptedData: { ...interceptedRequestData, ...interceptedResponseData }
            })

            return response
          }
        
        
          const openCopy = XMLHttpRequest.prototype.open
          const sendCopy = XMLHttpRequest.prototype.send
          
          XMLHttpRequest.prototype.open = function(method, url) {
            this.url = url
            this.method = method

            this.addEventListener('load', function() {
              let interceptedResponseData

              const responseHeaders = Object.fromEntries(
                new Map(
                  (this.getAllResponseHeaders() ?? '').split('\\r\\n').filter(Boolean).map(header => header.split(': '))
                )
              )

              const lowerCasedRequestHeaders = window.getLowerCasedHeaders(this.requestHeaders)
              const lowerCasedResponseHeaders = window.getLowerCasedHeaders(responseHeaders)

              try {
                const responseBody = lowerCasedResponseHeaders['content-type']?.includes('application/json') ? JSON.parse(this.response) : this.response
                
                interceptedResponseData = responseHook({
                  url: this.url,
                  method: this.method,
                  status: this.status,
                  requestBody: this.requestBody,
                  responseBody,
                  requestHeaders: lowerCasedRequestHeaders,
                  responseHeaders: lowerCasedResponseHeaders,
                  requestType: 'xhr',
                  rawResponse: this
                })
              } catch (error) {
                console.error('Error running response interceptor', error)
              }
            
              window.broadcastInterceptedRequest({
                requestHeaders: lowerCasedRequestHeaders,
                responseHeaders: lowerCasedResponseHeaders,
                interceptedData: interceptedResponseData ?? {}
              })
            })
            
            openCopy.apply(this, arguments)
          }
          
          XMLHttpRequest.prototype.wrappedSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader
          
          XMLHttpRequest.prototype.setRequestHeader = function(header, value) {
            this.wrappedSetRequestHeader(header, value)
        
            this.requestHeaders = this.requestHeaders || {}
            this.requestHeaders[header.toLowerCase()] = value
          }
          
          XMLHttpRequest.prototype.send = function(body) {
            let interceptedRequestData
            
            try {
              const lowerCaseRequestHeaders = window.getLowerCasedHeaders(this.requestHeaders)
              this.requestBody = lowerCaseRequestHeaders['content-type']?.includes('application/json') && typeof body === 'string'
                ? JSON.parse(body) 
                : body

              interceptedRequestData = requestHook({ 
                url: this.url,
                method: this.method,
                requestBody: this.requestBody,
                requestHeaders: lowerCaseRequestHeaders,
                requestType: 'xhr',
                rawRequest: this,
              })
            } catch (error) {
              console.error('Error running request interceptor', error)
            }
            
            window.broadcastInterceptedRequest({
              interceptedData: interceptedRequestData ?? {}
            })

            sendCopy.apply(this, arguments)
          }

          window.addedRequestInterceptor = true
        }
      }`
    }),
    store
  })
}

// NOTE: This matches up with the backend schema for sending evaluate requests
function _buildInjector({
  stringifiedFunctionToEvaluate,
  functionParameters = {}
}) {
  return {
    messageUUID: v4(),
    stringifiedFunctionToEvaluate,
    functionParameters
  }
}
