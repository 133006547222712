<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="logo-skeleton"
      shape="box"
      shapeWidth="80%"
      shapeHeight="50px"
    />

    <SkeletonBlock
      class="title-skeleton mt-2 mb-1"
      shape="box"
      shapeWidth="90%"
      shapeHeight="24px"
    />

    <SkeletonBlock
      class="paragraph-skeleton"
      shape="box"
      shapeWidth="70%"
      shapeHeight="16px"
    />
    <SkeletonBlock
      class="btn-skeleton my-4"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />

    <div class="d-flex mt-1">
      <SkeletonBlock
        class="powered-by-skeleton"
        shape="box"
        shapeWidth="120px"
        shapeHeight="16px"
      />
    </div>
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'PayLinkWelcomeSkeleton',
  components: {
    SkeletonTemplate,
    SkeletonBlock
  }
}
</script>

<style lang="scss" scoped>
.icon-skeleton {
  margin-right: 8px;
  z-index: 2;
}
.skeleton-template {
  justify-content: flex-end;
  align-items: center;
}
</style>
