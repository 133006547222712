export const security = {
  title: 'Keeping your data secure',
  intro:
    'Our information security plan is built to meet or exceed industry standards on account security.',
  bullet1Title: 'AES Encryption',
  bullet1Text:
    'We use AES-256-CBC. AES is considered the gold-standard for encryption, is NIST certified, and is what the government uses to encrypt secure data.',
  bullet2Title: 'Strict TLS',
  bullet2Text:
    'We only support the latest, and stricted form of Transport Layer Security (TLS) to secure network communications.',
  bullet3Title: 'Strong Authentication',
  bullet3Text:
    'All of our critical systems use Multi-Factor Authentication (MFA) to keep intruders out.',
  bullet4Title: 'Security Audits',
  bullet4Text:
    'Security researchers regularly audit our security controls against industry standards to help maintain our security plan.'
}
