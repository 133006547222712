export const socialSecurityInterstitial = {
  title: '¿Qué tipo de Seguro Social recibe?',
  socialSecurityBenefits: 'Beneficios de Seguro Social',
  socialSecurityHelperText: 'Jubilación, discapacidad, sobreviviente',
  supplementSecurityIncome: 'Suplemento de Seguridad de Ingreso',
  supplementSecurityIncomeHelperText:
    'Ingresos limitados, disababilidad o mayores de 65 años',
  both: 'Ambos',
  bothHelpertext: 'Beneficios de Seguro Social & SSI',
  ssaEmployer: `Soy empleado de la SSA`,
  noDoNot: 'No recibo Seguro Social'
}
