<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="back-icon-svg"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'IconBack'
}
</script>

<style scoped lang="scss">
.back-icon-svg {
  // the default of inline-block results in elements containing this one to have a small amount of extra height
  // display: block forces elements containing this one to be perfectly flush to the height of the SVG
  display: block;
  color: var(--gray-600);
  transition: color 200ms ease-in-out;

  &.dark {
    color: var(--gray-500);
  }
}
</style>
