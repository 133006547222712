export function createPayLinkService({ makeRequest }) {
  let pciEndpoint

  return {
    /** For injecting runtime dependencies */
    initialize({ pciEndpoint: _pciEndpoint }) {
      pciEndpoint = _pciEndpoint
    },
    async patchSelections({ companyIdsToAdd = [], selectionIdsToRemove = [] }) {
      return makeRequest({
        method: 'PATCH',
        endpoint: '/pay-link/selections',
        data: {
          companyIdsToAdd,
          selectionIdsToRemove
        }
      })
    },
    async refreshSelections() {
      return makeRequest({
        method: 'POST',
        endpoint: '/pay-link/refresh-selections'
      })
    },
    async addCard({ card, identity }) {
      return makeRequest({
        method: 'PUT',
        endpoint: '/user/add-card',
        baseUrl: pciEndpoint,
        data: {
          card,
          identity
        }
      }).catch((error) => {
        /**
         * Logging error from frontend since the error may originate from the
         * PCI endpoint, which isn't integrated with DataDog.
         * (Sentry captures console.error.)
         */
        console.error(error)
        throw error
      })
    }
  }
}
