export const directDepositForm = {
  directDepositAuthorizationForm: 'Autorización de depósito directo',
  legalText: ({ customerName }) =>
    `Este formulario declara que deseo configurar el depósito directo para mi cuenta de ${customerName}. Incluye los números de cuenta y de ruta relevantes, junto con una copia de mi cheque anulado. Si la información proporcionada aquí no es suficiente, por favor házmelo saber.`,
  employeeNameLabel: 'Titular de la cuenta',
  bankNameLabel: 'Nombre del banco',
  accountTypeLabel: 'Tipo de cuenta',
  payTo: 'Pagar a la orden de',
  dollars: 'Dólares',
  routingNumber: 'Número de ruta',
  accountNumber: 'Número de cuenta',
  voidedCheckTitle: 'Copia del cheque anulado',
  void: 'ANULADO',
  accountDetailsTitle: 'Detalles de la cuenta',
  checkDescription:
    'La imagen de este cheque anulado puede ser proporcionada a su empleador u otro pagador con el único propósito de configurar el depósito directo.',
  authorizationTitle: 'Autorización',
  iAuthorize: ({ customerName }) =>
    `Autorizo a mi empleador a depositar mis cheques de pago directamente en mi cuenta de ${customerName}. `,
  iWish: ({ amount }) => `${amount} `,
  signature: 'Firma',
  date: 'Fecha',
  footerCopyrightFDIC: ({ customerName }) => `${customerName} Miembro de FDIC.`,
  footerCopyrightNCUA: ({ customerName }) => `${customerName} Miembro de NCUA.`
}
