<template>
  <div class="terms-conditions-wrap">
    <h5>Exhibit A</h5>
    <h2>Privacy Policy</h2>
    <p>Last modified: June 11, 2024</p>
    <p>
      We take your privacy very seriously. This Privacy Policy (&ldquo;Privacy
      Policy&rdquo;) is meant to help you understand how Atomic FI, Inc.
      (&ldquo;Atomic,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;) collects and
      processes your information and data in connection with the products and
      services we offer (each, a &ldquo;Service&rdquo;), other than those
      related to our public-facing website, marketing and events, which are
      governed by a separate privacy policy.&nbsp;
    </p>
    <p>
      Please read our Privacy Policy carefully before indicating your acceptance
      and check the current Privacy Policy each time you use a Service, as it
      may change over time. Accessing or using a Service indicates that you
      accept and agree to be bound by the version of this Privacy Policy
      displayed to you at that time. If you do not accept this Privacy Policy,
      do not access or use the Service. You acknowledge that (a)you have read
      and understood this Privacy Policy; and (b) this Privacy Policy shall have
      the same force and effect as a signed agreement.
    </p>
    <p>
      <strong
        >The Services are operated by Atomic FI, Inc., 2890 East Cottonwood
        Parkway, Suite 400, Cottonwood Heights, UT 84121 USA.</strong
      >
      This Privacy Policy describes the information collected through your use
      of the Services, how we use it, how we share it, how we protect it, and
      choices you can make about your information.
    </p>
    <h4>1. &nbsp;Information We Collect&nbsp;</h4>
    <p>
      <strong>Information From the Services</strong>. We may collect information
      about you when you use a Service, contact or interact with us, or
      voluntarily provide us with comments and other content in connection with
      using the Services.&nbsp;
    </p>
    <p>
      The Services provide a way for you to connect a third-party employment,
      ticketing, accounting or other application or service (&ldquo;Third-Party
      Application&rdquo;) with a mobile or web application or service
      (&ldquo;Partner Application&rdquo;) that is used by you and operated by
      our business partner or our business partner&rsquo;s customer
      (&ldquo;Partner&rdquo;). The specific information Atomic collects varies
      by Service and the Partner&rsquo;s requirements. Some of this information
      may be personal information. Please contact the Partner if you would like
      to know which Service(s) and feature(s) the Partner Application uses.
    </p>
    <ul>
      <li>
        <strong
          >When you authorize Atomic to access your Third-Party Application and
          you are not directed to the Third-Party Application to log in</strong
        >, we may collect authentication information for the Third-Party
        Application, such as your username, password, application programming
        interface (API) key, employee ID, date of birth, Social Security number,
        telephone number, email address, and security questions and answers. We
        also may collect your account information (such as your account and bank
        routing number) from the Partner and display it to you, for example,
        &nbsp;to help you complete manual instructions for a direct deposit
        switch.
      </li>
      <li>
        <strong
          >When you authorize a &ldquo;Monitoring&rdquo; feature to enable
          periodic updates,</strong
        >
        we may collect and store your authentication information for the linked
        account(s) to enable the Partner to obtain periodic updates of the
        information in your linked account(s). The relevant Partner should
        inform you, before you use the Service, that a Monitoring feature has
        been enabled. For more information about Monitoring and your choices
        related to it, please contact the Partner whose Partner Application you
        are using.&nbsp;
      </li>
      <li>
        <strong
          >When you authorize UserLink&rsquo;s &ldquo;Deposit&rdquo; feature to
          supply or update the bank account to which your direct deposit is
          credited</strong
        >, we may collect your account number, routing number, account type,
        distribution type, distribution amount, and recent direct deposit
        activity.
      </li>
      <li>
        <strong
          >When you authorize UserLink&rsquo;s &ldquo;Verify&rdquo; feature to
          supply your identity, income, and employment information</strong
        >, we may collect your employment information (income, statement,
        deposit account, employment, tax-related, identity, and timesheet
        information). We may derive inferences (such as annual salary) from some
        of the information. The UserLink documentation,
        https://docs.atomicfi.com/reference/employment-data, lists the specific
        data categories that may be supplied if requested by the Partner and
        available under the authentication information provided.
      </li>
      <li>
        <strong
          >When you authorize PayLink&rsquo;s &ldquo;Switch&rdquo; feature to
          supply or update your payment method information,</strong
        >
        we may collect your credit or debit payment card information (such as
        PAN, expiration date, CVV/CVC, and billing address (street address,
        city, state, zip)), or your ACH transfer information (such as bank
        account number, ABA routing number, account type, and optionally account
        title).
      </li>
    </ul>
    <p>
      <strong>Information From Third Parties.</strong>&nbsp;We may collect
      information, including personal information, about you from third-party
      sources when you use a Service. When you use the Service, you authorize us
      to collect information from your designated payroll provider or employer,
      financial institution, merchant, service provider, or other third party
      with which you do business, or from the technology companies and digital
      platforms providing services to any of them.
    </p>
    <p>
      <strong>Information From Your Device.</strong> We automatically collect
      information from you as a user of our Services, including your IP address,
      the Service features you use and the time of your use, your device
      information (e.g., device model and operating system version), which we
      de-identify, and other aggregated information that cannot be used to
      specifically identify you when you use the Services.&nbsp;
    </p>
    <p>
      We may combine any or all of the information we collect and use it in the
      manner described in this Privacy Policy.
    </p>
    <h4>2. &nbsp;How We Use Information&nbsp;</h4>
    <p>
      We use the information that we collect for several purposes, including:
    </p>
    <ul>
      <li>The purposes for which it was provided;</li>
      <li>
        To provide information and respond to requests for services from you,
        the provider of a Third-Party Application, or the Partner whose Partner
        Application you are using;
      </li>
      <li>To process and respond to your inquiries and comments;</li>
      <li>To administer, operate, and improve the Services;</li>
      <li>To enhance your experience using the Services;</li>
      <li>
        To generate and review reports and data about our user base and Services
        usage patterns;
      </li>
      <li>
        To analyze the accuracy, effectiveness, usability, or popularity of the
        Services;
      </li>
      <li>
        To compile aggregate data for internal and external business purposes;
      </li>
      <li>
        To prevent fraud and abuse of the Services and to otherwise protect you
        and our business;
      </li>
      <li>To assist law enforcement and respond to subpoenas; and</li>
      <li>
        To perform other business activities as needed, or as described
        elsewhere in this Privacy Policy or otherwise consented to by you.&nbsp;
      </li>
    </ul>
    <p>
      As explained elsewhere in this Privacy Policy, personal information we
      collect may be processed by our business partners to provide services
      related to the Service (such as administration services, technical
      services relating to the maintenance, servicing, and upgrading of
      software, hosting services, customer service, data migration services, and
      analytical services, among others).&nbsp;
    </p>
    <h4>3. &nbsp;How Information is Disclosed&nbsp;</h4>
    <p>
      Information may be disclosed to third parties in accordance with our
      Privacy Policy.
    </p>
    <p>
      <strong>Third-Party Service Providers and Business Partners</strong>. We
      may share your information with third-party service providers and business
      partners (including Partners) to perform functions in connection with the
      Service, such as analytics, relationship management, functions related to
      analyzing and improving the Service usefulness, reliability, user
      experience, and operation, storing data, and as otherwise described in
      this Privacy Policy.&nbsp;
    </p>
    <p>
      <strong>Partners</strong>. When you use the Service, you authorize us to
      share your information with the Partner providing the Partner Application.
    </p>
    <p>
      <strong>Third-Party Application Provider</strong>. When you use a Service,
      you authorize us to share your information with your financial
      institution, payroll provider, employer, or other provider of your
      Third-Party Application.
    </p>
    <p>
      <strong>Business Changes</strong>. If we become involved in a merger,
      acquisition, sale of assets, joint venture, securities offering,
      bankruptcy, reorganization, liquidation, dissolution, or other
      transaction, or if the ownership of all or substantially all our business
      otherwise changes, we may transfer your information to a third party or
      parties in connection therewith.&nbsp;
    </p>
    <p>
      <strong>Affiliates</strong>. We also may share your information with our
      affiliates for purposes consistent with this Privacy Policy. Our
      affiliates will be required to maintain that information in accordance
      with this Privacy Policy.
    </p>
    <p>
      <strong>Investigations and Law</strong>. We may disclose information about
      you to third parties if we believe that such disclosure is necessary to:
    </p>
    <ul>
      <li>
        Comply with the law or guidance from relevant governmental or regulatory
        authorities or cooperate with government or law enforcement officials or
        private parties;
      </li>
      <li>
        Investigate, prevent, or take action regarding suspected illegal
        activities, suspected fraud, the rights, reputation, safety, and
        property of us, users, or others, or violations of our policies or other
        agreements with us;
      </li>
      <li>
        Respond to claims and legal process (for example, subpoenas);
        and/or&nbsp;
      </li>
      <li>Protect against legal liability.</li>
    </ul>
    <p>
      <strong>Aggregated Information</strong>. We may share aggregated
      information relating to users of the Services with affiliated or
      unaffiliated third parties. This aggregated information does not contain
      personal information about any individual.
    </p>
    <p>
      <strong>Analytics</strong>. We use third-party analytics tools to better
      understand how people are using the Services. These tools aggregate
      deidentified data about our users&rsquo; use of the Services and their
      preferences and activities. This information may be used to analyze data
      and determine the effectiveness of certain features or content, among
      other things.
    </p>
    <h4>4. &nbsp;Security</h4>
    <p>
      We implement a variety of security measures to help protect your personal
      information when you enter, submit, or access your personal
      information.&nbsp;&nbsp;
    </p>
    <p>
      We maintain a comprehensive written information security program aimed at
      protecting the security, integrity, availability, resilience, and
      confidentiality of personal information that we collect, and that meets
      generally accepted industry standards. We additionally use
      industry-standard encryption tools, and we have implemented measures to
      protect the security and confidentiality of encryption keys associated
      with encrypted information. We also maintain physical access controls that
      secure relevant facilities, equipment, and hardware used in connection
      with processing personal information, including an access control system
      that enables us to monitor and control physical access to our facilities.
      We also implement controls to limit access to personal information. We
      abide by the &ldquo;principle of least privilege,&rdquo; pursuant to which
      we permit access to personal information by our personnel solely on a
      need-to-know basis.&nbsp;
    </p>
    <p>
      While we take reasonable measures to protect personal information against
      loss, theft, and unauthorized use, disclosure, or modification, we cannot
      guarantee its absolute security. No Internet, email, or mobile application
      transmission is ever fully secure or error free. Email and other messages
      sent through the Service may not be secure. You should use caution
      whenever submitting information on the Internet, including through the
      Services, and take special care in deciding with which information you
      provide us. WE ASSUME NO LIABILITY FOR DISCLOSURE OF YOUR INFORMATION DUE
      TO TRANSMISSION ERRORS, THIRD-PARTY ACCESS, OR CAUSES BEYOND OUR CONTROL.
    </p>
    <p>
      The Services are intended to be used in the United States. If you use our
      Services outside of the United States, you consent to having your data
      transferred to the United States.&nbsp;
    </p>
    <h4>5. &nbsp;Data Retention Policy, Managing Your Information</h4>
    <p>
      Except as otherwise permitted or required by applicable law or regulation,
      we may retain your information, or a portion thereof, for as long as you
      use the Services and for a reasonable time thereafter. We may maintain
      anonymized or aggregated data, including usage data, for analytics
      purposes. We may retain and use your information as necessary to comply
      with our legal obligations, resolve disputes, and/or for the period
      required by laws in applicable jurisdictions. Please note that some or all
      of the information we have collected may be required for the Service to
      function properly.
    </p>
    <h4>
      6. &nbsp;Links, Logos and Trademarks Related to Other Services or
      Applications
    </h4>
    <p>
      This Privacy Policy applies only to the Services. The Services may contain
      links to other websites or apps, may display the logos and trademarks of
      their providers, or may forward users to other websites or apps that we
      may not own or operate and to which this Privacy Policy does not apply.
      The use of links and third-party logos and trademarks does not imply that
      we endorse or have reviewed these websites or apps, or that we are
      affiliated with, sponsored by, or endorsed by their providers. The
      policies and procedures we describe in this Privacy Policy do not apply to
      these websites or apps. We cannot control and are not responsible for
      their privacy practices or content, and all rights in the providers&rsquo;
      logos and trademarks remain solely and exclusively with their respective
      owners. The logos and trademarks are used only to factually identify
      websites or apps that are compatible with the Services. We suggest
      contacting these providers directly for information on their privacy
      policies. Nonetheless, we seek to protect the integrity of our Services
      and welcome any feedback about these linked websites and mobile
      applications.
    </p>
    <h4>7. &nbsp;Your Choices Regarding Your Information</h4>
    <p>
      In those cases where we store your personal information, if you would like
      to change or update your information, you may email us at
      support@atomicfi.com. For some uses of your information, such as in the
      case of any of our UserLink monitoring features, you may need to contact
      the Partner.&nbsp;
    </p>
    <h4>8. &nbsp;Children</h4>
    <p>
      Our Services are not intended for children under 18 years of age. We do
      not knowingly collect personal information from an individual under age
      18. If you are under the age of 18, please do not submit any personal
      information through the Services. If you have reason to believe that we
      may have accidentally received personal information from an individual
      under age 18, please contact us immediately at support@atomicfi.com.
    </p>
    <h4>9. &nbsp;Changes to Privacy Policy</h4>
    <p>
      We reserve the right to change this Privacy Policy from time to time
      without prior notice. If we materially change this Privacy Policy, we will
      let you know by posting an updated Privacy Policy that will be shown to
      you the next time you use a Service. We also will notify Partners of any
      material changes, as they are often best positioned to apprise you of
      changes relevant to the Partner Application you are using.
    </p>
    <h4>10. &nbsp;How to Contact Us</h4>
    <p>
      Please feel free to contact us by email at support@atomicfi.com, or by
      writing to us at Atomic FI, Inc., 2890 East Cottonwood Parkway, Suite 400,
      Cottonwood Heights, UT 84121 USA, if you have any questions about this
      Privacy Policy or to ask about your privacy choices.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>
<style lang="scss" scoped>
.terms-conditions-wrap {
  padding-right: 1.6rem;

  h3,
  h4 {
    margin-top: 32px;
    line-height: 125%;
    font-size: 1.8rem;
  }

  p {
    line-height: 125%;
    margin-top: 16px;
    font-size: 14px;
    text-align: justify;

    .dark & {
      color: var(--gray-400);
    }
  }

  a {
    display: inline;
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 1;
    color: var(--info-500);

    .dark & {
      color: var(--info-300);
    }
  }

  ul {
    margin-top: 24px;
    padding: 0 24px 0 48px;
    list-style-type: circle;
    text-align: justify;

    li {
      line-height: 125%;
      margin-top: 16px;
      font-size: 1.4rem;
      color: var(--gray-700);
      text-align: justify;

      .dark & {
        color: var(--gray-400);
      }
    }
  }
}
</style>
