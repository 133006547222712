<template>
  <div
    class="pba"
    :class="{ dark: useDarkMode }"
    data-test-id="powered-by-atomic"
  >
    <span class="powered-by-atomic-text">{{ poweredByAtomic }}</span>
    <LegalDisclaimerActions
      v-show="paypalRequired"
      pairedWithPba
      :separated="paypalRequired"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LegalDisclaimerActions from '@/components/Legal/LegalDisclaimerActions.vue'
import { ROUTES } from '@/util/constants'
import {
  fadeInPoweredByAtomic,
  revealPbaPrivacyDisclaimerAnimation,
  hidePbaPrivacyDisclaimerAnimation
} from '@/util/animation'
import { featureUniqueForPaypal } from '@/util/customization'
import { getTextPhrase } from '@/util/i18n'

export default {
  name: 'PoweredByAtomic',
  components: {
    LegalDisclaimerActions
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('theme', ['useDarkMode']),
    poweredByAtomic() {
      return getTextPhrase({
        key: 'poweredByAtomic',
        namespace: 'shared',
        store: this.$store
      })
    },
    paypalRequired() {
      return featureUniqueForPaypal({ customer: this.userData.customer })
    }
  },
  watch: {
    $route(to, from) {
      if (this.paypalRequired) {
        if (to.name === ROUTES.SEARCH_COMPANY) {
          revealPbaPrivacyDisclaimerAnimation()
        }
        if (from.name === ROUTES.SEARCH_COMPANY) {
          hidePbaPrivacyDisclaimerAnimation()
        }
      }
    }
  },
  mounted() {
    fadeInPoweredByAtomic()
  }
}
</script>

<style lang="scss" scoped>
.pba {
  color: #0c0c0d; //Paypal black/gray
  text-align: center;
  margin: 0;
  padding: 16px 0 unquote('max(24px, env(safe-area-inset-bottom))');
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  height: var(--pbaHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.4rem;
  opacity: 1;

  &.dark {
    background: linear-gradient(
      0deg,
      rgba(11, 14, 30, 1) 0%,
      rgba(11, 14, 30, 1) 75%,
      rgba(11, 14, 30, 0) 100%
    );

    .powered-by-atomic-text {
      color: var(--darkModeTextHigh);
    }
  }
}

.powered-by-atomic-text {
  display: inline-block;
  position: absolute;
  transform: translateY(0);
}
</style>
