import * as RPC from '@atomicfi/rpc'
import transact from '@/plugins/rpc/transact'
import Ably from '@/plugins/ably'

const PUBLIC_KEY =
  'MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE1QYWDCHL7YqYvNng3GIc7eDc49h7gzY73YtRXNUqh5CIlDA6eTfYT1+MkdyXyJ2cUgULcR0WHR5CaV957F2kCQ=='

function register(rpc) {
  transact.register(rpc)
}

export default {
  /**
   * @param { string } event
   * @return { Promise<RPC.RPC> }
   */
  async initialize(event) {
    const transport = RPC.transport.ably.create({
      ably: Ably.get(),
      event
    })

    const verifier = RPC.transform.verifier.create()
    await verifier.initialize({ publicKey: PUBLIC_KEY })

    transport.pipe(verifier)

    const rpc = RPC.create(transport)

    register(rpc)

    await transport.initialize()

    return rpc
  }
}
