const state = {
  withholding: {}
}

const mutations = {
  setWithholding: (state, withholding) => {
    state.withholding = withholding
  }
}

const actions = {
  updateWithholding({ commit }, withholding) {
    commit('setWithholding', withholding)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
