import { mapValues } from 'lodash-es'
import { getHookStorage } from '@/util/native/helpers/before-hook'

export async function runRequestBeforeHook({ store, request, pageHandle }) {
  const storage = getHookStorage({ store })

  await eval(request.beforeHook)({ request, storage, pageHandle })

  return request
}

export function convertHeadersToStrings(headers) {
  return mapValues(headers, (headerValue) => `${headerValue}`)
}

/**
 *
 * @param { Record<string, string> } headers
 * @param { string } key
 * @returns { { name: string, value: string } | undefined }
 */
export function findHeader(headers, key) {
  for (const [name, value] of Object.entries(headers)) {
    if (name.toLowerCase() === key.toLowerCase())
      return {
        name,
        value
      }
  }
}

/**
 *
 * @param { Record<string, string> } headers
 * @param { string } key
 * @returns { string | undefined }
 */
export function getHeader(headers, key) {
  const header = findHeader(headers, key)

  return header?.value
}
