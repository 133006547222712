<script>
import { getGlobalCSSValue } from '@/util/general'
import { mapGetters } from 'vuex'

export default {
  name: 'IconBase',
  props: {
    stroke: String,
    size: Number,
    title: String
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    iconDimensions() {
      return this.size || 24
    },
    iconSize() {
      return {
        width: this.iconDimensions,
        height: this.iconDimensions
      }
    },
    iconStroke() {
      return { stroke: this.stroke || getGlobalCSSValue('--white') }
    },
    iconTwoToneStrokeOne() {
      return {
        stroke: this.useDarkMode
          ? getGlobalCSSValue('--iconToneTwo')
          : getGlobalCSSValue('--iconToneOne')
      }
    },
    iconTwoToneStrokeTwo() {
      return {
        stroke: this.useDarkMode
          ? getGlobalCSSValue('--iconToneOne')
          : getGlobalCSSValue('--iconToneTwo')
      }
    },
    // ToneOne is the lightest tone
    iconTwoToneFillOne() {
      return {
        fill: this.useDarkMode
          ? getGlobalCSSValue('--iconToneTwo')
          : getGlobalCSSValue('--iconToneOne')
      }
    },
    // ToneTwo is the darker tone
    iconTwoToneFillTwo() {
      return {
        fill: this.useDarkMode
          ? getGlobalCSSValue('--iconToneOne')
          : getGlobalCSSValue('--iconToneTwo')
      }
    }
  }
}
</script>
