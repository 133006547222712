import mixpanel from 'mixpanel-browser'
import { store } from '@/store'

export default {
  install: () => {
    mixpanel.init(store.state.environment.mixpanelId, {
      api_host: 'https://ingest.atomicfi.com'
    })
  },
  identify: (userId) => {
    mixpanel.identify(userId)
  },
  get() {
    return mixpanel
  }
}
