<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconBase',
  props: {
    stroke: String,
    fillColor: String,
    customerBranded: Boolean,
    taskBranded: Boolean,
    size: Number
  },
  computed: {
    ...mapGetters('theme', ['customerBrandColor']),
    ...mapGetters('company', ['brandColor']),
    iconSize() {
      return this.size || 24
    },
    iconStyles() {
      return {
        zIndex: 2
      }
    },
    svgProps() {
      return {
        stroke: this.stroke
          ? this.stroke
          : this.customerBranded
          ? this.customerBrandColor
          : this.taskBranded
          ? this.brandColor
          : this.fillColor
          ? 'transparent'
          : '#FFFFFF',
        fill: this.fillColor || 'transparent',
        width: this.iconSize,
        height: this.iconSize
      }
    }
  }
}
</script>
