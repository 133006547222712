export const USER_COMPANY_SELECTION_STATUS = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  SELECTED: 'selected',
  IN_PROGRESS: 'in-progress',
  REQUIRES_USER_INPUT: 'requires-user-input',
  /**
   * When the task fails for `subscription-managed-by-partner-provider` and
   * we're able to refer the user to the managing company. We want to treat this
   * differently than a failure because it doesn't feel like a failure to the
   * user.
   *
   * We're adding this status on the frontend for now to see if it makes a
   * difference in conversion. In the long term this status should be added to
   * the selection in the backend, just like the other statuses.
   */
  MANAGING_COMPANY_FOUND: 'managing-company-found'
}
