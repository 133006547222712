import { ROUTES, TASK_WORKFLOW_VIEW } from '@/util/constants'

export function showProgressBar({ store, router }) {
  return (
    _showProgressBarBasedOnView({
      store,
      router
    }) &&
    !store.getters['theme/showBackButtonText'] &&
    !store.getters['taskWorkflow/productsIncludeVerify'] &&
    store.state.experiment.ldFlags.progressBar
  )
}

function _showProgressBarBasedOnView({ store, router }) {
  _updateProgressBar({ store, router })

  return (
    !_isWelcomePage(router) &&
    (_isSearchPage(router) ||
      _isTaskPageAndNoInitialViewSet({ store, router }) ||
      _isAuthentication(store) ||
      _isDistributionPage(store))
  )
}

function _updateProgressBar({ store, router }) {
  const completion = _determineProgressBarCompletion({ store, router })
  store.dispatch('progressBar/updateProgressBar', completion)
}

function _determineProgressBarCompletion({ store, router }) {
  if (_isSearchPage(router)) {
    return 15
  } else if (_isAuthentication(store)) {
    return 50
  } else if (_isDistributionPage(store)) {
    return 85
  } else {
    return store.state.progressBar.completion
  }
}

function _isWelcomePage(router) {
  return [ROUTES.WELCOME, ROUTES.PAY_LINK_WELCOME].includes(
    router.currentRoute?.value?.name
  )
}

function _isSearchPage(router) {
  return [
    ROUTES.SEARCH_COMPANY,
    ROUTES.SEARCH_FRANCHISE,
    ROUTES.PAY_LINK_SEARCH
  ].includes(router.currentRoute?.value?.name)
}

function _isTaskPageAndNoInitialViewSet({ store, router }) {
  return (
    router.currentRoute?.value?.name === ROUTES.TASK &&
    !store.state.taskWorkflow.taskWorkflowState?.view
  )
}

function _isAuthentication(store) {
  return (
    store?.state?.taskWorkflow?.taskWorkflowState?.view ===
    TASK_WORKFLOW_VIEW.LOGIN
  )
}

function _isDistributionPage(store) {
  return (
    store?.state?.taskWorkflow?.taskWorkflowState?.view ===
    TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION
  )
}
