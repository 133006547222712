import { invoke } from '@/util/native/invoke'
import { HIDE } from '@/util/native/events'

export async function hide(options) {
  const message = await invoke({
    type: HIDE,
    payload: options
  })

  return message.payload
}
