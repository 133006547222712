export const displayAccounts = {
  header: 'How should your paycheck be split?',
  previously: 'Previously',
  new: 'NEW',
  mustEqual100: 'Must add up to 100%',
  setAsRemainder: 'Set account as remainder',
  remainder: 'Remainder',
  enterNonZeroFixedAmount: ({ customerName }) =>
    `Enter an amount greater than $0 for ${customerName}`,
  enterNonZeroPercentAmount: ({ customerName }) =>
    `Enter an amount greater than 0% for ${customerName}`
}
