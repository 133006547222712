export const addCard = {
  addCard: 'Agregar tarjeta',
  addressUsedForAllCards: 'Esta dirección se utiliza para todas las tarjetas.',
  billingAddress: 'Dirección de facturación',
  cardDetails: 'Detalles de la tarjeta',
  error: {
    address: 'Por favor, introduce una dirección.',
    cardNumber: 'Por favor, introduce un número de tarjeta válido.',
    cardNumberCustomer: ({ customerName }) =>
      `Por favor, introduce un número de tarjeta ${customerName} válido.`,
    cardSave: 'Ha ocurrido un error. Por favor, inténtalo de nuevo.',
    city: 'Por favor, introduce una ciudad.',
    cvv: 'Por favor, introduce un CVV válido.',
    expiry: 'Por favor, introduce una fecha de caducidad válida.',
    postalCode: 'Por favor, introduzca un código postal válido.',
    required: 'Campo es obligatorio.',
    state: 'Por favor, introduce un estado válido.'
  },
  mustBeCustomerCard: ({ customerName }) =>
    `Debe ser una tarjeta de ${customerName}.`,
  save: 'Guardar',
  saving: 'Guardando'
}
