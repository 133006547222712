import { makeRequest } from '@/util/requests'

const actions = {
  async createRequestedCompanyForConfigurableConnector(_, { payload }) {
    await makeRequest({
      method: 'POST',
      endpoint: '/requested-company/for-configurable-connector',
      data: payload
    })
  }
}

export default {
  namespaced: true,
  actions
}
