export const legal = {
  acceptTermsAndConditions: 'I agree to the ',
  termsAndConditions: 'terms and conditions',
  legalDisclaimerPretext: `By continuing, you accept Atomic's `,
  actionPrivacyPolicy: 'Privacy policy',
  actionTerms: 'Terms',
  actionTermsOfUse: 'Terms of Use',
  termsConditionsSee: 'See',
  termsConditionsAnd: 'and'
}
