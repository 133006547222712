<template>
  <AppOverlay>
    <PageWrap>
      <MainActionBar v-if="showActionBar" />
      <router-view
        v-slot="{ Component }"
        class="child-view"
        :class="{ 'powered-by-atomic-enabled': poweredByAtomic }"
      >
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </PageWrap>
  </AppOverlay>
</template>

<script>
import AppOverlay from '@/components/Layout/AppOverlay.vue'
import PageWrap from '@/components/Layout/PageWrap.vue'
import MainActionBar from '@/components/Shared/MainActionBar.vue'
import { mapState, mapGetters } from 'vuex'
import { DEEPLINK_STEPS, ROUTES } from '@/util/constants'
import modalKeydownClose from '@/mixins/modal-keydown-close'
import { listenForEmulatorEvents } from '@/util/emulator'

export default {
  name: 'TransactApp',
  components: {
    AppOverlay,
    PageWrap,
    MainActionBar
  },
  mixins: [modalKeydownClose],
  computed: {
    ...mapState('animation', ['transitionName']),
    ...mapState('main', ['deeplink']),
    ...mapGetters('theme', ['poweredByAtomic']),
    ...mapGetters('main', ['isScopePayLink']),
    showActionBar() {
      return !(
        (this.isScopePayLink &&
          this.deeplink?.step === DEEPLINK_STEPS.ADD_CARD) ||
        this.deeplink?.step === DEEPLINK_STEPS.GENERATE_DEPOSIT_PDF
      )
    }
  },
  created() {
    listenForEmulatorEvents()

    if (window.location.href.indexOf('initialize') > -1) {
      window.atomicStorage.setItem('initializedOnce', false)
    } else {
      if (window.atomicStorage.getItem('initializedOnce') === 'true') {
        this.$router.push({
          name: ROUTES.INITIALIZE,
          params: {
            config: window.atomicStorage.getItem('config')
          }
        })
      }
    }
  }
}
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
.child-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  &.powered-by-atomic-enabled {
    height: calc(100% - var(--pbaHeight));
  }
}

/* .slide-left & .slide-right are transition classes */
.slide-right-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-right-enter-to,
.slide-right-leave-from,
.slide-left-enter-to,
.slide-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-leave-to,
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 250ms cubic-bezier(0.12, 0, 0.39, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.scale-in-enter-active,
.scale-in-leave-active {
  transition: all 250ms ease-out;
}

.scale-in-enter-from,
.scale-in-leave-to {
  opacity: 0;
  transform-origin: center center;
  transform: scale(0);
}

.scale-in-enter-to,
.scale-in-leave-from {
  opacity: 1;
  transform-origin: center center;
  transform: scale(1, 1);
}
</style>
