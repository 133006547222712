<template>
  <CloseButton @close="clickedClose" />
</template>

<script>
import { mapActions } from 'vuex'

import CloseButton from '@/components/Shared/CloseButton.vue'

export default {
  name: 'ModalClose',
  components: {
    CloseButton
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    async clickedClose() {
      await this.closeModal()
      this.$emit('close')
    }
  }
}
</script>
