import { createStore } from 'vuex'
import activeElement from '@/store/modules/active-element'
import analytics from '@/store/modules/analytics'
import animation from '@/store/modules/animation'
import appClip from '@/store/modules/app-clip'
import authentication from './modules/authentication'
import authenticator from './modules/authenticator'
import company from './modules/company'
import demo from './modules/demo'
import distribution from './modules/distribution'
import environment from './modules/environment'
import exit from './modules/exit'
import experiment from './modules/experiment'
import formFlow from './modules/form-flow'
import i18n from './modules/i18n'
import main from './modules/main'
import messaging from '@/store/modules/messaging'
import modal from './modules/modal'
import network from './modules/network'
import { createPayLinkModule } from './modules/pay-link'
import { PayLinkService } from '@/services'
import progress from './modules/progress'
import progressBar from './modules/progress-bar'
import requestedCompany from './modules/requested-company'
import search from './modules/search'
import smartAuth from './modules/smart-auth'
import survey from './modules/survey'
import task from './modules/task'
import taskHistory from './modules/task-history'
import taskWorkflow from './modules/task-workflow'
import tempStorage from './modules/temp-storage'
import theme from './modules/theme'
import user from './modules/user'
import userDeviceAutomation from './modules/user-device-automation'
import withholding from './modules/withholding'
import Analytics from '@/plugins/analytics'

export const store = createStore({
  modules: {
    activeElement,
    analytics,
    animation,
    appClip,
    authentication,
    authenticator,
    company,
    demo,
    distribution,
    environment,
    exit,
    experiment,
    formFlow,
    i18n,
    main,
    messaging,
    modal,
    network,
    payLink: createPayLinkModule(PayLinkService, Analytics),
    progress,
    progressBar,
    requestedCompany,
    search,
    smartAuth,
    survey,
    task,
    taskHistory,
    taskWorkflow,
    tempStorage,
    theme,
    user,
    userDeviceAutomation,
    withholding
  }
})
