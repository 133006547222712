export const maintenanceInterstitial = {
  inMaintenanceMode: ({ connectorName }) =>
    `Disculpas, actualmente no podemos dar soporte a ${connectorName}.`,
  maintenanceDescDeposit:
    'Estamos trabajando para restaurar este sistema. Mientras tanto, configure su depósito directo utilizando otro método.',
  maintenanceDescVerify:
    'Mientras tanto, verifique su información utilizando otro método.',
  maintenanceDescTax:
    'Inténtelo de nuevo más tarde o suba su declaración de impuestos de otra manera.',
  maintenanceDescDepositManual:
    'Estamos trabajando para restaurar este sistema. Mientras tanto, configure su depósito directo manualmente.'
}
