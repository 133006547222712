import { createFocusTrap } from 'focus-trap'

let focusTraps = new Map()

const FocusTrap = {
  install: function (Vue) {
    Vue.directive('focus-trap', {
      mounted: _trapFocus,
      // Bind on updated is required to allow dynamic values of the shouldTrapFocus argument
      updated: _trapFocus,
      unmounted(el, binding, vnode) {
        // we only want to deactivate the focus trap for secondary focus traps (i.e. inside a modal)
        // so we check for the 'permanent' argument before calling deactivate
        // removing this check results in unpredictable behavior for the primary permanent focus trap
        if (binding.arg !== 'permanent') {
          _deactivateAndDeleteTrap(vnode.scopeId)
        }
      }
    })
  }
}

function _trapFocus(el, binding, vnode) {
  const shouldTrapFocus = binding.value?.shouldTrapFocus ?? true

  if (!shouldTrapFocus) {
    _deactivateAndDeleteTrap(vnode.scopeId)

    return
  }

  // in some cases the component we need to focus will be different from the component where the directive is mounted
  // in these cases we need a way of targeting the other component, but we focus the component where the directive is mounted by default
  const elementToFocus = binding.value?.targetClass
    ? window.document.querySelector(binding.value?.targetClass)
    : el

  const trap = createFocusTrap(elementToFocus, {
    initialFocus: elementToFocus,
    preventScroll: true,
    returnFocusOnDeactivate: false,
    fallbackFocus: '.app-content',
    allowOutsideClick: true,
    checkCanFocusTrap: (trapContainers) => {
      const results = trapContainers.map((trapContainer) => {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (trapContainer.outerText) {
              clearTimeout(timeout)
              clearInterval(interval)
              resolve()
            }
          }, binding.value?.waitBeforeFocus || 100)

          const timeout = setTimeout(function () {
            clearInterval(interval)
            reject()
          }, 3000)
        })
      })
      // Return a promise that resolves when all the trap containers are able to receive focus
      return Promise.all(results)
    }
  })
  trap.activate()

  focusTraps.set(vnode.scopeId, {
    trap,
    previousActiveElement: document.activeElement
  })
}

function _deactivateAndDeleteTrap(id) {
  if (focusTraps.has(id)) {
    const { trap, previousActiveElement } = focusTraps.get(id)
    trap.deactivate()
    previousActiveElement.focus()
    focusTraps.delete(id)
  }
}

export default FocusTrap
