import { waitForTruthyFunctionResult } from './wait-for-truthy-function-result'

const SUCCESS_RESPONSE = 'success'
const TIMEOUT_RESPONSE = 'timed-out'

export async function waitWithTimeout({ func, options }) {
  let { delay, retries } = { delay: 100, retries: 50, ...options }

  const timeout = delay * retries

  const response = await Promise.race([
    _timeout(timeout),
    waitForTruthyFunctionResult({ func, delay, retries }).then((done) =>
      done ? SUCCESS_RESPONSE : TIMEOUT_RESPONSE
    )
  ])

  return response
}

async function _timeout(duration) {
  await new Promise((resolve) => setTimeout(resolve, duration))
  return TIMEOUT_RESPONSE
}
