<template>
  <div class="dynamic-header-logos-wrap">
    <div class="primary-logo">
      <Logo
        v-if="logos.primary"
        :imgSrc="logos.primary.src"
        :backgroundColor="logos.primary.background"
        :replacement-text="logos.replacementText"
        :size="50"
      />
      <Logo
        v-if="logos.accent"
        class="accent-logo"
        :class="{
          'top-left': accentTopLeft,
          'top-right': accentTopRight
        }"
        :imgSrc="logos.accent.src"
        :backgroundColor="logos.accent.background"
        :size="24"
        :inner-padding="4"
        :border-radius="12"
      />
    </div>
    <Logo
      v-if="logos.secondary"
      class="secondary-logo"
      :imgSrc="logos.secondary.src"
      :backgroundColor="logos.secondary.background"
      :size="50"
    />
    <component v-if="badge" class="badge" :is="badgeComponent" />
  </div>
</template>

<script>
import Logo from '@/components/Shared/LogoWrap.vue'
import BadgeError from '@/components/Badge/BadgeError.vue'
import BadgeSuccess from '@/components/Badge/BadgeSuccess.vue'
import BadgeInfo from '@/components/Badge/BadgeInfo.vue'
import BadgeArrowRight from '@/components/Badge/BadgeArrowRight.vue'

export default {
  name: 'DynamicHeaderLogos',
  props: {
    logos: {
      type: Object,
      required: true
    },
    badge: {
      type: String,
      validator: (value) =>
        ['success', 'error', 'info', 'arrow'].includes(value)
    }
  },
  components: {
    Logo,
    BadgeError,
    BadgeSuccess,
    BadgeInfo,
    BadgeArrowRight
  },
  computed: {
    accentTopLeft() {
      return this.logos.accent?.position === 'top-left'
    },
    accentTopRight() {
      return this.logos.accent?.position === 'top-right'
    },
    badgeComponent() {
      switch (this.badge) {
        case 'success':
          return BadgeSuccess
        case 'error':
          return BadgeError
        case 'info':
          return BadgeInfo
        case 'arrow':
          return BadgeArrowRight
        default:
          return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dynamic-header-logos-wrap {
  display: flex;
  position: relative;
  height: 50px;
  .primary-logo {
    position: relative;
    .accent-logo {
      position: absolute;
      transform: scale(1);
      transform-origin: center center;
      border: solid 2.5px var(--white);
      z-index: 2;
      left: -8px;
      top: -8px;
      &.top-left {
        left: -8px;
        top: -8px;
      }
      &.top-right {
        right: -8px;
        top: -8px;
      }
      &.dark {
        border-color: var(--darkModeBg);
      }
    }
  }
  .secondary-logo {
    margin-left: calc(var(--marginBase) * 1);
  }
  .badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    scale: 1;
  }
}
</style>
