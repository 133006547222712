<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          :title="phrases.uplinkInterstitial.turnOnGooglePlayInstant"
        />
        <ol class="instructions">
          <li class="line-item">{{ phrases.uplinkInterstitial.step1 }}</li>
          <li class="line-item">{{ phrases.uplinkInterstitial.step2 }}</li>
          <li class="line-item">{{ phrases.uplinkInterstitial.step3 }}</li>
          <li class="line-item">{{ phrases.uplinkInterstitial.step4 }}</li>
        </ol>
        <p class="p-100">{{ phrases.uplinkInterstitial.tip }}</p>
        <DecorativeImage
          class="instant-app-help"
          src="/img/android-instant-app-help.svg"
        />
        <TextButton
          class="mb-2"
          data-test-id="close"
          :text="phrases.shared.close"
          @handleClick="handleClickClose"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import TextButton from '@/components/Form/TextButton.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import DecorativeImage from '@/components/Shared/DecorativeImage.vue'

import { mapActions } from 'vuex'

export default {
  name: 'AndroidInstantAppsModal',
  components: {
    ModalContentWrap,
    ModalBody,
    ModalContent,
    TextButton,
    DynamicHeader,
    DecorativeImage
  },
  data: () => ({}),
  methods: {
    ...mapActions('modal', ['closeModal']),
    async handleClickClose() {
      this.$analytics.track({
        event: 'Closed Android Instant Apps Modal'
      })
      await this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.instructions {
  color: var(--gray-900);
  padding-left: calc(var(--paddingBase) * 2);
  margin-bottom: calc(var(--marginBase) * 2);
  font-size: 1.4rem;
  .line-item {
    margin-top: var(--marginBase);
    &:first-of-type {
      margin-top: 0;
    }
  }
}
.instant-app-help {
  width: 250px;
  margin: calc(var(--marginBase) * 3) auto 0;
}
</style>
