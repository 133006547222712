<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          :title="phrases.findCredentialsHelpModal.title"
          textAlignment="text-center"
        />
        <ul>
          <HelpStep
            symbol="A"
            :title="phrases.findCredentialsHelpModal.step1.title"
            :description="phrases.findCredentialsHelpModal.step1.description"
          />
          <HelpStep
            symbol="B"
            :title="phrases.findCredentialsHelpModal.step2.title"
            :description="phrases.findCredentialsHelpModal.step2.description"
          />
          <HelpStep
            symbol="C"
            :title="phrases.findCredentialsHelpModal.step3.title"
            :description="phrases.findCredentialsHelpModal.step3.description"
          />
        </ul>

        <TextButton
          class="mt-2"
          data-test-id="close"
          btnStyle="hollow"
          :text="phrases.shared.close"
          @handleClick="handleClickClose"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import HelpStep from '@/components/Shared/HelpStep.vue'
import TextButton from '@/components/Form/TextButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'FindCredentialsHelpModal',
  components: {
    ModalContentWrap,
    ModalBody,
    ModalContent,
    DynamicHeader,
    HelpStep,
    TextButton
  },
  data: () => ({}),
  methods: {
    ...mapActions('modal', ['closeModal']),
    async handleClickClose() {
      this.$analytics.track({
        event: 'Closed How To Find Credentials Modal'
      })
      await this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  .step-content {
    margin-left: calc(var(--marginBase) * 2);
    .title {
      color: var(--gray-800);
      margin-top: 4px;

      .dark & {
        color: var(--gray-300);
      }
    }
  }
}
</style>
