<template>
  <div class="distribution-type">
    <div class="distribution-content">
      <ModalLabel :level="2" class="title" data-test-id="modal-label">{{
        changeDistributionTitle
      }}</ModalLabel>
      <ModalDescription element="div">
        <FormSelect
          :data="{ options: selectOptions }"
          :init-value="distributionType"
          :focus-delay="400"
          ignore-init-update-event
          @inputUpdate="handleInput"
          aria-labelledby="modalLabel"
        ></FormSelect>
      </ModalDescription>
    </div>
  </div>
</template>

<script>
import FormSelect from '@/components/FormTypes/FormSelect.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { DISTRIBUTION_TYPES, PAGES, MODAL_VIEW } from '@/util/constants'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import ModalDescription from '@/components/Modal/ModalDescription.vue'
import { getTextPhrase } from '@/util/i18n'

export default {
  name: 'DistributionType',
  emits: ['cancel', 'changedDistribution', 'choseDistribution'],
  components: {
    ModalDescription,
    ModalLabel,
    FormSelect
  },
  data() {
    return {
      distributionType:
        this.$store.state?.distribution?.newDistributionType ??
        DISTRIBUTION_TYPES.TOTAL
    }
  },
  computed: {
    ...mapState('company', ['name']),
    ...mapState('distribution', [
      'fractionalError',
      'distributionList',
      'newDistributionType'
    ]),
    ...mapState('modal', ['modalState']),
    ...mapGetters('user', ['accountNumberLastFour', 'defaultDistributionType']),
    selectOptions() {
      return this.distributionList.map((option) => ({
        ...option,
        value: option.id
      }))
    },
    changeDistributionTitle() {
      return (
        getTextPhrase({
          key: 'changeDistributionTitle',
          namespace: 'shared',
          store: this.$store
        }) || this.phrases.depositOptions.title
      )
    },
    changeDistributionSubtitle() {
      return (
        getTextPhrase({
          key: 'changeDistributionSubtitle',
          namespace: 'shared',
          store: this.$store
        }) ||
        `${this.phrases.depositOptions.futurePaychecks} ${this.accountNumberLastFour}.`
      )
    }
  },
  methods: {
    ...mapActions('distribution', [
      'updateDistributionState',
      'updateNewDistributionType',
      'clearDistributionLimits',
      'updateDistributionLimits',
      'handleFractionalDepositUpdates',
      'fetchDistributionList',
      'resetDistributionSettings'
    ]),
    ...mapActions('modal', ['openModal', 'closeModal']),
    async handleInput({ value: distributionType }) {
      this.distributionType = distributionType
      this.$analytics.track({
        event: `Clicked Distribution Type From ${PAGES.DEPOSIT_OPTIONS}`,
        payload: { depositOption: this.distributionType }
      })

      await this.clearDistributionLimits()

      if (this.distributionType === DISTRIBUTION_TYPES.TOTAL) {
        this.updateNewDistributionType(this.distributionType)
        if (this.fractionalError.requiresAction) {
          this.$emit('changedDistribution')
        } else {
          this.$emit('choseDistribution')
        }
      } else {
        await this.closeModal()
        await this.updateDistributionLimits(this.distributionType)
        await this.openModal({
          view: MODAL_VIEW.DISTRIBUTION_EDIT_AMOUNT,
          data: {
            distributionType: this.distributionType
          }
        })
      }
    }
  },
  created() {
    this.fetchDistributionList({
      availableDistributionTypes:
        this.modalState.data.availableDistributionTypes
    })
    this.$analytics.track({ event: `Viewed ${PAGES.DEPOSIT_OPTIONS}` })
  }
}
</script>

<style lang="scss" scoped>
.distribution-type {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 8px 0 24px;

  .distribution-content {
    flex: 1 0 auto;
  }
}
</style>
