export const findEmployerHelpModal = {
  title: 'How to find your employer',
  step1: {
    title: 'Check your pay stubs',
    description:
      'You can usually find your payroll provider information on work-related documents, such as a pay stub.'
  },
  step2: {
    title: 'Ask your employer or co-worker',
    description:
      'Your employer, manager, or a co-worker likely knows who your payroll provider is.'
  }
}
