<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="mt-6"
      shape="box"
      shapeWidth="100%"
      shapeHeight="50px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="40px"
    />
    <SkeletonBlock
      class="mt-4"
      shape="box"
      shapeWidth="100%"
      shapeHeight="68px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="68px"
    />
    <SkeletonBlock
      class="mt-auto"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'

export default {
  name: 'PreselectedCompanyMultipleSkeleton',
  components: { SkeletonBlock, SkeletonTemplate }
}
</script>

<style scoped></style>
