import { Quantum } from '@atomicfi/quantum-js'
import { userAgent } from '@/util/client-side-automation'
import Analytics from '@/plugins/analytics'

export async function executeUserAction({ store }) {
  const [
    {
      action: { id }
    }
  ] = store.state.taskWorkflow.tasks

  const action = JSON.parse(atob(id))

  // This event name is used in the native SDK's, don't remove it
  Analytics.get().track({
    event: 'Launched User Action',
    payload: {
      id
    }
  })

  if (action.url && !action.flow) {
    await _openUrl(action.url)
  }

  // This event name is used in the native SDK's, don't remove it
  Analytics.get().track({
    event: 'Completed User Action',
    payload: {
      id
    }
  })
}

async function _openUrl(url) {
  const { page } = await Quantum.launch()

  await page.setUserAgent(userAgent())

  await page.show()
  await page.goto(url)
}
