/* eslint-disable no-empty-pattern */
const state = {
  trackAnalyticsEvents: true,
  trueAuthInteractionEventCallback: null
}

const actions = {
  resetAnalyticsTracking({ commit }) {
    commit('setTrackAnalyticsEvents', true)
    commit('setTrueAuthInteractionEventCallback', null)
  },
  updateTrackAnalyticsEvents({ commit }, trackAnalyticsEvents) {
    commit('setTrackAnalyticsEvents', trackAnalyticsEvents)
  },
  updateTrueAuthInteractionEventCallback(
    { commit },
    trueAuthInteractionEventCallback
  ) {
    commit(
      'setTrueAuthInteractionEventCallback',
      trueAuthInteractionEventCallback
    )
  }
}

const mutations = {
  setTrackAnalyticsEvents: (state, trackAnalyticsEvents) => {
    state.trackAnalyticsEvents = trackAnalyticsEvents
  },
  setTrueAuthInteractionEventCallback(state, trueAuthInteractionEventCallback) {
    state.trueAuthInteractionEventCallback = trueAuthInteractionEventCallback
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
