export const PAGES = {
  ACCESS_UNAUTHORIZED: 'Access Unauthorized Page',
  ADD_CARD: 'Add Card Page',
  ADD_CARD_INTERSTITIAL: 'Add Card Interstitial Page',
  ADP_EDDE_SDK: 'ADP EDDE SDK Page',
  ADP_COAUTH_INTERSTITIAL: 'ADP CoAuth Interstitial Page',
  ADP_INTERSTITIAL: 'Select From ADP Login Options Page',
  AMAZON_INTERSTITIAL: 'Amazon Interstitial Page',
  AUTHENTICATION_FAILED: 'Authentication Failed Page',
  WALMART_AUTHENTICATION_HELP: 'Authentication Help Page',
  AUTHENTICATION_PAUSED: 'Authentication Paused Page',
  AUTHENTICATION_SUCCESS: 'Authentication Success Page',
  BAD_API_REQUEST: 'Bad API Request Page',
  BANK_INTERSTITIAL: 'Bank Interstitial Page',
  CHECK_VPN_CONNECTIVITY: 'Check VPN Connectivity Page',
  CONNECTED_EMPLOYERS: 'Connected Employers Page',
  DEPOSIT_OPTIONS: 'Select From Deposit Options Page',
  DISTRIBUTION_CONFIRMATION: 'Distribution Confirmation Page',
  DOCUMENT_TYPE_SELECTION: 'Document Type Selection Page',
  DOCUMENT_UPLOAD: 'Document Upload Page',
  DOORDASH_INTERSTITIAL: 'Doordash Interstitial Page',
  EXIT_CONFIRMATION: 'Exit Confirmation Page',
  EXPIRED_TOKEN: 'Expired Token Page',
  FAILED_LOGIN_RECOVERY: 'Failed Login Recovery Page',
  FAILED_TIMEOUT: 'Timeout Failure Page',
  FIXED_DEPOSIT: 'Fixed Deposit Amount Page',
  FRACTIONAL_ERROR: 'Fractional Deposit Error Page',
  FRANCHISE_HELP_MODAL: 'Franchise Help Modal',
  HIGH_LATENCY: 'High Latency Page',
  INFO: 'Info Page',
  INTERRUPT: 'Interrupt Page',
  INVALID_SDK: 'Invalid SDK Parameters Page',
  LOGIN_HELP: 'Login Help Page',
  LOGIN_RECOVERY: 'Login Recovery Page',
  LOGIN: 'Login Page',
  MAINTENANCE: 'Under Maintenance Page',
  MANUAL_DEPOSIT_INSTRUCTIONS: 'Manual Deposit Instructions Page',
  MANUAL_FALLBACK_ACCOUNT_ROUTING: 'Manual Fallback Account And Routing Page',
  MANUAL_FALLBACK_OPTIONS: 'Manual Fallback Options Page',
  MANUAL_FALLBACK_PREFILLED_FORM: 'Manual Fallback Prefilled Form Page',
  MANUAL_FALLBACK_PREFILLED_SUMMARY: 'Manual Fallback Prefilled Summary Page',
  MANUAL_FALLBACK_DOWNLOAD_PDF_SUCCESS:
    'Manual Fallback Download PDF Success Page',
  MULTIPLE_ACCOUNTS: 'Select From Multiple Accounts Page',
  MULTIPLE_PAYROLL_PROVIDERS: 'Select From Multiple Payroll Providers Page',
  NETWORK_ERROR: 'Network Error Page',
  INVALID_CONFIGURATION: 'Invalid Configuration Page',
  NO_CONNECTION: 'No Internet Connection Page',
  PAY_LINK_INTERSTITIAL: 'PayLink Interstitial Page',
  PERCENT_DEPOSIT: 'Percentage Deposit Amount Page',
  SEARCH_COMPANY: 'Search By Company Page',
  SEARCH_CONFIGURABLE_CONNECTOR_TYPEAHEAD:
    'Typeahead Search By Configurable Connector Page',
  SEARCH_CONFIGURABLE_CONNECTOR: 'Search By Configurable Connector Page',
  SEARCH_FRANCHISE: 'Search By Franchise Page',
  SEARCH_PAYROLL: 'Search By Payroll Page',
  SELECTIONS: 'Selections Page',
  SELECTION_DETAILS: 'Selection Details',
  SMART_AUTH: 'Smart Auth Page',
  SMART_AUTH_FINISHED: 'Smart Auth Finished Page',
  SOCIAL_SECURITY_INTERSTITIAL: 'Social Security Interstitial Page',
  SOCIAL_SECURITY_LOGIN_OPTIONS: 'Social Security Login Options Page',
  SOCIAL_SECURITY_SSI: 'Social Security SSI Page',
  SYSTEM_AFTER_HOURS: 'System After Hours Page',
  TASK_COMPLETED: 'Task Completed Page',
  TASK_FAILED: 'Task Failed Page',
  TASK_HISTORY: 'Recent Tasks Page',
  TASK_PROGRESS: 'Task Progress Page',
  TASK_UNAUTHORIZED: 'Task Unauthorized Page',
  TOTAL_DEPOSIT: 'Total Deposit Page',
  TERMS_CONDITIONS: 'Terms And Conditions Page',
  TERMS_REQUIRED: 'Terms And Conditions Are Required Page',
  UPDATED_DEPOSIT_EXPERIENCE: 'Updated Deposit Experience',
  UPLINK_INTERSTITIAL: 'Uplink Interstitial Page',
  WELCOME: 'Welcome Page',
  WITHHOLD_NOT_SUPPORTED: 'Withhold Product Not Supported',
  YOU_ARE_PROTECTED: 'Learn How You Are Protected Page',
  ROUTING_NUMBER_BLOCKED: 'Routing Number Blocked Page',
  WALMART_MANUAL: 'Walmart Manual Fallback Page',
  SPARK_DRIVER_MANUAL: 'Spark Manual Fallback Page'
}
