const state = {
  apiEndpoint: undefined,
  pciEndpoint: undefined,
  mixpanelId: undefined,
  adpCoauthEndpoint: undefined,
  environment: undefined
}

const getters = {
  isSandbox: (state) => state.environment === 'sandbox'
}

const mutations = {
  setApiEndpoint: (state, apiEndpoint) => {
    state.apiEndpoint = apiEndpoint
  },
  setPciEndpoint: (state, pciEndpoint) => {
    state.pciEndpoint = pciEndpoint
  },
  setMixpanelId: (state, mixpanelId) => {
    state.mixpanelId = mixpanelId
  },
  setAdpCoauthEndpoint: (state, adpCoauthEndpoint) => {
    state.adpCoauthEndpoint = adpCoauthEndpoint
  },
  setEnvironment: (state, environment) => {
    state.environment = environment
  }
}

const actions = {
  updateApiEndpoint({ commit }, apiEndpoint) {
    commit('setApiEndpoint', apiEndpoint)
  },
  updatePciEndpoint({ commit }, pciEndpoint) {
    commit('setPciEndpoint', pciEndpoint)
  },
  updateMixpanelId({ commit }, mixpanelId) {
    commit('setMixpanelId', mixpanelId)
  },
  updateAdpCoauthEndpoint({ commit }, adpCoauthEndpoint) {
    commit('setAdpCoauthEndpoint', adpCoauthEndpoint)
  },
  updateEnvironment({ commit }, environment) {
    commit('setEnvironment', environment)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
