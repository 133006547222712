export const welcome = {
  present: {
    title: ({ customerName }) => `Connect your payments to ${customerName}`,
    subtitle: `We'll help you quickly connect your accounts to unlock detailed transaction info and simplified bill management.`,
    button: 'Get started'
  },
  switch: {
    title: ({ customerName }) => `Link your payments to ${customerName}`,
    subtitle: `We'll help you quickly update the payment method on your recurring payments.`,
    button: 'Get started'
  }
}
