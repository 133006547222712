export function initFilters(app) {
  app.config.globalProperties.$filters = {
    currency: currencyFilter,
    percent: percentageFilter,
    truncate
  }
}

export function currencyFilter(amount) {
  return (
    Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) || 0
  )
}

export function percentageFilter(amount) {
  return (
    Number(amount).toLocaleString('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) || 0
  )
}

export function truncate(text, length, suffix) {
  let textWithoutHtml = text.replace(/<[^>]*>?/gm, '')
  if (textWithoutHtml.length > length) {
    return textWithoutHtml.substring(0, length) + suffix
  } else {
    return text
  }
}
