export const searchCompany = {
  title: 'Who pays you?',
  trySearching: 'Try searching',
  inputPlaceholder: 'Employer or payroll provider',
  minimalInputPlaceholder: 'Find your income source',
  searchByState: 'Search by State',
  stateNotFound: 'State not found',
  tryPayroll: 'Try searching for your employer’s payroll provider instead.',
  zeroResultsFound: ({ searchText }) => `We couldn’t find "${searchText}"`,
  payrollProvidersTitle: 'Payroll Providers',
  seeMore: 'See more',
  tags: {
    popular: 'Popular',
    payroll: 'Payroll',
    employer: 'Employer',
    gig: 'Gig',
    govt: 'Govt',
    subscription: 'Subscriptions',
    cell: 'Cell Phone',
    cable: 'Cable & Internet',
    insurance: 'Insurance',
    card: 'Cards'
  }
}
