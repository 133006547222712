// Listen for events from the native webview
import Ably from '@/plugins/ably'
import {
  beginAuthenticationInSdk,
  exitAuth,
  receivedInterceptedRequest,
  userWasAuthenticated
} from '@/util/client-side-automation'
import {
  injectPasswordManagerInput,
  injectRequestInterceptor,
  injectOpenReplay
} from '@/util/client-side-automation/script-injections'
import { TASK_WORKFLOW_VIEW } from '@/util/constants'

export async function eventHandler({ name, store, channel, payload }) {
  switch (name) {
    case 'sdk-exit-auth':
      return await exitAuth({ store })
    case 'sdk-user-authenticated':
      return await userWasAuthenticated({ store })
    case 'sdk-demo':
      return await userWasAuthenticated({ store, demo: true })
    case 'sdk-update-url':
      return await _urlWasUpdated({ store, channel, payload })
    case 'sdk-storage-response':
      console.log(name, payload)
      break
    case 'sdk-vpn-success':
      return await beginAuthenticationInSdk({ store })
    case 'sdk-vpn-failure':
      return await _failedToConnectToVPN({ store })
    case 'sdk-intercepted-request':
      return await receivedInterceptedRequest({
        store,
        request: payload.request
      })
    case 'sdk-on-document-start':
      break
    case 'sdk-on-document-end':
      await _injectScriptsIntoSDK({ store })
      break
    case 'sdk-message':
      return _receivedSDKMessage({ store, payload })
  }
}

export async function listenForNativeEvents({ store, channel }) {
  // Remove any previous native event listeners
  document.body.removeEventListener(
    'sdk-native-event',
    store.state.userDeviceAutomation.activeSession.nativeEventListener
  )

  const nativeEventListener = async ({ detail: { name, payload } }) => {
    return eventHandler({ name, store, channel, payload })
  }

  // Create new native event listeners for this channel
  await store.dispatch('userDeviceAutomation/updateActiveSession', {
    updates: { nativeEventListener }
  })

  // And start listening for these native events
  document.body.addEventListener(
    'sdk-native-event',
    store.state.userDeviceAutomation.activeSession.nativeEventListener
  )
}

export function demoAsIfAuthenticated({ store }) {
  return userWasAuthenticated({ store, demo: true })
}

async function _urlWasUpdated({ store, channel, payload }) {
  await store.dispatch('userDeviceAutomation/updateActiveSession', {
    updates: { currentURL: payload.url }
  })

  _injectScriptsIntoSDK({ store })

  if (store.state.userDeviceAutomation.activeSession.authenticationComplete) {
    Ably.chunkPublish({
      channel,
      eventName: 'client-update-url',
      eventData: payload
    })
  }
}

function _injectScriptsIntoSDK({ store }) {
  injectPasswordManagerInput({ store })

  if (store.getters['main/useUplinkOpenReplay']) {
    injectOpenReplay({
      store,
      userId: store.state.user.userData._id,
      connectorId: store.getters['company/connectorId'],
      companyId: store.getters['company/companyId'],
      capturePayload:
        store.state.experiment.ldFlags.captureUplinkOpenReplayPayload
    })
  }

  if (
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']
      .interceptRequests
  ) {
    injectRequestInterceptor({ store })
  }
}

async function _failedToConnectToVPN({ store }) {
  await store.dispatch('taskWorkflow/resetTaskWorkflowState', {
    initialView: TASK_WORKFLOW_VIEW.INTERSTITIAL.CHECK_VPN_CONNECTIVITY
  })
}

async function _receivedSDKMessage({ store, payload }) {
  if (payload?.message?.openReplaySessionHash) {
    await store.dispatch('userDeviceAutomation/updateActiveSession', {
      updates: {
        openReplaySessionHash: payload.message.openReplaySessionHash
      }
    })
  }
}
