export function getHookStorage({ store }) {
  return {
    requestHeaders: {
      get: (header) => {
        return store.state.tempStorage.spiedRequestHeaders[header]
      }
    },
    responseHeaders: {
      get: (header) => {
        return store.state.tempStorage.spiedResponseHeaders[header]
      }
    },
    interceptedData: {
      get: (key) => {
        const value = store.state.tempStorage.interceptedData[key]
        // ensure that we're returning a plain object
        if (typeof value === 'object') {
          return JSON.parse(
            JSON.stringify(store.state.tempStorage.interceptedData[key])
          )
        } else {
          return value
        }
      }
    },
    sensitiveData: {
      get: (key) => {
        return store.state.tempStorage.sensitiveData.get(key)
      }
    },
    cardData: {
      get: (pageHandle) => store.state.tempStorage.cardData.get(pageHandle)
    }
  }
}
