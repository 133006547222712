<template>
  <component :is="componentToRender" :brand="brand" class="card" />
</template>

<script>
import IconCardBranded from './IconCardBranded.vue'
import IconCardCustomer from './IconCardCustomer.vue'

export default {
  name: 'IconCard',
  components: {
    IconCardBranded,
    IconCardCustomer
  },
  props: {
    brand: String
  },
  computed: {
    componentToRender() {
      return this.brand ? IconCardBranded : IconCardCustomer
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0px 4px 6px -2px rgba(51, 55, 77, 0.03),
    0px 12px 16px -4px rgba(51, 55, 77, 0.03);
}
</style>
