const state = {
  activeSurvey: undefined,
  viewedSurveys: new Set()
}

const getters = {
  activeSurvey: (state) => {
    return state.activeSurvey
  },
  hasViewedSurvey(state) {
    return (surveyId) => state.viewedSurveys.has(surveyId)
  }
}

const actions = {
  updateActiveSurvey({ commit }, surveyId) {
    commit('setActiveSurvey', surveyId)
  },
  clearActiveSurvey({ commit }) {
    commit('setActiveSurvey', undefined)
  }
}

const mutations = {
  addViewedSurvey: (state, surveyId) => {
    state.viewedSurveys.add(surveyId)
  },
  setActiveSurvey: (state, surveyId) => {
    state.activeSurvey = surveyId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
