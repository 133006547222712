<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="mt-3"
      shape="box"
      shapeWidth="100%"
      shapeHeight="55px"
    />
    <SkeletonBlock
      class="mt-3"
      shape="box"
      shapeWidth="100%"
      shapeHeight="90px"
    />
    <SkeletonBlock
      class="mt-1"
      shape="box"
      shapeWidth="100%"
      shapeHeight="90px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'ManualFallbackSkeleton',
  components: {
    SkeletonTemplate,
    SkeletonBlock
  }
}
</script>
