<template>
  <div
    class="modal-action-bar"
    @click="clickedClose"
    @keydown="clickedClose"
  ></div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ModalActionBar',
  methods: {
    ...mapActions('modal', ['closeModal']),
    async clickedClose() {
      await this.closeModal()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-action-bar {
  position: relative;
  min-height: 0px;
  padding: 0;
}

.powered-by-atomic-enabled {
  .modal-action-bar {
    min-height: 0px;
    padding: 0;
  }
}
</style>
