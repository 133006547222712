<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M27.5 22.3333V15.6667C27.4997 15.3744 27.4225 15.0873 27.2763 14.8343C27.13 14.5813 26.9198 14.3711 26.6667 14.225L20.8333 10.8917C20.58 10.7454 20.2926 10.6684 20 10.6684C19.7074 10.6684 19.42 10.7454 19.1667 10.8917L13.3333 14.225C13.0802 14.3711 12.87 14.5813 12.7237 14.8343C12.5775 15.0873 12.5003 15.3744 12.5 15.6667V22.3333C12.5003 22.6256 12.5775 22.9126 12.7237 23.1657C12.87 23.4187 13.0802 23.6289 13.3333 23.775L19.1667 27.1083C19.42 27.2546 19.7074 27.3316 20 27.3316C20.2926 27.3316 20.58 27.2546 20.8333 27.1083L26.6667 23.775C26.9198 23.6289 27.13 23.4187 27.2763 23.1657C27.4225 22.9126 27.4997 22.6256 27.5 22.3333Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.25 12.5083L20 14.675L23.75 12.5083"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.25 25.4917V21.1667L12.5 19"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 19L23.75 21.1667V25.4917"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.725 14.8L20 19.0083L27.275 14.8"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 27.4V19"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAES'
}
</script>
