<template>
  <div class="symbol-wrap" aria-hidden="true">
    <div
      class="symbol-background"
      :style="`background-color: ${symbolForeground}`"
    ></div>
    <div class="symbol">
      <svg
        v-if="symbol === $CONSTANTS.MANUAL_DEPOSIT_ACTIONS.DEPOSIT_INFO"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_20214_76433)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.25279 6.85071C2.63368 6.85071 2.94245 7.15948 2.94245 7.54037V14.6668C2.94245 15.0477 2.63368 15.3565 2.25279 15.3565C1.8719 15.3565 1.56314 15.0477 1.56314 14.6668V7.54037C1.56314 7.15948 1.8719 6.85071 2.25279 6.85071ZM5.93095 6.85071C6.31184 6.85071 6.62061 7.15948 6.62061 7.54037V14.6668C6.62061 15.0477 6.31184 15.3565 5.93095 15.3565C5.55006 15.3565 5.2413 15.0477 5.2413 14.6668V7.54037C5.2413 7.15948 5.55006 6.85071 5.93095 6.85071ZM10.0689 6.85071C10.4498 6.85071 10.7585 7.15948 10.7585 7.54037V14.6668C10.7585 15.0477 10.4498 15.3565 10.0689 15.3565C9.688 15.3565 9.37923 15.0477 9.37923 14.6668V7.54037C9.37923 7.15948 9.688 6.85071 10.0689 6.85071ZM13.747 6.85071C14.1279 6.85071 14.4367 7.15948 14.4367 7.54037V14.6668C14.4367 15.0477 14.1279 15.3565 13.747 15.3565C13.3662 15.3565 13.0574 15.0477 13.0574 14.6668V7.54037C13.0574 7.15948 13.3662 6.85071 13.747 6.85071Z"
            :fill="taskWorkflowBranding.foreground?.color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.53953 0.819958C7.80151 0.585079 8.1983 0.585079 8.46028 0.819958L15.1269 6.79697C15.3397 6.98775 15.4128 7.29001 15.3107 7.55693C15.2085 7.82385 14.9524 8.00012 14.6666 8.00012H1.33324C1.04745 8.00012 0.791262 7.82385 0.689127 7.55693C0.586992 7.29001 0.66007 6.98775 0.872863 6.79697L7.53953 0.819958ZM3.13559 6.62081H12.8642L7.99991 2.2597L3.13559 6.62081Z"
            :fill="taskWorkflowBranding.foreground?.color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.643584 14.6668C0.643584 14.2859 0.952353 13.9771 1.33324 13.9771H14.6666C15.0475 13.9771 15.3562 14.2859 15.3562 14.6668C15.3562 15.0477 15.0475 15.3564 14.6666 15.3564H1.33324C0.952353 15.3564 0.643584 15.0477 0.643584 14.6668Z"
            :fill="taskWorkflowBranding.foreground?.color"
          />
        </g>
        <defs>
          <clipPath id="clip0_20214_76433">
            <rect
              width="16"
              height="16"
              :fill="taskWorkflowBranding.foreground?.color"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else-if="symbol === $CONSTANTS.MANUAL_DEPOSIT_ACTIONS.ADDRESS"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="taskWorkflowBranding.foreground?.color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="icon pin"
      >
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
        <circle cx="12" cy="10" r="3"></circle>
      </svg>
      <span v-else :style="`color: ${symbolForeground}`" aria-hidden="true">
        {{ symbol }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ManualDepositInstructionsSymbol',
  props: {
    symbol: [Number, String]
  },
  computed: {
    ...mapState('taskWorkflow', ['taskWorkflowBranding']),
    symbolForeground() {
      return this.taskWorkflowBranding.foreground?.color || '#376DE1'
    },
    symbolBackground() {
      return this.taskWorkflowBranding.background?.color || '#F3F6FF'
    }
  }
}
</script>

<style lang="scss" scoped>
.symbol-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;

  .symbol-background {
    opacity: 0.2;
    border-radius: 50%;
    display: block;
    width: 100%;
    height: 100%;
    min-width: 32px;
    min-height: 32px;
  }
  .symbol {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    max-width: 18px;
    max-height: 18px;
    .icon {
      width: 16px;
      height: auto;
      &.pin {
        width: 18px;
      }
    }
  }
}
</style>
