export const displayAccounts = {
  header: '¿Cómo se debe dividir su cheque de pago?',
  previously: 'Previamente',
  new: 'Nuevo',
  mustEqual100: 'Debe sumar 100%',
  setAsRemainder: 'Establecer como resto',
  remainder: 'Resto',
  enterNonZeroFixedAmount: ({ customerName }) =>
    `Ingrese una cantidad mas de $0 para ${customerName}`,
  enterNonZeroPercentAmount: ({ customerName }) =>
    `Ingrese una cantidad mas de 0% para ${customerName}`
}
