<template>
  <img :src="src" alt="" aria-hidden="true" />
</template>

<script>
export default {
  name: 'DecorativeImage',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>
