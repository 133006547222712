<template>
  <div class="popover-wrap">
    <div class="popover-overlay" :class="{ dark: useDarkMode }"></div>
    <PopoverContent />
  </div>
</template>

<script>
import PopoverContent from '@/components/Popover/PopoverContent.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PopoverWrap',
  components: {
    PopoverContent
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
.popover-wrap {
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  z-index: 12;
  will-change: transform, opacity;
  top: 0;
  height: 100%;
  border-radius: var(--containerBorderRadius);
  backdrop-filter: blur(0px);
}

.popover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  will-change: opacity;
  opacity: 0;
  &.dark {
    background: rgba(11, 14, 30, 0.5);
  }
}
</style>
