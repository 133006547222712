<template>
  <div
    role="dialog"
    aria-labelledby="modalLabel"
    aria-describedby="modalDescription"
    class="modal-content"
    :class="{
      'powered-by-atomic-enabled': poweredByAtomic
    }"
    v-focus-trap="{
      targetClass: '.modal-wrap'
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalContent',
  computed: {
    ...mapGetters('theme', ['poweredByAtomic'])
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  height: 100%;
  &.powered-by-atomic-enabled {
    padding-bottom: 0;
  }
}
</style>
