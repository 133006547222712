/* eslint-disable no-empty-pattern */
const state = {
  enabled: false,
  uplink: false,
  uplinkDemoUsername: undefined,
  customer: {}
}

const getters = {
  demoModeEnabled: (state) => {
    return state.enabled
  }
}

const actions = {
  updateDemo({ commit, dispatch }, config) {
    if (config.enabled) commit('setDemoEnabled', config.enabled)
    if (config.customer) commit('setDemoCustomer', config.customer)
    if (config.uplink) {
      commit('setDemoUplink', config.uplink)
      dispatch(
        'main/updatePlatform',
        { name: 'ios', sdkVersion: '3.0.0' },
        { root: true }
      )
    }
  },
  updateUplinkDemoUsername({ commit }, username) {
    commit('setUplinkDemoUsername', username)
  }
}

const mutations = {
  setDemoEnabled: (state, enabled) => {
    state.enabled = enabled
  },
  setDemoCustomer: (state, customer) => {
    state.customer = customer
  },
  setDemoUplink: (state, uplink) => {
    state.uplink = uplink
  },
  setUplinkDemoUsername: (state, username) => {
    state.uplinkDemoUsername = username
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
