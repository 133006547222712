export const MANUAL_DEPOSIT_ACTIONS = {
  DEPOSIT_INFO: 'deposit-info',
  PHONE_LINK: 'phone-link',
  EMAIL_LINK: 'email-link',
  WEBSITE_LINK: 'website-link',
  DOWNLOAD_FILE: 'download-file',
  VOIDED_CHECK: 'voided-check',
  FAX_LINK: 'fax-link',
  ADDRESS: 'address',
  COMPANY_LINK: 'company-link'
}
