import { triggerOld } from '@/util/native/trigger-old'
import { USER_DEVICE_AUTOMATION_EVENTS } from '@/util/constants'
import { store } from '@/store'
import interpolateLocalDataIntoPayload from '@/util/client-side-automation/interpolate-local-data-into-payload'

export async function evaluate(options) {
  const { func, args } = await interpolateLocalDataIntoPayload({
    store,
    payload: options
  })

  const params = JSON.stringify(args ?? [])

  const result = await triggerOld({
    type: USER_DEVICE_AUTOMATION_EVENTS.EVALUATE_ON_DEVICE,
    payload: {
      stringifiedFunctionToEvaluate: `async () => { return { result: await (${func}).apply(null, ${params}) } }`,
      functionParameters: {},
      script: `return (async () => { return { result: await (${func}).apply(null, ${params}) } })().catch(err => ({ result: { evaluateError: { message: err.message, stack: err.stack } } }))`
    }
  })

  return result.payload?.response?.result || result.payload?.result
}
