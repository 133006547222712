import { camelCase, cloneDeep, merge } from 'lodash-es'
import * as English from '@/languages/en/index'
import * as Spanish from '@/languages/es/index'

export function loadI18n({ scope }) {
  const defaultPhrases = {
    en: _combineBaseWithScope({ language: English, scope }),
    es: _combineBaseWithScope({ language: Spanish, scope })
  }

  return defaultPhrases
}

function _combineBaseWithScope({ language, scope }) {
  return _convertESModuleToObject(
    merge(cloneDeep(language.base), cloneDeep(language[camelCase(scope)]))
  )
}

function _convertESModuleToObject(module) {
  return { ...module }
}
