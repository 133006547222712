import Ably from 'ably'
import { chunk } from 'lodash-es'
import { makeRequest } from '@/util/requests'
import { isLocalDev } from '@/util/environment'

let ablyInstance

let logsWeLike = [
  'Channel state for channel',
  'received on web_socket',
  'Connection state:'
]

export default {
  install() {
    ablyInstance = new Ably.Realtime.Promise({
      log: {
        level: isLocalDev() ? 4 : 0,
        handler: (msg) => {
          if (logsWeLike.some((log) => msg.includes(log))) {
            console.log(msg)
          }
        }
      },
      transportParams: {
        heartbeatInterval: 5000
      },
      authCallback: async (tokenParams, callback) => {
        try {
          const response = await makeRequest({
            method: 'get',
            endpoint: '/config/ably-token'
          })

          callback(null, response.data)
        } catch (error) {
          callback(error, null)
        }
      },
      echoMessages: false
    })
    ablyInstance.connection.on('connected', () =>
      console.log('Ably is connected')
    )
    ablyInstance.connection.on('disconnected', () =>
      console.log('Ably is disconnected')
    )
    ablyInstance.connection.on('failed', () => console.error('Ably is failed'))
  },
  get() {
    if (!ablyInstance) this.install()
    return ablyInstance
  },
  async chunkPublish({ channel, eventName, eventData }) {
    try {
      const chunkSize = 55000
      const message = JSON.stringify(eventData)
      const chunks = chunk(message.split(''), chunkSize)
      const messageId = `${Math.random()}`

      for (let i = 0; i < chunks.length; i++) {
        await channel.publish(`${eventName}-chunked`, {
          messageId,
          currentChunk: chunks[i].join(''),
          lastChunk: i === chunks.length - 1
        })
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  close() {
    ablyInstance?.close()
    ablyInstance = undefined
  }
}
