export const SEARCH_TAGS = {
  POPULAR: 'popular',
  PAYROLL: 'payroll',
  EMPLOYER: 'employer',
  GIG: 'gig',
  FRANCHISE_PARENT: 'franchise-parent',
  SUBSCRIPTION: 'subscription',
  CELL: 'cell',
  CABLE: 'cable',
  INSURANCE: 'insurance',
  GOVT: 'govt',
  CARD: 'card'
}
