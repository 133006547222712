<template>
  <Card
    v-if="task && productIsSwitch"
    is="div"
    class="mb-5"
    :aria-label="phrases.shared.inProgressPaymentMethod"
    :aria-description="
      phrases.shared.paymentMethodAriaDescription({
        paymentMethod: task.settings.switch.paymentMethod
      })
    "
  >
    <PaymentMethodOptionContent
      aria-hidden="true"
      :payment-method="task.settings.switch.paymentMethod"
    />
  </Card>
  <TextButton
    data-test-id="close"
    :text="phrases.shared.close"
    @handleClick="handleClickClose"
  />
</template>

<script>
import TextButton from '@/components/Form/TextButton.vue'
import Card from '@/components/Shared/Card.vue'
import PaymentMethodOptionContent from '@/components/Switch/PaymentMethodOptionContent.vue'
import { PRODUCTS } from '@/util/constants'

export default {
  name: 'SelectionDetailModalContentInProgress',
  components: {
    TextButton,
    Card,
    PaymentMethodOptionContent
  },
  emits: ['close'],
  props: {
    selection: Object,
    task: Object
  },
  computed: {
    productIsSwitch() {
      return this.task?.product === PRODUCTS.SWITCH
    },
    productIsPresent() {
      return this.task?.product === PRODUCTS.PRESENT
    }
  },
  methods: {
    handleClickClose() {
      this.$emit('close')
    }
  }
}
</script>
