<template>
  <AppScreen />
</template>

<script>
import AppScreen from '@/components/Layout/AppScreen.vue'
import * as Preloader from '@/util/preloader'
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  initializeStore,
  initializeFirstView,
  parseConfig,
  initI18n
} from '@/util/initialize'
import { validateSdkParameters } from '@/util/sdk'
import { initializeEnvironmentFromConfig } from '@/util/environment'
import Mixpanel from '@/plugins/mixpanel'
import * as Sentry from '@sentry/vue'
import { omit } from 'lodash-es'
import OpenReplay from '@/plugins/openreplay'
import { subscribeUserPresence } from '@/util/realtime'
import { listenForNetworkEvents } from '@/util/network'
import { listenForNativeAppEvents } from '@/util/native/listeners'
import { featureUniqueForUSBank } from '@/util/customization'
import { PayLinkService, taskService } from '@/services'
import { executeUserAction } from '@/util/user-action'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Initialize',
  components: {
    AppScreen
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('main', ['deeplink', 'linkedAccountId']),
    ...mapGetters('main', ['useTransactOpenReplay']),
    ...mapGetters('user', ['customerLogo', 'customerName']),
    ...mapGetters('theme', ['getIcon', 'useInlineLayout']),
    ...mapGetters('taskWorkflow', ['productsIncludeUserAction'])
  },
  methods: {
    ...mapActions('main', ['initializeTransact']),
    ...mapActions('company', ['fetchConnectorDetails']),
    ...mapActions('experiment', ['updateLdFeatureFlags'])
  },
  async created() {
    initI18n({ store: this.$store })
    const config =
      this.$route.params.config || window.atomicStorage.getItem('config')
    const initializeConfig = parseConfig(config)
    const validSdkParameters = validateSdkParameters({
      config: initializeConfig,
      store: this.$store
    })

    // When Transact is used inline and a user presses the back button in the
    // browswer we want to prevent it from initializing the first route again
    // and instead go back in history on the browser.

    // Re-enabling for U.S. Bank
    if (
      window.atomicStorage.getItem('initializedOnce') === 'true' &&
      this.useInlineLayout
    ) {
      this.$router.go(-1)
      return
    }

    initializeEnvironmentFromConfig({
      config: initializeConfig,
      store: this.$store
    })
    Mixpanel.install()

    if (validSdkParameters) {
      window.atomicStorage.setItem('config', config)

      await initializeStore({ config: initializeConfig, store: this.$store })
      await this.initializeTransact({ config: initializeConfig })

      await Preloader.initiateImagePreload({
        customerLogo: this.customerLogo,
        getIcon: this.getIcon,
        store: this.$store
      })

      if (this.userData) {
        Mixpanel.identify(this.userData._id)
        Sentry.setUser({
          id: this.userData._id,
          customer: this.userData.customer
        })
        Sentry.setTag('customer', this.customerName)

        // Subscribe to a user presence channel so we can detect on the backend if the user is present or not
        // Only subscribe if we successfully got back a user, sometimes the GET user endpoint 401's for any
        // number of reasons
        subscribeUserPresence({ userId: this.userData._id })
        listenForNetworkEvents({ store: this.$store })
        listenForNativeAppEvents({
          store: this.$store,
          router: this.$router,
          payLinkService: PayLinkService,
          taskService
        })

        if (this.useTransactOpenReplay) {
          OpenReplay.start({ userId: this.userData._id })
        }
        if (featureUniqueForUSBank({ customer: this.userData.customer })) {
          let tag = document.createElement('script')
          tag.setAttribute('type', 'application/javascript')
          tag.setAttribute(
            'src',
            'https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=19921&site=production'
          )
          tag.setAttribute('id', 'cobrowsescript')
          tag.setAttribute('async', true)
          tag.setAttribute('data-groupid', 19921)
          tag.setAttribute('data-site', 'production')
          tag.setAttribute('data-loaded', true)
          document.head.appendChild(tag)
        }
      }

      this.$analytics.track({
        event: `Initialized Transact`,
        payload: {
          config: omit(initializeConfig, ['publicToken'])
        }
      })

      if (this.productsIncludeUserAction) {
        await executeUserAction({ store: this.$store })
      } else {
        initializeFirstView({ store: this.$store, router: this.$router })
      }

      window.atomicStorage.setItem('initializedOnce', true)
    }
  }
}
</script>
