import { SMART_AUTH_STATE } from '@/util/constants'

const state = {
  authState: SMART_AUTH_STATE.IDLE,
  formData: undefined,
  exceptionMessage: undefined,
  activeAuthenticator: undefined,
  requestData: {
    userInputElements: [],
    textElements: [],
    submitButton: {},
    authenticators: [],
    loginRecoveryOptions: [],
    prompt: undefined,
    exceptionMessage: undefined
  }
}

const mutations = {
  setAuthState: (state, newState) => {
    state.authState = newState
  },
  setFormData: (state, formData) => {
    state.formData = formData
  },
  setRequestData: (state, requestData) => {
    state.requestData = requestData
  },
  setActiveAuthenticator: (state, authenticator) => {
    state.activeAuthenticator = authenticator
  },
  setExceptionMessage: (state, exceptionMessage) => {
    state.exceptionMessage = exceptionMessage
  }
}

const getters = {
  authStateIsIdle: (state) => {
    return state.authState === SMART_AUTH_STATE.IDLE
  },
  isFirstUserConnecting: (state, getters, rootState, rootGetters) => {
    return !rootGetters['company/hasInitialSmartAuthState']
  }
}

const actions = {
  updateFormData({ commit }, formData) {
    commit('setFormData', formData)
  },
  updateAuthState({ commit }, { state }) {
    commit('setAuthState', state)
  },
  updateRequestData({ commit }, payload) {
    commit('setRequestData', payload)
  },
  updateExceptionMessage({ commit }, exceptionMessage) {
    commit('setExceptionMessage', exceptionMessage)
  },
  updateActiveAuthenticator({ commit }, authenticator) {
    commit('setActiveAuthenticator', authenticator)
  },
  resetAuthState({ commit }) {
    commit('setAuthState', SMART_AUTH_STATE.IDLE)
    commit('setFormData', undefined)
    commit('setActiveAuthenticator', undefined)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
