/* eslint-disable no-empty-pattern */
import {
  LANGUAGES,
  TASK_WORKFLOW_RESULT,
  PLATFORMS,
  PROCESSING_MODES,
  ROUTES,
  SCOPES,
  SESSION_CONTEXTS
} from '@/util/constants'
import { showBackButton } from '@/util/back-button'
import router from '@/router'
import { isEmpty } from 'lodash-es'

const state = {
  appIsReady: false,
  publicToken: '',
  useSimulator: false,
  inSdk: true,
  safeMode: false,
  linkedAccountId: undefined,
  pressedBack: false,
  coverActionBar: false,
  language: LANGUAGES.ENGLISH,
  testMode: {},
  deeplink: {},
  platform: {},
  handoff: undefined,
  globalErrorStatus: {
    code: undefined,
    description: undefined
  },
  initializeTerms: {
    isRequiredToStart: false,
    hasAccepted: false
  },
  // Metadata sent to us from our customer
  metadata: undefined,
  features: undefined,
  experiments: undefined,
  environment: undefined,
  authStateWorkaround: undefined,
  previousRouteName: undefined,
  scope: undefined,
  sessionContext: SESSION_CONTEXTS.DEFAULT
}

const getters = {
  isScopePayLink(state) {
    return state.scope === SCOPES.PAY_LINK
  },
  useTransactOpenReplay(state, getters, rootState, rootGetters) {
    return (
      rootState.experiment.ldFlags.useUplinkOpenReplay &&
      !rootGetters['taskWorkflow/productsIncludeSwitch'] // for pci compliance; open replay may expose cc data
    )
  },
  useUplinkOpenReplay(state, getters, rootState, rootGetters) {
    return (
      rootState.experiment.ldFlags.useUplinkOpenReplay &&
      !rootGetters['taskWorkflow/productsIncludeSwitch'] // for pci compliance; open replay may expose cc data
    )
  },
  showBackButton() {
    return showBackButton()
  },
  usesManualFallbackFeature: (state) => {
    return state.features?.manualFallbackCallToAction?.enabled && state.inSdk
  },
  showManualFallbackCta: (state, getters, rootState, rootGetters) => {
    return (
      getters.usesManualFallbackFeature &&
      !getters.isTrueAuthSessionContext &&
      rootGetters['taskWorkflow/productsIncludeDeposit'] &&
      ((router.currentRoute?.value?.name === ROUTES.TASK &&
        rootState.taskWorkflow?.taskWorkflowState?.result ===
          TASK_WORKFLOW_RESULT.FAILED) ||
        router.currentRoute?.value?.name === ROUTES.SEARCH_PAYROLL)
    )
  },
  allowFractionalDeposits: (state) => {
    return state.experiments?.fractionalDeposits !== undefined
      ? state.experiments?.fractionalDeposits
      : state.features?.fractionalDeposits?.enabled
  },
  customerDiscouragesSSOAuthentications: (state) => {
    return state?.features?.discourageSsoAuthentications
  },
  showConfirmDistributionBeforeTaskBypass: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return (
      (rootGetters['taskWorkflow/productsIncludeDeposit'] &&
        rootState.distribution.canUpdateDistribution) ||
      (rootGetters['taskWorkflow/productsIncludeDeposit'] &&
        rootState.distribution.presentUpdateDistribution) ||
      (rootGetters['taskWorkflow/productsIncludeDeposit'] &&
        !rootState.distribution.distributionEnforced)
    )
  },
  useSanitizedLogos: (state) => {
    return state.features?.logoSanitization?.enabled
  },
  platform: (state) =>
    isEmpty(state.platform)
      ? {
          name: PLATFORMS.WEBVIEW
        }
      : state.platform,
  platformName: (_, getters) => getters.platform.name,
  platformSdkVersion: (_, getters) => getters.platform?.sdkVersion || '0.0.0',
  isBrowserPlatform: (state) =>
    state.platform.name === PLATFORMS.BROWSER || !state.platform.name,
  isIOSPlatform: (_, getters) => getters.platformName === PLATFORMS.IOS,
  allowManualFallbackMethods: (state, getters, rootState) => {
    const manualDepositFlowFeatureEnabled =
      rootState?.user?.userData?.customer?.features?.manualDepositFlow?.enabled
    const manualDepositFlowExperiment = state.experiments?.manualFallback

    if (manualDepositFlowExperiment === false) {
      return false
    } else if (manualDepositFlowFeatureEnabled || manualDepositFlowExperiment) {
      return true
    } else {
      return false
    }
  },
  showManualFallbackMethods: (state, getters, rootState, rootGetters) => {
    return (
      getters.allowManualFallbackMethods &&
      rootGetters['taskWorkflow/productsIncludeDeposit']
    )
  },
  isTrueAuthSessionContext: (state) =>
    state.sessionContext === SESSION_CONTEXTS.TRUE_AUTH_APP,
  processingMode: (state) =>
    state.scope === SCOPES.PAY_LINK
      ? PROCESSING_MODES.CONCURRENT
      : PROCESSING_MODES.SERIAL,
  isConcurrentProcessingMode: (_, getters) =>
    getters.processingMode === PROCESSING_MODES.CONCURRENT,
  isSerialProcessingMode: (_, getters) =>
    getters.processingMode === PROCESSING_MODES.SERIAL
}

const actions = {
  async initializeTransact({ dispatch }, { config }) {
    await dispatch('experiment/initializeAbTestVariants', undefined, {
      root: true
    })
    await dispatch('user/fetchUser', config, { root: true })
  },
  updatePublicToken({ commit }, payload) {
    commit('setPublicToken', payload)
  },
  updatePreviousRouteName({ commit }, routeName) {
    commit('setPreviousRouteName', routeName)
  },
  updateScope({ commit }, payload) {
    commit('setScope', payload)
  },
  updateSessionContext({ commit }, payload) {
    commit('setSessionContext', payload)
  },
  updateInSdk({ commit }, payload) {
    commit('setInSdk', payload)
  },
  updateSafeMode({ commit }, payload) {
    commit('setSafeMode', payload)
  },
  updateLinkedAccount({ commit }, id) {
    commit('setLinkedAccount', id)
  },
  updateTestMode({ commit }, payload) {
    commit('setTestMode', payload)
  },
  updateDeeplink({ commit }, payload) {
    commit('setDeeplink', payload)
  },
  updatePlatform({ commit }, payload) {
    commit('setPlatform', payload)
  },
  updateUseSimulator({ commit }, payload) {
    commit('setUseSimulator', payload)
  },
  updateHandoff({ commit }, payload) {
    commit('setHandoff', payload)
  },
  updatePressedBack({ commit }, payload) {
    commit('setPressedBack', payload)
  },
  coverActionBar({ commit }) {
    // We don't hid the action bar on Android, since as of 11/20/20
    // there is no way to detect the keyboard closing to show the bar again
    if (IS_MOBILE && !IS_ANDROID) commit('setCoverActionBar', true)
  },
  uncoverActionBar({ commit }) {
    commit('setCoverActionBar', false)
  },
  updateGlobalErrorStatus({ commit }, error) {
    commit('setGlobalErrorStatus', error)
  },
  resetLinkedAccount({ commit }) {
    commit('setLinkedAccount', undefined)
  },
  goBackToPreviousPage: ({ dispatch }) => {
    dispatch('updatePressedBack', true)
    router.go(-1)
  },
  updateInitializeTerms({ commit }, payload) {
    commit('setInitializeTerms', payload)
  },
  updateUserMetadata({ commit }, metadata) {
    commit('setUserMetadata', metadata)
  },
  updateFeatures({ state, commit }, features) {
    commit('setFeatures', { ...state.features, ...features })
  },
  updateExperiments({ commit }, experiments) {
    commit('setExperiments', experiments)
  },
  updateEnvironment({ commit }, environment) {
    commit('setEnvironment', environment)
  },
  updateAuthStateWorkaround({ commit }, authState) {
    commit('setAuthStateWorkaround', authState)
  },
  updateManualDepositFlow({ commit }, manualDepositFlow) {
    commit('setManualDepositFlow', manualDepositFlow)
  },
  updateAppIsReady({ commit }, isReady) {
    commit('setAppIsReady', isReady)
  }
}

const mutations = {
  setPublicToken: (state, publicToken) => {
    state.publicToken = publicToken
  },
  setCoverActionBar: (state, covered) => {
    state.coverActionBar = covered
  },
  setUseSimulator: (state, useSimulator) => {
    state.useSimulator = useSimulator
  },
  setInSdk: (state, inSdk) => {
    state.inSdk = inSdk
  },
  setSafeMode: (state, mode) => {
    state.safeMode = mode
  },
  setLinkedAccount: (state, id) => {
    state.linkedAccountId = id
  },
  setTestMode: (state, settings) => {
    state.testMode = settings
  },
  setHandoff: (state, handoff) => {
    state.handoff = handoff
  },
  setPressedBack: (state, value) => {
    state.pressedBack = value
  },
  setDeeplink: (state, deeplink) => {
    state.deeplink = deeplink
  },
  setPlatform: (state, platform) => {
    state.platform = platform
  },
  setGlobalErrorStatus: (state, status) => {
    state.globalErrorStatus.code = status.code
    state.globalErrorStatus.description = status.description
  },
  setInitializeTerms: (state, data) => {
    state.initializeTerms.isRequiredToStart = data.isRequiredToStart
    state.initializeTerms.hasAccepted = data.hasAccepted
  },
  setUserMetadata: (state, metadata) => {
    state.metadata = metadata
  },
  setFeatures: (state, features) => {
    state.features = features
  },
  setExperiments: (state, experiments) => {
    state.experiments = experiments
  },
  setEnvironment: (state, environment) => {
    state.environment = environment
  },
  setAuthStateWorkaround: (state, authState) => {
    state.authStateWorkaround = authState
  },
  setPreviousRouteName: (state, routeName) => {
    state.previousRouteName = routeName
  },
  setScope: (state, scope) => {
    state.scope = scope
  },
  setSessionContext: (state, sessionContext) => {
    state.sessionContext = sessionContext
  },
  setManualDepositFlow: (state, manualDepositFlow) => {
    state.manualDepositFlow = manualDepositFlow
  },
  setAppIsReady: (state, isReady) => {
    state.appIsReady = isReady
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
