import { merge } from '@/util/merge'
import {
  DISTRIBUTION_VIEW,
  DISTRIBUTION_ACTION_TYPES,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_LIMIT_TYPES
} from '@/util/constants'

const defaultFractionalError = () => ({
  requiresAction: false,
  depositAccounts: [],
  availableDistributionTypes: []
})

const defaultDepositSettings = () => ({
  distributionType: DISTRIBUTION_TYPES.TOTAL,
  distributionAmount: undefined,
  fixedDistributionLimits: {
    minimum: {
      amount: 0,
      type: DISTRIBUTION_LIMIT_TYPES.WARN
    },
    maximum: {
      amount: Infinity,
      type: DISTRIBUTION_LIMIT_TYPES.WARN
    }
  },
  percentDistributionLimits: {
    minimum: {
      amount: 0,
      type: DISTRIBUTION_LIMIT_TYPES.WARN
    },
    maximum: {
      amount: 100,
      type: DISTRIBUTION_LIMIT_TYPES.WARN
    }
  }
})

const defaultNewDepositFlowConfig = () => ({
  fixedAndPercentTypesAllowed: true
})

const state = {
  distributionState: {},
  ...defaultDepositSettings(),
  distributionAction: DISTRIBUTION_ACTION_TYPES.CREATE,
  distributionEnforced: false,
  canUpdateDistribution: false,
  presentUpdateDistribution: false,
  fractionalError: defaultFractionalError(),
  distributionList: [],
  activeDepositAccountChanges: [],
  depositAccounts: [],
  newDepositFlowConfig: defaultNewDepositFlowConfig(),
  newDistributionType: undefined,
  newDistributionAmount: undefined,
  selectedDistributionType: undefined
}

// If the connector is Workday and Coinbase is the customer, don't allow fractional deposits
const BETA_FRACTIONAL_CONNECTORS = ['5f036115d366be000833fe71']
const CUSTOMERS_THAT_WANT_ONLY_GA_FRACTIONAL_CONNECTORS = []

const getters = {
  canChangeDistribution: (state, getters, rootState, rootGetters) => {
    const userIsUsingDeposit =
      rootGetters['taskWorkflow/productsIncludeDeposit']

    const customerHasEnabledFractionalDeposits =
      rootGetters['main/allowFractionalDeposits']

    const distributionTypes =
      rootState.company.connector?.capabilities?.distributionTypes || []

    const connectorSupportsFractionalDeposits =
      distributionTypes.includes(DISTRIBUTION_TYPES.FIXED) ||
      distributionTypes.includes(DISTRIBUTION_TYPES.PERCENT)

    const isBetaFractionalConnector = BETA_FRACTIONAL_CONNECTORS.includes(
      rootState.company.connector._id
    )

    const customerOnlyWantsGAFractionalConnectors =
      CUSTOMERS_THAT_WANT_ONLY_GA_FRACTIONAL_CONNECTORS.includes(
        rootState.user.userData.customer._id
      )

    const betaFractionalConnectorIsOkay =
      !isBetaFractionalConnector ||
      (isBetaFractionalConnector && !customerOnlyWantsGAFractionalConnectors)

    const defaultCanChangeDistribution =
      userIsUsingDeposit &&
      customerHasEnabledFractionalDeposits &&
      connectorSupportsFractionalDeposits &&
      betaFractionalConnectorIsOkay &&
      (state.canUpdateDistribution || !state.distributionEnforced)

    const manualFlowCanChangeDistribution =
      userIsUsingDeposit &&
      rootGetters['taskWorkflow/taskWorkflowTypeIsManualFallback'] &&
      rootGetters['main/allowManualFallbackMethods'] &&
      customerHasEnabledFractionalDeposits

    return defaultCanChangeDistribution || manualFlowCanChangeDistribution
  },
  shouldResetDistribution: (state) => {
    return !state.distributionEnforced
  },
  distributionLimits: (state) => {
    return state[`${state.selectedDistributionType}DistributionLimits`]
  }
}

const mutations = {
  setDistributionState: (state, distributionState) => {
    state.distributionState = distributionState
  },
  setDistributionType: (state, type) => {
    state.distributionType = type
  },
  setDistributionAmount: (state, amount) => {
    state.distributionAmount = amount
  },
  setFixedDistributionLimits: (state, limits) => {
    state.fixedDistributionLimits = merge(state.fixedDistributionLimits, limits)
  },
  setPercentDistributionLimits: (state, limits) => {
    state.percentDistributionLimits = merge(
      state.percentDistributionLimits,
      limits
    )
  },
  setDistributionAction: (state, action) => {
    state.distributionAction = action
  },
  setDistributionEnforced: (state, enforced) => {
    state.distributionEnforced = enforced
  },
  setCanUpdateDistribution: (state, canUpdate) => {
    state.canUpdateDistribution = canUpdate
  },
  setPresentUpdateDistribution: (state, isPresented) => {
    state.presentUpdateDistribution = isPresented
  },
  setFractionalError: (state, fractionalError) => {
    state.fractionalError = { ...fractionalError }
  },
  setDistributionList: (state, distributionList) => {
    state.distributionList = distributionList
  },
  setDepositAccounts: (state, accounts) => {
    state.depositAccounts = accounts
  },
  setNewDepositFlowConfig: (state, config) => {
    state.newDepositFlowConfig = { ...state.newDepositFlowConfig, ...config }
  },
  setActiveDepositAccountChanges: (state, activeDepositAccountChanges) => {
    state.activeDepositAccountChanges = activeDepositAccountChanges
  },
  setNewDistributionType(state, newDistributionType) {
    state.newDistributionType = newDistributionType
  },
  setNewDistributionAmount(state, newDistributionAmount) {
    state.newDistributionAmount = newDistributionAmount
  },
  setDistributionLimits(state, distributionType) {
    state.selectedDistributionType = distributionType
  }
}

const actions = {
  updateDistributionState({ commit }, distributionState) {
    commit('setDistributionState', distributionState)
  },
  clearDistributionState({ commit, dispatch }) {
    commit('setDistributionState', {
      view: DISTRIBUTION_VIEW.TYPE
    })
    dispatch('resetNewDistributionSettings')
  },
  resetDistributionSettings({ state, commit }) {
    if (!state.distributionEnforced) {
      const defaultSettings = defaultDepositSettings()
      commit('setDistributionType', defaultSettings.distributionType)
      commit('setDistributionAmount', defaultSettings.distributionAmount)
    }
  },
  updateDistributionType({ commit }, payload) {
    commit('setDistributionType', payload)

    if (payload === DISTRIBUTION_TYPES.TOTAL) {
      commit('setDistributionAmount', undefined)
    }
  },
  updateDistributionAmount({ commit }, payload) {
    commit('setDistributionAmount', payload)
  },
  updateFixedDistributionLimits({ commit }, payload) {
    commit('setFixedDistributionLimits', payload)
  },
  updatePercentDistributionLimits({ commit }, payload) {
    commit('setPercentDistributionLimits', payload)
  },
  updateDepositAccounts({ commit }, accounts) {
    commit('setDepositAccounts', accounts)
  },
  updateNewDepositFlowConfig({ commit }, config) {
    commit('setNewDepositFlowConfig', config)
  },
  updateActiveAccountChanges({ commit }, payload) {
    commit('setActiveDepositAccountChanges', payload)
  },
  updateFractionalError: ({ commit }, fractionalError) => {
    commit(
      'setFractionalError',
      fractionalError
        ? { requiresAction: true, ...fractionalError }
        : defaultFractionalError()
    )
  },
  updateDistributionSettings: (
    { commit },
    { type, amount, action, canUpdate, isPresented }
  ) => {
    if (type) commit('setDistributionType', type)
    if (amount || amount === 0) commit('setDistributionAmount', amount)
    if (action) commit('setDistributionAction', action)
    commit('setDistributionEnforced', true)
    commit('setCanUpdateDistribution', !!canUpdate)
    commit('setPresentUpdateDistribution', !!isPresented)
  },
  handleFractionalDepositUpdates: ({ dispatch, state }) => {
    dispatch(
      'task/taskResponseHandler',
      {
        fractionalResponse: {
          distributionType: state.distributionType,
          distributionAmount: state.distributionAmount
        }
      },
      { root: true }
    )
  },
  handleMultiAccountUpdates: ({ dispatch, state }) => {
    const originalAccount = state.activeDepositAccountChanges.find(
      (account) => account.isTaskAccount
    )

    dispatch(
      'task/taskResponseHandler',
      {
        multiAccountChange: {
          distributionType: originalAccount.distributionType,
          distributionAmount: originalAccount.distributionAmount,
          userAccountChanges: state.activeDepositAccountChanges
        }
      },
      { root: true }
    )
  },
  async fetchDistributionList(
    { commit, rootGetters },
    { availableDistributionTypes }
  ) {
    const canPercentDeposit = availableDistributionTypes.includes(
      DISTRIBUTION_TYPES.PERCENT
    )
    const canFixedDeposit = availableDistributionTypes.includes(
      DISTRIBUTION_TYPES.FIXED
    )

    const distributionList = [
      {
        id: DISTRIBUTION_TYPES.TOTAL,
        title: rootGetters['experiment/ldFlags'].condensedDistribution
          ? rootGetters['i18n/phrases'].distribution.entireAmount
          : rootGetters['i18n/phrases'].distribution.entireAmount,
        disabled: false,
        icon: {
          default: 'paperCheck'
        }
      },
      {
        id: DISTRIBUTION_TYPES.PERCENT,
        title: rootGetters['experiment/ldFlags'].condensedDistribution
          ? rootGetters['i18n/phrases'].distribution.percentageAmount
          : rootGetters['i18n/phrases'].distribution.percentage,
        unsupported: !canPercentDeposit
          ? rootGetters['i18n/phrases'].distribution.unsupported
          : undefined,
        disabled: !canPercentDeposit,
        icon: {
          default: 'percentage'
        }
      },
      {
        id: DISTRIBUTION_TYPES.FIXED,
        title: rootGetters['experiment/ldFlags'].condensedDistribution
          ? rootGetters['i18n/phrases'].distribution.specificAmount2
          : rootGetters['i18n/phrases'].distribution.specificAmount,
        unsupported: !canFixedDeposit
          ? rootGetters['i18n/phrases'].distribution.unsupported
          : undefined,
        disabled: !canFixedDeposit,
        icon: {
          default: 'dollarBill'
        }
      }
    ]

    await commit('setDistributionList', [
      ...distributionList.filter((o) => !o.disabled),
      ...distributionList.filter((o) => o.disabled)
    ])
  },
  updateNewDistributionType({ commit }, payload) {
    commit('setNewDistributionType', payload)

    if (payload === DISTRIBUTION_TYPES.TOTAL) {
      commit('setNewDistributionAmount', undefined)
    }
  },
  updateNewDistributionAmount({ commit }, payload) {
    commit('setNewDistributionAmount', payload)
  },
  resetNewDistributionSettings({ commit, state }) {
    commit('setNewDistributionType', state.distributionType)
    commit('setNewDistributionAmount', state.distributionAmount)
  },
  commitNewDistributionSettings({ dispatch, state }) {
    dispatch('updateDistributionType', state.newDistributionType)
    dispatch('updateDistributionAmount', state.newDistributionAmount)
    dispatch('resetNewDistributionSettings')
  },
  updateDistributionLimits({ commit }, distributionType) {
    commit('setDistributionLimits', distributionType)
  },
  clearDistributionLimits({ commit }) {
    commit('setDistributionLimits', undefined)
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
