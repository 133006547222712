<template>
  <div
    id="popover-content"
    class="popover-content"
    :class="{ dark: useDarkMode }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PopoverContainer',
  computed: {
    ...mapGetters('theme', ['useDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
.popover-content {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--containerBorderRadius);
  z-index: 1;
  min-width: 320px;
  width: 90%;
  height: 95%;
  max-height: 900px;
  max-width: 600px;
  padding: calc(var(--paddingBase) * 2);
  transform: translate(-50%, 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 16px var(--gray-300);

  &.dark {
    background: var(--darkModeBg);
    box-shadow: 0 0 16px var(--gray-800);
  }
}
</style>
