export const progress = {
  connection: ({ name }) => `Conectando a ${name}`,
  titleAuthentication: 'Verifique sus credenciales',
  titleDeposit: 'Actualizando su depósito',
  titleVerify: 'Verificando su empleo',
  titleWithhold: 'Actualizando sus retenciones',
  titleRecoveryFind: 'Encontrando su cuenta',
  titleRecoveryReset: 'Actualizando sus credenciales',
  tax: {
    connection: ({ connectorName }) => `Conectando a ${connectorName}`,
    verify: 'Verificando sus credenciales',
    collect: 'Recopilando documentos',
    pull: 'Reuniendo documentos fiscales'
  }
}
