import { emitSdkEvent } from '@/util/sdk'
import { HAPTIC_TAP_WEIGHTS, SDK_EVENT_TYPES } from '@/util/constants'

export default {
  notifySuccess() {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: 'haptic-success'
    })
  },
  notifyError() {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: 'haptic-error'
    })
  },
  tap({ weight = HAPTIC_TAP_WEIGHTS.LIGHT } = {}) {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: 'haptic-tap',
      weight
    })
  },
  select() {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: 'haptic-selection'
    })
  }
}
