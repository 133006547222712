<template>
  <div
    class="skeleton-block-wrap"
    :class="[shape, { dark: useDarkMode }]"
    :style="{ width: shapeWidth, height: shapeHeight }"
  >
    <div class="skeleton-block"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SkeletonBlock',
  props: {
    shape: String,
    shapeWidth: String,
    shapeHeight: String
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
.skeleton-block-wrap {
  background: var(--gray-200);
  position: relative;
  overflow: hidden;

  .skeleton-block {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:after {
      animation: waves 1000ms forwards infinite linear;
      background: linear-gradient(
        to right,
        hsla(231, 20%, 87%, 0),
        hsla(231, 20%, 87%, 0.4),
        hsla(231, 20%, 87%, 0)
      );
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(-100%);
    }
  }

  &.dark {
    background: hsla(231, 30%, 18%, 0.5);
    .skeleton-block {
      &:after {
        background: linear-gradient(
          to right,
          hsla(231, 20%, 38%, 0),
          hsla(231, 20%, 38%, 0.1),
          hsla(231, 20%, 38%, 0)
        );
      }
    }
  }

  &.box {
    border-radius: var(--smallBorderRadius);
    .skeleton-block {
      border-radius: var(--smallBorderRadius);
    }
  }

  &.circle {
    border-radius: 50%;
    .skeleton-block {
      border-radius: 50%;
    }
  }
}

@keyframes waves {
  to {
    transform: translateX(100%);
  }
}
</style>
