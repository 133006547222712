export const TASK_WORKFLOW_VIEW = {
  ADD_CARD_FORM: 'add-card-form',
  CONFIRM_DISTRIBUTION: 'confirm-distribution',
  CONFIRM_MANUAL_USER_SESSION_ACTION: 'confirm-manual-user-session-action',
  DATA_VERIFICATION: 'data-verification',
  FINISHED: 'finished',
  FRACTIONAL_DEPOSIT_ERROR: 'fractional-deposit-error',
  IN_PROGRESS: 'in-progress',
  INTERSTITIAL: {
    ADD_CARD: 'add-card-interstitial',
    CHECK_VPN_CONNECTIVITY: 'check-vpn-connectivity',
    CONFIGURABLE_CONNECTOR_COMPANY_SEARCH:
      'configurable-connector-company-search',
    CONFIGURABLE_CONNECTOR_COMPANY_SEARCH_RESULTS:
      'configurable-connector-company-search-results',
    MAINTENANCE_MODE: 'maintenance-mode',
    MANUAL_FALLBACK: 'manual-fallback',
    MULTIPLE_PAYROLL_PROVIDERS: 'multiple-payroll-providers',
    ROUTING_NUMBER_BLOCKED: 'routing-number-blocked',
    SYSTEM_AFTER_HOURS: 'system-after-hours',
    PAY_LINK_PRE_LOGIN: 'pay-link-pre-login'
  },
  LOGIN: 'login',
  MANUAL: 'manual',
  MANUAL_FALLBACK_ACCOUNT_ROUTING: 'manual-fallback-account-routing',
  MANUAL_FALLBACK_PREFILLED: 'manual-fallback-prefilled',
  MANUAL_FALLBACK_PREFILLED_SUCCESS: 'manual-fallback-prefilled-success',
  MANUAL_FALLBACK_SUMMARY: 'manual-fallback-summary',
  MANUAL_SPARK: 'manual-spark',
  MANUAL_WALMART: 'manual-walmart',
  INTERRUPT: 'interrupt',
  PLACEHOLDER: 'placeholder'
}
