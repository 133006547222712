import Ably from '@/plugins/ably'
import OpenReplay from '@/plugins/openreplay'

export function subscribeUserPresence({ userId }) {
  Ably.get().channels.get(`${userId}:presence:app`).presence.enter()
}

export function disconnectRealtimeConnections() {
  Ably.close()
  OpenReplay.stop()
}
