export const REQUEST_TYPES = {
  AUTHENTICATION: 'auth',
  EXTERNAL_LOGIN_RECOVERY: 'externalLoginRecovery',
  FORM: 'form',
  MFA: 'mfa',
  MULTI_SELECT: 'multiSelect',
  LOGIN_RECOVERY: 'loginRecovery',
  SMART_AUTH_FORM: 'smart-auth-form',
  TASK_UPDATE: 'taskUpdate',
  PRE_SCREENED_LOGIN: 'preScreenedLogin'
}
