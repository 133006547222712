// merge copies all entries from source to target,
// if the values in source are defined, and not null
// this differs from _.merge by handling nulls

export const merge = (target, source = {}) => {
  Object.entries(source).forEach(([key, val]) => {
    if (val) {
      if (typeof val === 'object' && typeof target[key] === 'object') {
        target[key] = merge(target[key], val)
      } else {
        target[key] = val
      }
    }
  })
  return target
}
