const shared = {
  title: '¿A quién le paga?',
  suggestionsListLabel: 'Proveedores populares',
  zeroResultsFound: ({ searchText }) =>
    `Parece que aún no admitimos "${searchText}"`,
  zeroResultsFoundDescription: `Estamos trabajando constantemente para aumentar la cobertura de proveedores.`
}

export const searchCompany = {
  switch: {
    ...shared,
    inputPlaceholder: 'Facturador o suscripción'
  },
  present: {
    ...shared,
    inputPlaceholder: 'Comerciantes'
  }
}
