<template>
  <button
    @click="() => optionSelected({ option, index })"
    class="select-option content-only focus-visible-width"
    :class="{
      disabled: option.disabled,
      active: option.selected,
      dark: useDarkMode,
      'has-image-left': option.icon
    }"
    :aria-checked="selectedIndex === index"
    :aria-hidden="option.disabled"
    :aria-label="option.ariaLabel"
    :aria-description="option.ariaDescription"
    :data-test-id="option.id"
    :id="`${option.id}-option`"
  >
    <span v-if="hasSlotContent" class="content-slot">
      <slot name="content" v-bind="option" />
    </span>

    <template v-else>
      <DecorativeImage
        v-if="option.icon?.default"
        class="image-left"
        :src="iconURL"
      />

      <span class="text">
        <span class="title">{{ option.label || option.title }}</span>

        <span v-if="option.subtitle" class="subtitle">{{
          option.subtitle
        }}</span>
      </span>
    </template>

    <div class="content-right">
      <div class="unsupported-badge" v-if="option.disabled">
        {{ option.unsupported }}
      </div>
      <div v-else class="image-right">
        <DecorativeImage v-if="arrow" :src="iconArrow" />
        <transition v-else name="fade">
          <span
            v-show="option.selected"
            class="icon-selected"
            :style="{ '--icon-selected-background': brandColor }"
          >
            <IconCheck :size="16" />
          </span>
        </transition>
      </div>
    </div>
  </button>
</template>

<script>
import DecorativeImage from '@/components/Shared/DecorativeImage.vue'
import IconCheck from '@/components/Icons/Atomic/IconCheck.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FormSelectButton',
  components: {
    DecorativeImage,
    IconCheck
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    selectedIndex: {
      type: Number,
      required: true
    },
    arrow: Boolean
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode', 'getIcon']),
    ...mapGetters('company', ['brandColor']),
    iconURL() {
      return this.getIcon(this.option.icon.default)
    },
    iconArrow() {
      return this.getIcon('arrowRight')
    },
    hasSlotContent() {
      return !!this.$slots.content
    }
  },
  methods: {
    optionSelected({ option, index }) {
      this.$emit('selectOption', { option, index })
    }
  }
}
</script>

<style scoped lang="scss">
.select-option {
  min-height: 56px;
  border-radius: 8px;
  background: var(--gray-100);
  color: var(--gray-800);
  font-size: 1.6rem;
  display: flex;
  gap: var(--marginBase);
  padding: 16px;
  transition: var(--button-hover);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(var(--marginBase) * 2);

  &:first-child {
    margin-top: 0;
  }

  &.dark {
    background: var(--gray-900);
    color: var(--darkModeTextHigh);
  }

  &.disabled {
    opacity: 0.6;
  }

  &:not(.disabled) {
    &.active,
    &:hover {
      cursor: pointer;
      transform: scale(0.98);
      opacity: 0.8;

      &.dark {
        background: var(--gray-800);
      }

      .subtitle {
        color: var(--gray-700);

        .dark & {
          color: var(--gray-400);
        }
      }
    }
  }

  &.has-image-left {
    // Reduce padding when there's an image
    padding: 12px;
  }
}

.survey-block .select-option {
  margin-top: var(--marginBase);
  font-size: 1.4rem;
  line-height: 1.2rem;
  padding: 12px 16px;
  min-height: 0;

  .title {
    line-height: 1.4rem;
  }
}

.image-left {
  max-height: 44px;
  max-width: 44px;
  object-fit: contain;
  object-position: center;
  margin-right: 12px;

  &.shadow {
    box-shadow: 0px 4px 6px -2px rgba(51, 55, 77, 0.03),
      0px 12px 16px -4px rgba(51, 55, 77, 0.03);
  }
}

.content-right {
  flex-grow: 0;
  flex-shrink: 0;
}

.image-right {
  width: 24px;
  height: 24px;
}

.unsupported-badge {
  font-size: 1rem;
  color: var(--gray-1000);
  background: var(--gray-300);
  padding: 8px;
  border-radius: 4px;
  text-transform: uppercase;

  .dark & {
    background: var(--gray-800);
    color: var(--darkModeTextMedium);
  }
}

.icon-selected {
  background: var(--icon-selected-background, var(--primary));
  height: 24px;
  width: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-slot {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  text-align: left;
  margin-right: auto;
  line-height: 2.4rem;
}

.subtitle {
  color: var(--gray-600);
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  text-transform: capitalize;
  transition: all 100ms linear;

  .dark & {
    color: var(--darkModeTextLow);
  }
}

.fade-enter-active,
.fade-leave-active {
  // Faster transition than default for "selected" icon
  transition: opacity 100ms linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
