export const taskProgress = {
  noNeedToHangAround: 'No need to stick around—we’ll take it from here!',
  progressTitleDeposit: 'Almost there! Updating your direct deposit...',
  progressTitleVerify: 'Almost there! Linking your payroll...',
  progressTitleWithhold: 'Almost there! Updating your withholdings...',
  progressTitleAggregate: `You're almost there. We're gathering your data...`,
  ariaSuccessPrompt: ', finished successfully.',
  ariaWaitPrompt: 'is still in progress, please wait.',
  messageAverage: 'Typically, this takes around',
  seconds: 'seconds',
  minutes: 'minutes',
  estimatedTimeRemaining: 'Est. time remaining',
  tax: {
    title: 'Almost there! Gathering your tax documents...'
  }
}
