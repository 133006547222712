import Analytics from '@/plugins/analytics'
import { stopOpenReplayDeprecated } from '@/util/client-side-automation/script-injections'
import { backButtonHandler } from '@/util/back-button'
import { emitSdkEvent } from '@/util/sdk'
import {
  SDK_EVENT_TYPES,
  TASK_WORKFLOW_VIEW,
  USER_DEVICE_INTERACTION_EVENTS
} from '@/util/constants'

export async function exitAuth({ store }) {
  Analytics.get().track({
    event: 'Native SDK Closed Auth',
    payload: { url: store.state.userDeviceAutomation.activeSession.currentURL },
    internal: true
  })

  await store.dispatch('userDeviceAutomation/updateActiveSession', {
    updates: { runTriggers: false }
  })

  stopOpenReplayDeprecated({ store })

  if (_showConfirmManualUserSessionAction({ store })) {
    await _navigateToConfirmUserSessionAction({ store })
  } else {
    await backButtonHandler({ store })
  }

  emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, {
    name: USER_DEVICE_INTERACTION_EVENTS.CLOSED,
    value: {}
  })
}

function _showConfirmManualUserSessionAction({ store }) {
  return (
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']
      .keepAliveAfterAuth &&
    store.getters['taskWorkflow/productsIncludeDeposit']
  )
}

async function _navigateToConfirmUserSessionAction({ store }) {
  await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.CONFIRM_MANUAL_USER_SESSION_ACTION
  })
}
