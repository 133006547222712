export const welcome = {
  protectYouButtonText: 'Aprender cómo está protegido',
  depositTitle: ({ customerName }) =>
    `Actualiza su depósito directo a ${customerName}`,
  verifyTitle: ({ customerName }) =>
    `Conectar su proveedor de nómina a ${customerName}`,
  hasAccessTitle: 'Permite el acceso a',
  noAccessTitle: 'Nunca permite el acceso a',
  accessBulletDeposit: ({ customerName }) =>
    `Actualice su cuenta de depósito directo a ${customerName}.`,
  accessBulletVerify: 'Ingresos, recibos de pago, hojas de asistencia.',
  accessBulletIdentify: 'Nombre, dirección e información de contacto.',
  taxTitle: ({ customerName }) =>
    `${customerName} usa Atomic para obtener sus documentos fiscales.`,
  tax: {
    bullet: {
      secure: {
        title: 'Asegurar',
        description: 'Ciframos sus datos de inicio de sesión.'
      },
      protect: {
        title: 'Proteger',
        description: 'Trabajamos para mantener su información segura.'
      }
    }
  },
  v2: {
    deposit: {
      title1: 'Mueve',
      title2: 'su',
      title3: 'dinero',
      subtitle: 'Como funciona',
      step1: 'Encuentra quien se paga',
      step1Description: 'Gig, Employer, SSI',
      step2: 'Ingrese sus credenciales',
      step3: 'Conectamos y actualizamos su nómina',
      button: `Hagámoslo`,
      securedByAtomic: 'Asegurado por Atomic'
    }
  }
}
