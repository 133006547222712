import { DEEPLINK_STEPS } from '@/util/constants'

export function initiateImagePreload({ customerLogo, getIcon, store }) {
  const mainStore = store.state.main
  const alwaysPreloadTheseImages = [customerLogo]
  let imagePreloadList = [
    ...alwaysPreloadTheseImages,
    getIcon('threeDots'),
    getIcon('keypadDelete'),
    getIcon('inputSearch'),
    `/img/connectors/default.svg`,
    `/img/notification/icon-lock.svg`,
    `/img/notification/icon-wifi-off.svg`
  ]

  // We only need the images for the welcome screen if they're going to it
  if (!mainStore.deeplink?.step) {
    imagePreloadList = [
      ...imagePreloadList,
      getIcon(`depositAccess`),
      getIcon(`identifyAccess`),
      getIcon(`verifyAccess`),
      getIcon(`noDepositAccess`),
      getIcon(`noIdentifyAccess`),
      getIcon(`noVerifyAccess`)
    ]
  } else if (mainStore.deeplink.step === DEEPLINK_STEPS.SEARCH_COMPANY) {
    imagePreloadList = [...alwaysPreloadTheseImages, getIcon('inputSearch')]
  }

  preloadImages([...imagePreloadList])
}

export async function preloadImages(imageList) {
  return Promise.all(
    imageList.map(
      (image) =>
        new Promise((resolve) => {
          const img = new Image()
          img.onload = function () {
            resolve()
          }
          img.onerror = function () {
            resolve()
          }
          img.src = image
        })
    )
  )
}
