<template>
  <SkeletonTemplate>
    <SkeletonBlock shape="box" shapeWidth="172px" shapeHeight="24px" />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="48px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="300px"
      shapeHeight="32px"
    />
    <div class="grid-skeleton mt-1">
      <div v-for="index in 16" :key="index" class="mt-2 mr-2">
        <SkeletonBlock shape="box" shapeWidth="56px" shapeHeight="56px" />
        <SkeletonBlock
          class="mt-1"
          shape="box"
          shapeWidth="56px"
          shapeHeight="12px"
        />
      </div>
    </div>
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'SearchGridSkeleton',
  components: {
    SkeletonBlock,
    SkeletonTemplate
  }
}
</script>

<style lang="scss" scoped>
.grid-skeleton {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(56px, 1fr));
  @media screen and (max-width: 848px) {
    grid-gap: 72px;
  }
  @media screen and (max-width: 500px) {
    grid-gap: 48px;
  }
  @media screen and (max-width: 416px) {
    grid-gap: 30px;
  }
  @media screen and (max-width: 400px) {
    grid-gap: 24px;
  }
}
</style>
