export const TASK_WORKFLOW_ACTIONS = {
  AUTHENTICATION_FALLBACK: 'authentication-fallback',
  CLOSE: 'close',
  CONTINUE: 'continue',
  EXTERNAL_URL: 'external-url',
  MFA_RETRY: 'mfa-retry',
  RETRY: 'retry',
  ROUTE: 'route',
  SEND_EVENT_AND_RETRY: 'send-event-and-retry',
  START_TASK_CREDENTIALS_BYPASS: 'start-task-credentials-bypass',
  UPDATE_VIEW: 'update-view',
  WAIT: 'wait'
}
