import anime from 'animejs'

export const MODAL_ANIMATION_DURATION = 300
const SCALED_FOREGROUND_LOGO = 1.4
const CUBIC_BEZIER_LEFT = 'cubicBezier(0.12, 0, 0.39, 0)'

const FADE_OUT_BACKGROUND_LOGO = {
  targets: ['.branded-logo.background'],
  opacity: [1, 0],
  duration: 250
}

const TRANSITION_BACKGROUND_LOGO_LEFT = {
  targets: ['.branded-logo.background'],
  translateX: [
    { value: 0, duration: 0 },
    { value: -30, duration: 250 }
  ],
  opacity: [
    { value: 1, duration: 0 },
    { value: 0, duration: 250 }
  ],
  easing: CUBIC_BEZIER_LEFT
}

const SCALE_DOWN_FOREGROUND_LOGO_PRIMARY = {
  targets: '.branded-logo.foreground',
  scale: [1, 0],
  duration: 500,
  easing: 'easeInQuint'
}

const SCALE_UP_FOREGROUND_LOGO_PRIMARY = {
  targets: '.branded-logo.foreground',
  scale: [0, 1],
  duration: 350,
  easing: 'easeOutQuint'
}

const SCALE_UP_FOREGROUND_LOGO_NOT_PRIMARY = {
  targets: '.branded-logo.foreground',
  scale: [1, SCALED_FOREGROUND_LOGO],
  duration: 350,
  easing: 'easeOutQuint'
}

const SQUARE_FOREGROUND_LOGO_NOT_PRIMARY = {
  targets: '.branded-logo.foreground',
  borderRadius: ['100px', '11.25px'],
  duration: 250,
  easing: 'easeOutQuint'
}

const REMOVE_BORDER_FOREGROUND_LOGO_NOT_PRIMARY = {
  targets: '.branded-logo.foreground',
  borderWidth: ['2.5px', '0px'],
  duration: 100,
  easing: 'easeOutQuint'
}

const BRING_PROGRESS_FORWARD = {
  targets: '.progress-wrap',
  zIndex: [-1, 2],
  duration: 1
}

const TRANSITION_FOREGROUND_LOGO_LEFT = {
  targets: ['.foreground-container:not(.is-primary)'],
  translateX: [
    { value: 0, duration: 0 },
    { value: -18, duration: 250 }
  ],
  translateY: [
    { value: 0, duration: 0 },
    { value: -12, duration: 250 }
  ],
  easing: CUBIC_BEZIER_LEFT
}

const FADE_IN_PROGRESS = {
  targets: '.progress-wrap',
  opacity: [0, 1],
  duration: 200
}

const SCALE_UP_PROGRESS_LOGO = {
  targets: '.progress-logo',
  scale: [0, 1],
  duration: 500,
  easing: 'easeOutQuint'
}

const FADE_IN_PROGRESS_MESSAGING = {
  targets: '.progress-messaging',
  translateY: [-15, 0],
  opacity: [0, 1],
  easing: 'easeOutQuint',
  duration: 750
}

const FADE_IN_PROGRESS_INDICATOR = {
  targets: '.progress-indicator',
  opacity: [0, 1],
  duration: 250
}

const SCALE_UP_NOTIFICATION_LOGO = {
  targets: '.notification-logo',
  scale: [0, 1],
  duration: 350,
  easing: 'easeOutQuint'
}

const SCALE_DOWN_NOTIFICATION_LOGO = {
  targets: '.notification-logo',
  scale: [1, 0],
  duration: 350,
  easing: 'easeInQuint'
}

const FADE_OUT_TASK_WORKFLOW_VIEW = {
  targets: '.navigation-view',
  opacity: [1, 0],
  duration: 250
}

const FADE_IN_TASK_WORKFLOW_VIEW = {
  targets: '.navigation-view',
  opacity: 1,
  duration: 250
}

const PUSH_BACK_APP_CONTENT = {
  targets: '.app-content',
  opacity: [1, 0.6],
  scale: [1, 0.98],
  duration: 250,
  easing: 'easeOutQuad'
}

const BRING_FORWARD_APP_CONTENT = {
  targets: '.app-content',
  opacity: [0.6, 1],
  scale: [0.98, 1],
  duration: 250,
  easing: 'easeInQuad'
}

const FADE_OUT_APP_CONTENT = ({ dark }) => ({
  targets: '.app-content-overlay',
  opacity: [0, dark ? 0.7 : 0.25],
  duration: 150,
  easing: 'linear'
})

const FADE_IN_APP_CONTENT = ({ dark }) => ({
  targets: '.app-content-overlay',
  opacity: [dark ? 0.7 : 0.25, 0],
  duration: 150,
  easing: 'linear'
})

const SLIDE_UP_MODAL = {
  targets: '.modal-wrap',
  opacity: [1, 1],
  translateY: ['100%', 0],
  duration: MODAL_ANIMATION_DURATION,
  easing: 'easeOutQuart'
}

const SLIDE_DOWN_MODAL = {
  targets: '.modal-wrap',
  translateY: [0, '100%'],
  duration: MODAL_ANIMATION_DURATION,
  easing: 'easeInSine'
}

const HIDE_PROGRESS_DURATION = {
  targets: '.duration-message',
  opacity: [1, 0],
  duration: 1,
  easing: 'linear'
}

const SCALE_DOWN_AGREE_CIRCLE = {
  targets: '.circle-check .circle',
  scale: [1, 0],
  duration: 250
}

const SCALE_UP_AGREE_CIRCLE = {
  targets: '.circle-check .circle',
  scale: [0, 1],
  duration: 250
}

const SCALE_UP_AGREE_CHECK = {
  targets: '.circle-check .check',
  scale: [0, 1],
  duration: 250
}

const SCALE_DOWN_AGREE_CHECK = {
  targets: '.circle-check .check',
  scale: [1, 0],
  duration: 250
}

const FADE_IN_COUNTDOWN_TIMER = {
  targets: ['.progress-countdown-wrap'],
  opacity: [0, 1],
  duration: 500,
  delay: 250
}

const FADE_OUT_COUNTDOWN_TIMER = {
  targets: ['.progress-countdown-wrap'],
  opacity: [1, 0],
  duration: 500
}

const HIDE_COUNTDOWN_TIMER = {
  targets: ['.progress-countdown-wrap'],
  duration: 1,
  complete: function () {
    const countdownWrap = document.querySelector('.progress-countdown-wrap')

    if (countdownWrap) {
      countdownWrap.style.display = 'none'
    }
  }
}

function _drawHookFill({ store }) {
  return {
    targets: '.progress-step.in-progress .hook.fill',
    strokeDashoffset: [anime.setDashoffset, 0],
    opacity: [{ value: 1, duration: 1, easing: 'linear' }],
    easing: 'easeOutCirc',
    update: function (anim) {
      store.dispatch('progress/updateProgressHookPulse', `${anim.progress}%`)
    }
  }
}

const DRAW_HOOK_PULSE = {
  targets: '.progress-step.in-progress .hook.pulse',
  strokeDashoffset: [anime.setDashoffset, 0],
  duration: 1500,
  delay: 1500,
  easing: 'easeInQuad'
}

const FADE_OUT_HOOK_PULSE = {
  targets: '.progress-step.in-progress .hook.pulse',
  opacity: [0.4, 0],
  easing: 'linear',
  duration: 500
}

const FADE_OUT_MANUAL_DEPOSIT_LINKS = {
  targets: ['.md-privacy-link'],
  opacity: [1, 0],
  duration: 250
}

const SLIDE_UP_POWERED_BY_ATOMIC_TEXT = {
  targets: '.powered-by-atomic-text',
  translateY: [0, -12],
  duration: 200
}

const SLIDE_DOWN_POWERED_BY_ATOMIC_TEXT = {
  targets: '.powered-by-atomic-text',
  translateY: [-12, 0],
  duration: 150
}

const FADE_IN_PBA_PRIVACY_POLICY_DISCLAIMER = {
  targets: '.pba .terms-conditions-actions',
  opacity: [0, 1],
  duration: 200
}

const FADE_OUT_PBA_PRIVACY_POLICY_DISCLAIMER = {
  targets: '.pba .terms-conditions-actions',
  opacity: [1, 0],
  duration: 150
}

const BRING_PBA_PRIVACY_POLICY_DISCLAIMER_FORWARD = {
  targets: '.pba .terms-conditions-actions',
  zIndex: [-1, 0],
  duration: 1
}

const SEND_PBA_PRIVACY_POLICY_DISCLAIMER_BACKWARD = {
  targets: '.pba .terms-conditions-actions',
  zIndex: [0, -1],
  duration: 1
}

let PROGRESS_ANIMATION = undefined

export function startTaskAnimation({ store }) {
  const baseAnimation = anime
    .timeline({ easing: 'linear' })
    .add(TRANSITION_BACKGROUND_LOGO_LEFT)
    .add(TRANSITION_FOREGROUND_LOGO_LEFT, '-=250')
  _shouldScaleLogo({ baseAnimation, store })
}

export function resumeTaskAnimation() {
  const baseAnimation = anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_BACKGROUND_LOGO)
    .add(SCALE_DOWN_FOREGROUND_LOGO_PRIMARY)
    .add(HIDE_PROGRESS_DURATION)
  _addShowProgressAnimation(baseAnimation)
}

export async function resumeTaskAnimationFromFinished() {
  const baseAnimation = anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_TASK_WORKFLOW_VIEW)
    .add(SCALE_DOWN_NOTIFICATION_LOGO)

  return _addShowProgressAnimation(baseAnimation).finished
}

function _addShowProgressAnimation(baseAnimation) {
  return baseAnimation.add(FADE_IN_PROGRESS_INDICATOR)
}

function _shouldScaleLogo({ baseAnimation, store }) {
  if (store.getters['taskWorkflow/hasBackgroundLogo']) {
    return baseAnimation
      .add(SCALE_UP_FOREGROUND_LOGO_NOT_PRIMARY, '-=250')
      .add(REMOVE_BORDER_FOREGROUND_LOGO_NOT_PRIMARY, '-=250')
      .add(SQUARE_FOREGROUND_LOGO_NOT_PRIMARY, '-=250')
  } else {
    return baseAnimation
  }
}

export function finishTaskWorkflowAnimation() {
  return anime.timeline({ easing: 'linear' }).add(SCALE_UP_NOTIFICATION_LOGO)
    .finished
}

export function showProgressAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(BRING_PROGRESS_FORWARD)
    .add(FADE_IN_PROGRESS)
    .add(SCALE_UP_PROGRESS_LOGO)
    .add(FADE_IN_PROGRESS_MESSAGING)
    .add(FADE_IN_PROGRESS_INDICATOR, '-=750')
}

export function fadeOutTaskWorkflowView() {
  return anime.timeline({ easing: 'linear' }).add(FADE_OUT_TASK_WORKFLOW_VIEW)
    .finished
}

export function startChangeAuthenticationView() {
  return anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_TASK_WORKFLOW_VIEW)
    .add(SCALE_DOWN_NOTIFICATION_LOGO).finished
}

export function startUpdateAuthenticationView() {
  return anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_TASK_WORKFLOW_VIEW)
    .add(SCALE_DOWN_NOTIFICATION_LOGO).finished
}

export function finishUpdateAuthenticationView() {
  anime
    .timeline({ easing: 'linear' })
    .add(SCALE_UP_NOTIFICATION_LOGO)
    .add(FADE_IN_TASK_WORKFLOW_VIEW)
}

export async function startChangeAuthenticatorAnimation() {
  return anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_TASK_WORKFLOW_VIEW)
    .add(SCALE_DOWN_FOREGROUND_LOGO_PRIMARY).finished
}

export function finishChangeAuthenticatorAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(SCALE_UP_FOREGROUND_LOGO_PRIMARY)
    .add(FADE_IN_TASK_WORKFLOW_VIEW)
}

export async function openModalAnimation({ overlay, dark }) {
  return anime
    .timeline({ easing: 'easeOutQuad' })
    .add(overlay ? FADE_OUT_APP_CONTENT({ dark }) : PUSH_BACK_APP_CONTENT)
    .add(SLIDE_UP_MODAL, '-=150').finished
}

export async function closeModalAnimation({ overlay, dark }) {
  return anime
    .timeline({ easing: 'easeInQuad' })
    .add(SLIDE_DOWN_MODAL)
    .add(
      overlay ? FADE_IN_APP_CONTENT({ dark }) : BRING_FORWARD_APP_CONTENT,
      '-=250'
    ).finished
}

export function startProgressAnimation({ duration, store } = {}) {
  PROGRESS_ANIMATION = anime
    .timeline({
      easing: 'easeOutCirc',
      autoplay: false
    })
    .add({ ..._drawHookFill({ store }), duration })
  PROGRESS_ANIMATION.play()
}

export function pauseProgressAnimation() {
  PROGRESS_ANIMATION.pause()
}

export function resumeProgressAnimation({ dashoffset, fill, tracks, store }) {
  const opacityBasedOnThemeMode = store.getters['theme/useDarkMode'] ? 0.2 : 0.1
  anime({
    targets: tracks,
    strokeDashoffset: [anime.setDashoffset, 0],
    duration: 1,
    opacity: [
      { value: opacityBasedOnThemeMode, duration: 1, easing: 'linear' }
    ],
    easing: 'linear'
  })

  anime({
    targets: fill,
    strokeDashoffset: [dashoffset, 0],
    duration: store.state.task.taskCountdown.timer
      ? store.state.task.taskCountdown.timer * 1000
      : 30000,
    opacity: [{ value: 1, duration: 1, easing: 'linear' }],
    easing: 'linear'
  })
}

export async function completeProgressAnimation() {
  if (!PROGRESS_ANIMATION) {
    return
  }

  PROGRESS_ANIMATION.pause()
  const target = document.querySelector('.progress-step.in-progress .hook.fill')
  if (!target) return
  await anime({
    targets: target,
    strokeDashoffset: [target.style.strokeDashoffset, 0],
    duration: 2000,
    easing: 'easeInOutCubic'
  }).finished
}

export async function sleep(time) {
  await new Promise((resolve) => setTimeout(resolve, time))
}

export function shakeElement(element) {
  const xMax = 5
  return anime({
    targets: element,
    translateX: [
      {
        value: xMax * -1
      },
      {
        value: xMax
      },
      {
        value: xMax / -2
      },
      {
        value: xMax / 2
      },
      {
        value: 0
      }
    ],
    easing: 'easeInOutSine',
    duration: 500
  })
}

export function startCircleCheckAnimation(id) {
  const scaleDownInfo = { ...SCALE_DOWN_AGREE_CIRCLE }
  const scaleUpInfo = { ...SCALE_UP_AGREE_CHECK }
  if (id) {
    scaleDownInfo.targets = `#${id} ${scaleDownInfo.targets}`
    scaleUpInfo.targets = `#${id} ${scaleUpInfo.targets}`
  }
  anime.timeline({ easing: 'linear' }).add(scaleDownInfo).add(scaleUpInfo)
}

export function hideCircleCheckAnimation(id) {
  const scaleDownInfo = { ...SCALE_DOWN_AGREE_CHECK }
  const scaleUpInfo = { ...SCALE_UP_AGREE_CIRCLE }
  if (id) {
    scaleDownInfo.targets = `#${id} ${scaleDownInfo.targets}`
    scaleUpInfo.targets = `#${id} ${scaleUpInfo.targets}`
  }
  anime.timeline({ easing: 'linear' }).add(scaleDownInfo).add(scaleUpInfo)
}

export function fadeInTaskCountdown() {
  anime.timeline({ easing: 'linear' }).add(FADE_IN_COUNTDOWN_TIMER)
}

export function fadeOutTaskCountdown() {
  anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_COUNTDOWN_TIMER)
    .add(HIDE_COUNTDOWN_TIMER)
}

export function finishProgressToFinishAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(SCALE_UP_NOTIFICATION_LOGO)
    .add(FADE_IN_TASK_WORKFLOW_VIEW)
}

export async function startTaskViewChangeFromFinish() {
  return anime.timeline({ easing: 'linear' }).add(SCALE_DOWN_NOTIFICATION_LOGO)
    .finished
}

export function finishTaskViewChangeFromFinish() {
  anime.timeline({ easing: 'linear' }).add(FADE_IN_TASK_WORKFLOW_VIEW)
}

export function presentOptionToExit({ delay = 8000 } = {}) {
  return anime({
    targets: '.option-to-exit-wrap',
    opacity: [
      { value: 0, duration: 0 },
      { value: 1, duration: 500 }
    ],
    zIndex: [
      { value: -1, duration: 0 },
      { value: 0, duration: 1 }
    ],
    easing: 'linear',
    delay,
    complete: function () {
      const exitWrap = document.querySelector('.option-to-exit-wrap')

      if (exitWrap) {
        exitWrap.tabIndex = '-1'
        exitWrap.focus()
      }
    }
  })
}

export function staggerListAnimation({ target, state }) {
  const delay = target?.dataset?.index * 100
  setTimeout(() => {
    anime({
      targets: target,
      opacity: [0, 1],
      translateX: [20, 0],
      easing: 'easeOutExpo',
      duration: 1000,
      complete: state
    })
  }, delay)
}

export function drawProgressPath() {
  return anime({
    targets: '.progress-step.not-started .hook.track',
    strokeDashoffset: [anime.setDashoffset, 0],
    duration: 500,
    easing: 'linear',
    delay: anime.stagger(500, { start: 150 })
  }).finished
}

export function drawProgressPulse({ delay }) {
  setTimeout(() => {
    anime
      .timeline({ easing: 'linear', loop: true })
      .add(DRAW_HOOK_PULSE)
      .add(FADE_OUT_HOOK_PULSE, '-=400')
  }, delay)
}

export function routingDotAnimation() {
  _dotAnimation('.dot-wrap.routing .dot')
}

export function accountDotAnimation() {
  _dotAnimation('.dot-wrap.account .dot')
}

function _dotAnimation(target) {
  return anime({
    targets: target,
    keyframes: [
      { translateY: 0 },
      { translateY: 1 },
      { translateY: -2 },
      { translateY: 0 }
    ],
    duration: 800,
    loop: true,
    delay: anime.stagger(100)
  })
}

export function accountRevealAnimation() {
  return anime.timeline({ easing: 'linear' }).add(FADE_OUT_MANUAL_DEPOSIT_LINKS)
    .finished
}

export function fadeInPoweredByAtomic() {
  return anime({
    targets: '.pba',
    opacity: [0, 1],
    easing: 'linear',
    duration: 250,
    delay: 250
  })
}

export function revealPbaPrivacyDisclaimerAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(SLIDE_UP_POWERED_BY_ATOMIC_TEXT)
    .add(BRING_PBA_PRIVACY_POLICY_DISCLAIMER_FORWARD)
    .add(FADE_IN_PBA_PRIVACY_POLICY_DISCLAIMER)
}

export function hidePbaPrivacyDisclaimerAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(FADE_OUT_PBA_PRIVACY_POLICY_DISCLAIMER)
    .add(SEND_PBA_PRIVACY_POLICY_DISCLAIMER_BACKWARD)
    .add(SLIDE_DOWN_POWERED_BY_ATOMIC_TEXT)
}

export function scrollToPrivacyPolicy() {
  const scrollContainer = document.querySelector(
    '.legal-wrap .scrollable-container'
  )
  const privacyPolicyTop = document.querySelector('#product-pp').offsetTop
  setTimeout(() => {
    scrollContainer.scrollTop = privacyPolicyTop
  }, 550)
}

export function scaleDownForegroundLogoPrimary() {
  return anime
    .timeline({ easing: 'linear' })
    .add(SCALE_DOWN_FOREGROUND_LOGO_PRIMARY).finished
}

export function scaleUpNotificationLogo() {
  return anime.timeline({ easing: 'linear' }).add(SCALE_UP_NOTIFICATION_LOGO)
    .finished
}

export function scaleDownNotificationLogo() {
  return anime.timeline({ easing: 'linear' }).add(SCALE_DOWN_NOTIFICATION_LOGO)
    .finished
}
