<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="mt-3"
      shape="box"
      shapeWidth="152px"
      shapeHeight="32px"
    />
    <SkeletonBlock
      class="mt-1"
      shape="box"
      shapeWidth="300px"
      shapeHeight="32px"
    />
    <div class="mt-4" v-for="index in 2" :key="index">
      <div class="d-flex align-items-center">
        <SkeletonBlock shape="box" shapeWidth="100%" shapeHeight="200px" />
      </div>
    </div>
    <SkeletonBlock
      class="mt-auto"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'TaskHistorySkeleton',
  components: {
    SkeletonTemplate,
    SkeletonBlock
  }
}
</script>

<style lang="scss" scoped>
.logo-overlap {
  margin-left: -8px;
  box-shadow: -2px 0px 0px 2px var(--white);

  &.dark {
    box-shadow: -2px 0px 0px 2px var(--gray-1000);
  }
}
</style>
