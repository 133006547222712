export const MODAL_VIEW = {
  ADP_EDDE_SDK: 'adp-edde-sdk',
  ANDROID_INSTANT_APPS: 'android-instant-apps',
  APP_CLIP_QR: 'app-clip-qr',
  WALMART_AUTHENTICATION_HELP: 'authentication-help',
  EXIT: 'exit',
  ERROR: 'error',
  DISTRIBUTION_OPTIONS: 'distribution-options',
  DISTRIBUTION_EDIT_AMOUNT: 'distribution-edit-amount',
  FIND_CREDENTIALS_HELP: 'find-credentials-help',
  FIND_EMPLOYER_HELP: 'find-employer-help',
  FRANCHISE_HELP: 'franchise-help',
  PROTECT_YOU: 'protect-you',
  LEGAL: 'legal',
  OFFLINE: 'offline',
  SURVEY: 'survey',
  UPLINK_DEMO: 'uplink-demo',
  PAYLINK_DETAIL: 'paylink-detail',
  PAY_LINK_SELECT_PAYMENT_METHOD: 'pay-link-select-payment-method'
}
