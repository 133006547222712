import { emitSdkEvent } from '@/util/sdk'
import {
  SDK_EVENT_TYPES,
  USER_DEVICE_AUTOMATION_EVENTS,
  USER_DEVICE_INTERACTION_EVENTS
} from '@/util/constants'
import {
  attachDOMPullListenerScript,
  getAuthIsCompleteEvaluatorScript,
  sanitizeFullDOMScript
} from './sdk-scripts'
import { beginTriggerPolling } from './trigger-polling'

export async function beginAuthenticationInSdk({ store }) {
  const config =
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']

  config.authIsCompleteEvaluatorScript = getAuthIsCompleteEvaluatorScript({
    stringifiedFunctionToEvaluate: config.authIsCompleteEvaluator,
    functionParameters: { helpers: config.helpers }
  })

  emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, {
    name: USER_DEVICE_INTERACTION_EVENTS.INITIALIZED,
    value: {}
  })

  emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
    type: USER_DEVICE_AUTOMATION_EVENTS.AUTH_POLL,
    sdkScripts: { attachDOMPullListenerScript, sanitizeFullDOMScript },
    helpers: '',
    ...config
  })

  beginTriggerPolling({ store })
}
