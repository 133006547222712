export const EXIT_REASONS = {
  AUTH_SUCCESS_EXIT: 'auth-success-exit',
  BAD_REQUEST: 'bad-request',
  EXIT_MANUAL_INSTRUCTIONS: 'exit-manual-instructions',
  EXPIRED_TOKEN: 'expired-token',
  FAILED_CONNECTION: 'failed-connection',
  FAILED_VPN_CONNECTIVITY: 'failed-vpn-connectivity',
  FINISH_MANUAL_INSTRUCTIONS: 'finish-manual-instructions',
  GENERATE_DEPOSIT_PDF_SUCCESS: 'generate-deposit-pdf-success',
  INVALID_CONFIGURATION: 'invalid-configuration',
  MANUAL_COMPLETION: 'manual-completion',
  MANUAL_FALLBACK: 'manual-fallback',
  MANUAL_FALLBACK_COPY_ACCOUNT_ROUTING: 'manual-fallback-copy-account-routing',
  TASK_PENDING: 'task-pending',
  UNAUTHORIZED: 'unauthorized',
  UNKNOWN: 'unknown',
  WRONG_PAYROLL_PROVIDER: 'wrong-payroll-provider',
  ZERO_SEARCH_RESULTS: 'zero-search-results'
}
