const state = {
  data: undefined
}

const actions = {
  updateExitData({ commit }, data) {
    commit('setExitData', data)
  },
  clearExitData({ commit }) {
    commit('setExitData', undefined)
  }
}

const mutations = {
  setExitData: (state, data) => {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
