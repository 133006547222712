export const socialSecuritySsi = {
  title: 'Configura su depósito de la Seguridad Social',
  step1: {
    title:
      'Lo sentimos, la configuración en línea no está disponible. Actualmente, solo puedes configurar o actualizar su depósito directo llamando a la Administración de la Seguridad Social al:',
    phone: '1-800-772-1213',
    phoneTTY: '(TTY 1-800-325-0778)',
    hours: 'De lunes a viernes, de 8:00 a.m. a 7:00 p.m. hora local.'
  },
  step2: {
    title: 'Proporciona la información de su cuenta bancaria:'
  },
  showAccountNumbers: 'Mostrar números de cuenta'
}
