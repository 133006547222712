import * as page from '@/util/native/page'

/** @param { import('@atomicfi/rpc').RPC } rpc */
export function register(rpc) {
  rpc.register('transact.uplink.page.evaluate', evaluate)
  rpc.register('transact.uplink.page.cookies', cookies)
  rpc.register('transact.uplink.page.show', show)
  rpc.register('transact.uplink.page.hide', hide)
  rpc.register('transact.uplink.page.url', url)
  rpc.register('transact.uplink.page.request', request)
  rpc.register('transact.uplink.page.wait', wait)
  rpc.register('transact.uplink.page.navigate', navigate)
  rpc.register('transact.uplink.page.getRequestHeaders', getRequestHeaders)
}

export async function evaluate(options) {
  return page.evaluate(options)
}

export async function cookies(options) {
  return page.cookies(options)
}

export async function show(options) {
  return page.show(options)
}

export async function hide(options) {
  return page.hide(options)
}

export async function url(options) {
  return page.url(options)
}

export async function request(options) {
  return page.request(options)
}

export async function wait(options) {
  return page.wait(options)
}

export async function navigate(options) {
  return page.navigate(options)
}

export async function getRequestHeaders(options) {
  return page.getRequestHeaders(options)
}
