export const AUTHENTICATION_EVENTS = {
  AUTHENTICATION_FALLBACK: 'AUTHENTICATION_FALLBACK',
  BACK: 'BACK',
  COMPLETED_LOGIN: 'COMPLETED_LOGIN',
  CONTINUE: 'CONTINUE',
  GET_HELP: 'GET_HELP',
  GO_TO_LOGIN: 'GO_TO_LOGIN',
  INITIALIZE: 'INITIALIZE',
  LAUNCH_UPLINK: 'LAUNCH_UPLINK',
  REINITIALIZE_MACHINE: 'REINITIALIZE_MACHINE',
  RESET_MACHINE: 'RESET_MACHINE',
  RESET_STEP: 'RESET_STEP',
  SEARCHED_CONNECTOR: 'SEARCHED_CONNECTOR',
  SEARCH_NOT_FOUND: 'SEARCH_NOT_FOUND',
  SELECTED_CONNECTOR: 'SELECTED_CONNECTOR',
  SELECTED_CREDENTIALS_KNOWN: 'SELECTED_CREDENTIALS_KNOWN',
  SELECTED_DONT_RECEIVE_SSA_PAYMENTS: 'SELECTED_DONT_RECEIVE_SSA_PAYMENTS',
  SELECTED_FORGOT_LOGIN: 'SELECTED_FORGOT_LOGIN',
  SELECTED_PAYROLL_PROVIDER: 'SELECTED_PAYROLL_PROVIDER',
  SELECTED_RECEIVE_SSA_PAYMENTS: 'SELECTED_RECEIVE_SSA_PAYMENTS',
  SELECTED_RECEIVE_SSA_SSI: 'SELECTED_RECEIVE_SSA_SSI',
  SELECTED_SEARCH_PAYROLL_PROVIDERS: 'SELECTED_SEARCH_PAYROLL_PROVIDERS',
  SELECTED_SSA_EMPLOYEE: 'SELECTED_SSA_EMPLOYEE',
  SELECTED_SSA_LOGIN: 'SELECTED_SSA_LOGIN',
  SMART_AUTH_GET_HELP: 'SMART_AUTH_GET_HELP',
  SMART_AUTH_FINISHED: 'SMART_AUTH_FINISHED',
  STEP: 'STEP',
  SWITCH_METHOD: 'SWITCH_METHOD'
}
