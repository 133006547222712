<template>
  <div class="payment-method-option-content">
    <IconCard :brand="viewModel.brand" class="icon-card" />
    <div class="text">
      <div class="title">{{ viewModel.title }}</div>
      <div class="subtitle">
        <div class="subtitle-text" v-if="viewModel.subtitle">
          {{ viewModel.subtitle }}
        </div>
        <div>{{ viewModel.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { maskNumber } from '@/util/general'
import { isBank } from '@/util/pay-link'
import IconCard from '@/components/Switch/IconCard/IconCard.vue'

export default {
  name: 'PaymentMethodOptionContent',
  components: {
    IconCard
  },
  props: {
    paymentMethod: Object
  },
  computed: {
    viewModel() {
      return _getPaymentMethodViewModel(this.paymentMethod)
    }
  }
}

function _getPaymentMethodViewModel(paymentMethod) {
  return isBank(paymentMethod)
    ? _formatBankAccountAsPaymentMethodOption(paymentMethod)
    : _formatCardAsPaymentMethodOption(paymentMethod)
}

function _formatBankAccountAsPaymentMethodOption({
  _id,
  title,
  accountNumber,
  accountNumberLastFour
}) {
  return {
    _id,
    title,
    number: maskNumber(accountNumber || accountNumberLastFour)
  }
}

function _formatCardAsPaymentMethodOption({
  _id,
  title,
  subtitle,
  brand,
  lastFour
}) {
  return {
    _id,
    title,
    subtitle,
    number: maskNumber(lastFour),
    brand
  }
}
</script>

<style lang="scss" scoped>
.payment-method-option-content {
  display: flex;
  gap: 12px;
  text-align: left;
  overflow: hidden;
}

.icon-card {
  flex-shrink: 0;
}

.text {
  min-width: 0;
}

.title {
  font-size: 1.4rem;
  line-height: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subtitle {
  font-size: 1.2rem;
  line-height: 1.3;
  color: var(--gray-600);
  display: flex;
  gap: 4px;

  .dark & {
    color: var(--gray-500);
  }
}

.subtitle-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
