import { store } from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import { ROUTES, PAGE_TRANSITIONS } from '@/util/constants'
import Initialize from '@/components/Shared/Initialize.vue'

// Lazy loaded routes
const Welcome = () =>
  import('@/components/Prototypes/Welcome/WelcomeRouter.vue')
const SelectAccount = () => import('@/components/Deposit/SelectAccount.vue')
const AddCard = () => import('@/components/Switch/AddCard.vue')
const DepositPreselectedCompanies = () =>
  import('@/components/Preselected/DepositPreselectedCompanies.vue')
const RouterWrap = () => import('@/components/Shared/RouterWrap.vue')
const SearchPayroll = () => import('@/components/Search/SearchPayroll.vue')
const SearchFranchise = () => import('@/components/Search/SearchFranchise.vue')
const SearchCompany = () => import('@/components/Search/SearchCompany.vue')
const SearchPayLink = () => import('@/components/Search/SearchPayLink.vue')
const Task = () => import('@/components/Flows/Task/TaskWrap.vue')
const TaskHistory = () => import('@/components/TaskHistory/TaskHistory.vue')
const Distribution = () =>
  import('@/components/Distribution/DistributionWrap.vue')
const PayLinkHome = () => import('@/components/PayLink/PayLinkHome.vue')
const PayLinkWelcome = () => import('@/components/PayLink/PayLinkWelcome.vue')
const GenerateDepositPDFDeeplink = () =>
  import('@/components/Deposit/GenerateDepositPDFDeeplink.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: ROUTES.INITIALIZE,
      path: '/initialize/:config',
      component: Initialize
    },
    {
      name: ROUTES.WELCOME,
      path: '/welcome',
      component: Welcome,
      meta: {
        entryRoute: true
      }
    },
    {
      name: ROUTES.ACCOUNT,
      path: '/account',
      component: SelectAccount
    },
    {
      name: ROUTES.ADD_CARD,
      path: '/add-card',
      component: AddCard
    },
    {
      name: ROUTES.GENERATE_DEPOSIT_PDF,
      path: '/deposit-pdf',
      component: GenerateDepositPDFDeeplink
    },
    {
      name: ROUTES.DEPOSIT_PRESELECTED_COMPANIES,
      path: '/deposit-preselected-companies',
      component: DepositPreselectedCompanies
    },
    {
      path: '/search',
      component: RouterWrap,
      children: [
        {
          name: ROUTES.SEARCH_COMPANY,
          path: 'company',
          component: SearchCompany,
          meta: {
            entryRoute: true
          }
        },
        {
          name: ROUTES.SEARCH_PAYROLL,
          path: 'payroll',
          component: SearchPayroll
        },
        {
          name: ROUTES.SEARCH_FRANCHISE,
          path: 'franchise',
          component: SearchFranchise
        },
        {
          name: ROUTES.PAY_LINK_SEARCH,
          path: 'providers',
          component: SearchPayLink
        }
      ]
    },
    {
      name: ROUTES.TASK,
      path: '/task',
      component: Task
    },
    {
      name: ROUTES.TASK_HISTORY,
      path: '/task-history',
      component: TaskHistory,
      meta: {
        entryRoute: true
      }
    },
    {
      name: ROUTES.DISTRIBUTION,
      path: '/distribution',
      component: Distribution
    },
    {
      name: ROUTES.PAY_LINK_WELCOME,
      path: '/pay-link',
      component: PayLinkWelcome
    },
    {
      name: ROUTES.PAY_LINK_HOME,
      path: '/pay-link/home',
      component: PayLinkHome
    }
  ]
})

// Detect native browser "Back" button press
router.options.history.listen((to, from, info) => {
  if (info.direction === 'back') {
    store.dispatch('main/updatePressedBack', true)
  }
})

router.beforeEach(() => {
  if (store.state.modal.isOpen && store.state.main.pressedBack) {
    store.dispatch('modal/closeModal')
    return false
  }

  // Uncovers the action bar if moving to a new screen if it was covered
  store.dispatch('main/uncoverActionBar')

  if (store.state.main.pressedBack) {
    store.dispatch(
      'animation/updateTransitionName',
      PAGE_TRANSITIONS.SLIDE_RIGHT
    )
  } else {
    store.dispatch(
      'animation/updateTransitionName',
      PAGE_TRANSITIONS.SLIDE_LEFT
    )
  }
})

router.afterEach((to, from) => {
  store.dispatch('main/updatePressedBack', false)
  store.dispatch('main/updatePreviousRouteName', from.name)
})

export default router
