<template>
  <li class="step">
    <ManualDepositInstructionsSymbol :symbol="symbol" />
    <div class="step-content">
      <p class="paragraph title">
        {{ title }}
        <slot></slot>
      </p>
      <p v-if="description" class="paragraph">{{ description }}</p>
    </div>
  </li>
</template>

<script>
import ManualDepositInstructionsSymbol from '@/components/Flows/Task/Process/ManualDeposit/ManualDepositInstructionsSymbol.vue'

export default {
  name: 'HelpStep',
  components: { ManualDepositInstructionsSymbol },
  props: {
    symbol: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  margin-bottom: calc(var(--marginBase) * 2);
  align-items: flex-start;
  .step-content {
    margin-left: calc(var(--marginBase) * 2);
    margin-top: calc(var(--marginBase) * 0.5);
    .title {
      color: var(--gray-800);
      margin: 0;

      .dark & {
        color: var(--gray-300);
      }
    }
  }
}
</style>
