<template>
  <FocusHeading
    v-if="showHeading"
    :key="reRenderFocusHeadingToRestoreFocus"
    data-test-id="page-title"
    :level="2"
    class="title"
    :class="{
      'has-back-button': showBackButton,
      center: headingData?.center
    }"
  >
    <Logo
      v-if="headingData?.logoUrl"
      style="margin-right: 8px"
      :imgSrc="headingData?.logoUrl"
      :backgroundColor="headingData?.logoBackgroundColor"
      :size="32"
      :xSmall="true"
    />
    {{ headingData?.text }}
  </FocusHeading>
</template>

<script>
import { mapGetters } from 'vuex'
import FocusHeading from '@/components/Shared/FocusHeading.vue'
import Logo from '@/components/Shared/LogoWrap.vue'
import { headingDataBasedOnRoute } from '@/util/general'
import { useAuthenticationService } from '@/machines/authentication'

export default {
  name: 'MainActionBarText',
  components: { FocusHeading, Logo },
  computed: {
    ...mapGetters('main', ['showBackButton']),
    ...mapGetters('theme', ['showBackButtonText']),
    headingData() {
      return headingDataBasedOnRoute({
        routeName: this.currentRoute(),
        store: this.$store,
        authService: useAuthenticationService()
      })
    },
    reRenderFocusHeadingToRestoreFocus() {
      return this.headingData?.text
    },
    showHeading() {
      return (
        !(this.showBackButtonText && this.showBackButton) &&
        (this.headingData?.logoUrl || this.headingData?.text)
      )
    }
  },
  methods: {
    currentRoute() {
      return this.$router?.currentRoute.value.name
    }
  }
}
</script>
