const state = {
  isOnline: true
}

const mutations = {
  setIsOnline: (state, isOnline) => {
    state.isOnline = isOnline
  }
}

const actions = {
  updateIsOnline({ commit }, isOnline) {
    commit('setIsOnline', isOnline)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
