import * as native from '@/util/native'

/** @param { import('@atomicfi/rpc').RPC } rpc */
export function register(rpc) {
  rpc.register('transact.uplink.evaluate', evaluate)
  rpc.register('transact.uplink.features', features)
  rpc.register('transact.uplink.hide', hide)
  rpc.register('transact.uplink.request', request)
  rpc.register('transact.uplink.show', show)
  rpc.register('transact.uplink.version', version)
  rpc.register('transact.uplink.wait', wait)
  rpc.register('transact.uplink.navigate', navigate)
  rpc.register('transact.uplink.detokenizeCreditCard', detokenize)
  rpc.register('transact.uplink.clearCardToken', clearCardToken)
}

export async function evaluate(options) {
  return native.evaluate(options)
}

export async function features() {
  return native.features()
}

export async function hide() {
  return native.hide()
}

export async function request(options) {
  return native.request(options)
}

export async function show() {
  return native.show()
}

export async function version() {
  return native.version()
}

export async function wait(options) {
  return native.wait(options)
}

export async function navigate(options) {
  return native.navigate(options)
}

export async function detokenize(options) {
  return native.detokenizeCreditCard(options)
}

export async function clearCardToken(options) {
  return native.clearCardToken(options)
}
