/* eslint-disable no-undef */
import { encode } from 'js-base64'
import { store } from '@/store'
import { isLocalDev } from '@/util/environment'
import { SDK_EVENT_TYPES } from '@/util/constants'
import { publishUserSessionEvent } from '@/util/realtime-helpers/user'

export function broadcastEvent({ event, payload: rawPayload }) {
  const payload = {
    ...rawPayload,
    identifier: store.state.user?.userData?.identifier
  }

  // TrueAuth App Clip/Google Play Instant support
  if (
    store.getters['main/isTrueAuthSessionContext'] &&
    event === SDK_EVENT_TYPES.INTERACTION
  ) {
    publishUserSessionEvent({
      store,
      eventName: 'true-auth-app-interaction',
      eventData: payload
    })
  }
  // iOS Support
  else if (window?.webkit?.messageHandlers?.[event]?.postMessage) {
    window.webkit.messageHandlers[event].postMessage(payload)
  }
  // React Native Support
  else if (window?.ReactNativeWebView?.postMessage) {
    let data = encode(JSON.stringify({ event, payload }))
    window.ReactNativeWebView.postMessage(data)
  }
  // Android Support
  else if (window?.TransactEvents?.handleAction) {
    let data = encode(JSON.stringify({ event, payload: payload || {} }))
    TransactEvents.handleAction(data)
  }
  // Flutter Support
  else if (window?.FlutterEvents?.postMessage) {
    let data = encode(JSON.stringify({ event, payload: payload || {} }))
    FlutterEvents.postMessage(data)
  }
  // iFrame Support
  else if (window?.parent?.postMessage) {
    window.parent.postMessage({ event, payload: payload || {} }, '*')
  }

  if (isLocalDev()) {
    console.log('BROADCAST:', event, payload)
  }
}
