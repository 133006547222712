<template>
  <span class="text">
    {{ text }}
    <br v-if="helperText" />
    <span v-if="helperText" class="helper-text">
      {{ helperText }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'BaseButtonCenter',
  props: {
    text: [String, Object],
    helperText: String,
    fontSize: String
  },
  computed: {
    buttonFontSize() {
      return this.fontSize || '1.6rem'
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  transition: opacity 100ms linear;
  font-family: var(--buttonFont);
  font-size: v-bind(buttonFontSize);
  font-weight: 500;
  .helper-text {
    font-size: 1.2rem;
    color: var(--gray-600);
  }
}
</style>
