<template>
  <div class="icon-info-wrapper mx-auto mb-3">
    <IconExclamation :size="24" :stroke="iconInfoStroke" aria-hidden="true" />
  </div>
  <div class="text-center mb-3">
    <h2>
      {{ phrases.shared.finishVerifyingAccount }}
    </h2>
    <p>
      {{
        phrases.shared.finishVerifyingAccountSubtitle({
          companyName: this.selection.company.name
        })
      }}
    </p>
  </div>
  <div class="action-list">
    <BaseButton
      btn-style="solid"
      data-test-id="try-again"
      :text="phrases.shared.verifyAccount"
      customer-branded
      @handleClick="handleClickVerifyAccount"
    />
    <TextButton
      @handleClick="handleClickClose"
      :text="phrases.shared.close"
      data-test-id="close"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import IconExclamation from '@/components/Icons/Atomic/IconExclamation.vue'
import { mapGetters } from 'vuex'
import { getGlobalCSSValue } from '@/util/general'
import { PAGES } from '@/util/constants'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'

export default {
  name: 'SelectionDetailModalContentUserInputRequired',
  components: {
    BaseButton,
    IconExclamation,
    TextButton
  },
  emits: ['close', 'fulfill-user-input-requirement'],
  props: {
    selection: Object
  },
  methods: {
    handleClickVerifyAccount() {
      this.$analytics.track({
        event: `Clicked Verify Account From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection)
      })
      this.$emit('fulfill-user-input-requirement')
    },
    handleClickClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    iconInfoStroke() {
      return getGlobalCSSValue(this.useDarkMode ? '--info-300' : '--info')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-info-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--info-200);

  .dark & {
    background: var(--info-600);
  }
}
</style>
