<template>
  <SkeletonTemplate>
    <SkeletonBlock shape="box" shapeWidth="172px" shapeHeight="32px" />
    <SkeletonBlock
      class="mt-2 mb-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="40px"
    />
    <div v-for="index in 8" :key="index" class="mt-2 d-flex align-items-center">
      <SkeletonBlock
        class="mr-2"
        shape="box"
        shapeWidth="48px"
        shapeHeight="48px"
      />
      <SkeletonBlock shape="box" shapeWidth="200px" shapeHeight="48px" />
    </div>
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'SearchListSkeleton',
  components: {
    SkeletonBlock,
    SkeletonTemplate
  }
}
</script>
