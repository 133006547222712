export const capitalOneCustomError = {
  title: 'Verify your identity with PingID',
  step1: {
    title: 'Go to the MFA Registration Portal.',
    description: 'Use your work computer or mobile phone.'
  },
  step2: {
    title: 'Register an additional MFA device.',
    description:
      'Select Register devices that are not Passwordless and choose Mobile Phone App.'
  },
  step3: {
    title: 'Download the PingID app, complete the registration, and try again.',
    description:
      'Open the app on your mobile device and follow the setup instructions.'
  },
  orUpdateDirectly: 'Or, you can update your deposit directly from Workday.'
}
