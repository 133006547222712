export const PROGRESS_STEP_TYPES = {
  CONNECTION: 'connection',
  AUTHENTICATION: 'authentication',
  GATHER_ACCOUNTS: 'gather-accounts',
  UPDATE_DEPOSIT: 'update-deposit',
  SWITCH: 'switch',
  PRESENT: 'present',
  VERIFY: 'verify',
  AGGREGATE: 'aggregate',
  WITHHOLD: 'withhold',
  RECOVERY: 'recovery',
  TAX: 'tax'
}
