<template>
  <button class="icon-close content-only" @click="$emit('close')">
    <img src="/img/icon-close.svg" :alt="phrases.shared.close" />
  </button>
</template>

<script>
export default {
  name: 'CloseButton'
}
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: -8px;
  padding: 8px;
}
</style>
