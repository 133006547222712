export const capitalOneCustomError = {
  title: 'Verifique su identidad con PingID',
  step1: {
    title: 'Vaya al Portal de Registro MFA.',
    description: 'Use su computadora de trabajo o teléfono móvil.'
  },
  step2: {
    title: 'Registre un dispositivo MFA adicional.',
    description:
      'Seleccione Registrar dispositivos que no sean sin contraseña y elija Aplicación de teléfono móvil.'
  },
  step3: {
    title: 'Descargue la aplicación PingID y complete el registro.',
    description:
      'Abra la aplicación en su dispositivo móvil y siga las instrucciones de configuración.'
  },
  orUpdateDirectly:
    'O bien, puede actualizar su depósito directamente desde Workday'
}
