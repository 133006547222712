// @TODO: Remove this once we refactor realtime helpers and store injection
import { store as deprecratedStore } from '@/store'
import { PROGRESS_STEP_STATUS, PROGRESS_STEP_TYPES } from '@/util/constants'
import {
  completeProgressAnimation,
  startProgressAnimation,
  drawProgressPulse
} from '@/util/animation'

export async function startProgress({ store }) {
  const firstStepIdx = _getNextProgressStep({ store })
  _startProgressStep({ stepIdx: firstStepIdx, store })
}

async function _startProgressStep({ stepIdx, store }) {
  await store.dispatch('progress/updateCurrentProgressStepIdx', stepIdx)
  await store.dispatch('progress/updateCurrentProgressStep')
  await store.dispatch(
    'progress/updateCurrentProgressStepStatus',
    PROGRESS_STEP_STATUS.IN_PROGRESS
  )

  const currentStep = store.state.progress.currentProgressStep

  startProgressAnimation({
    duration: _setHookAnimationDuration({ currentStep, store }),
    store
  })

  if (currentStep.showPulse) {
    drawProgressPulse({ delay: 5000 })
  }

  // If the currentStep has a duration set, then we don't need to wait for
  // any data from the backend. We can automatically complete the step. Otherwise
  // we need to wait for a response from the backend and then call the
  // completeProgressStep() from another file.
  if (currentStep.duration) {
    _createHookAnimationCompletionTimer({
      duration: currentStep.duration,
      store
    })
  }
}

export async function completeProgressStep() {
  if (!deprecratedStore.getters['progress/hasActiveProgressStep']) {
    return
  }

  await completeProgressAnimation()

  await deprecratedStore.dispatch(
    'progress/updateCurrentProgressStepStatus',
    PROGRESS_STEP_STATUS.COMPLETED
  )
  if (_getNextProgressStep({ store: deprecratedStore }) > 0) {
    await _startProgressStep({
      stepIdx: _getNextProgressStep({ store: deprecratedStore }),
      store: deprecratedStore
    })
  }
}

function _createHookAnimationCompletionTimer({ duration, store }) {
  const animationTimer = setTimeout(() => {
    completeProgressStep({ store })
  }, duration)
  store.dispatch('progress/startHookAnimationTimer', animationTimer)
}

export async function resetProgressToAuthentication() {
  const progressSteps = deprecratedStore.state.progress.progressFlow.steps

  const newProgressSteps = progressSteps.map((step) => {
    return {
      ...step,
      status:
        step.type === PROGRESS_STEP_TYPES.CONNECTION
          ? PROGRESS_STEP_STATUS.COMPLETED
          : PROGRESS_STEP_STATUS.NOT_STARTED
    }
  })

  await deprecratedStore.dispatch(
    'progress/updateProgressFlowSteps',
    newProgressSteps
  )

  await deprecratedStore.dispatch(
    'progress/updateCurrentProgressStepIdx',
    _getProgressAuthenticationStep({ store: deprecratedStore })
  )
  await deprecratedStore.dispatch('progress/updateCurrentProgressStep')
}

function _getProgressAuthenticationStep({ store }) {
  const progressSteps = store.state.progress.progressFlow.steps
  return progressSteps.findIndex(
    (step) => step.type === PROGRESS_STEP_TYPES.AUTHENTICATION
  )
}

function _getNextProgressStep({ store }) {
  const progressSteps = store.state.progress.progressFlow.steps
  return progressSteps.findIndex(
    (step) => step.status === PROGRESS_STEP_STATUS.NOT_STARTED
  )
}

function _setHookAnimationDuration({ currentStep, store }) {
  const EXTRA_SLOW_FILL_DURATION = 90000
  const taskDuration =
    store?.state?.company?.activeConnector?.taskAuthenticationDurationStatistics
      ?.percentiles[95].milliseconds ?? 60000
  return currentStep.duration ?? taskDuration + EXTRA_SLOW_FILL_DURATION
}
