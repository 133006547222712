import { ATOMIC_BRANDING } from '@/util/constants'
import { cloneDeep, get, merge, set } from 'lodash-es'
import { loadFonts } from '@/util/fonts'

const state = {
  defaults: {
    buttons: {
      borderRadius: ATOMIC_BRANDING.BORDER_RADIUS
    },
    colors: {
      brand: ATOMIC_BRANDING.BRAND_COLOR,
      overlay: ATOMIC_BRANDING.OVERLAY_COLOR,
      links: ATOMIC_BRANDING.LINK_COLOR
    },
    customCSS: undefined,
    dark: false,
    display: undefined,
    fonts: {
      headings: {
        fontFamily: ATOMIC_BRANDING.DEFAULT_FONT
      },
      buttons: {
        fontFamily: ATOMIC_BRANDING.DEFAULT_FONT
      },
      body: {
        fontFamily: ATOMIC_BRANDING.DEFAULT_FONT
      }
    },
    icons: {
      arrowRight: 'icon-arrow-right',
      bankBuilding: 'icon-bank-gray',
      chevronRight: 'icon-chevron-right',
      depositAccess: 'access/icon-deposit',
      identifyAccess: 'access/icon-identify',
      verifyAccess: 'access/icon-verify',
      noDepositAccess: 'noaccess/icon-deposit',
      noIdentifyAccess: 'noaccess/icon-identify',
      noVerifyAccess: 'noaccess/icon-verify',
      inputSearch: 'icon-search',
      keypadDelete: 'icon-delete',
      paylinkCard: 'pay-link/icon-card',
      paylinkBank: 'pay-link/icon-bank',
      secureLock: 'icon-lock',
      secureShield: 'icon-shield',
      simpleCheck: 'icon-simple-check',
      simpleCheckGreen: 'icon-simple-check-green',
      simpleCheckGreenThin: 'icon-simple-check-green-thin',
      threeDots: 'dots',
      timesRed: 'icon-times-red',
      paperCheck: 'icon-paper-check',
      dollarBill: 'icon-dollar-bill',
      percentage: 'icon-percentage',
      condensedDistributionPaperCheck:
        'condensed-distribution-experiment/icon-paper-check',
      condensedDistributionDollarBill:
        'condensed-distribution-experiment/icon-dollar-bill',
      condensedDistributionPercentage:
        'condensed-distribution-experiment/icon-percentage',
      condensedDistributionPaperCheckActive:
        'condensed-distribution-experiment/icon-paper-check-active',
      condensedDistributionDollarBillActive:
        'condensed-distribution-experiment/icon-dollar-bill-active',
      condensedDistributionPercentageActive:
        'condensed-distribution-experiment/icon-percentage-active'
    },
    poweredByAtomic: false,
    navigationOptions: {
      showBackButton: true,
      showBackButtonText: false,
      showCloseButton: true
    }
  },
  custom: {}
}

const getters = {
  theme(state) {
    const defaults = cloneDeep(state.defaults)
    const custom = cloneDeep(state.custom)

    return merge(defaults, custom)
  },
  customerBrandColor: (_, getters) => {
    return getters.theme.colors.brand
  },
  overlayColor: (_, getters) => {
    return getters.theme.colors.overlay
  },
  linkColor: (_, getters) => {
    return getters.theme.colors.links
  },
  overrideColorDefaults: (_, getters) => {
    return getters.theme.colors.overrideDefaults
  },
  useDarkMode: (_, getters) => {
    return getters.theme.dark
  },
  useInlineLayout: (_, getters) => {
    return getters.theme.display === 'inline'
  },
  showBackButton: (_, getters) => {
    return getters.theme.navigationOptions.showBackButton
  },
  showBackButtonText: (_, getters) => {
    return getters.theme.navigationOptions.showBackButtonText
  },
  showCloseButton: (_, getters) => {
    return getters.theme.navigationOptions.showCloseButton
  },
  poweredByAtomic: (_, getters) => {
    return getters.theme.poweredByAtomic
  },
  getDefaultIcon: (state, getters) => (path) => {
    const useDarkMode = getters.useDarkMode
    const iconFilePath = get(state, path)

    return `/img/${useDarkMode ? 'darkMode/' : ''}${iconFilePath}.svg`
  },
  getIcon: (_, getters) => (path) => {
    const useDarkMode = getters.useDarkMode

    const iconFilePath = get(getters.theme.icons, path)
    const isCustomIcon = iconFilePath?.startsWith('https')

    return isCustomIcon
      ? iconFilePath
      : `/img/${useDarkMode ? 'darkMode/' : ''}${iconFilePath}.svg`
  }
}

const actions = {
  updateDarkMode({ commit }, value) {
    commit('updateDarkMode', value)

    const el = window?.document.body
    value ? el.classList.add('dark') : el.classList.remove('dark')
  },
  updateTheme({ commit, state }, theme) {
    commit('setTheme', theme)

    if (theme?.fonts && Object.keys(theme?.fonts).length > 0) {
      const customFonts = state.custom?.fonts

      const fontsToLoad = Object.keys(customFonts).map((key) => {
        return {
          name: customFonts[key].fontFamily,
          area: key.toUpperCase()
        }
      })

      loadFonts(fontsToLoad)
    }
  },
  updateCustomization({ commit }, update) {
    const isResettingToDefaultIcon =
      update?.path?.startsWith('icons.') &&
      update?.value?.startsWith('data:image/')

    if (isResettingToDefaultIcon) {
      commit('setValue', {
        ...update,
        value: undefined
      })
    } else {
      commit('setValue', update)
    }

    const isFontCustomization = update?.path?.startsWith('fonts.')

    if (isFontCustomization) {
      const fontsToLoad = [
        {
          name: update.value,
          area: update.path.split('.')[1].toUpperCase() //get headings|body|buttons
        }
      ]

      loadFonts(fontsToLoad)
    }
  }
}

const mutations = {
  updateDarkMode: (state, value) => {
    state.custom.dark = value
  },
  setTheme: (state, theme) => {
    state.custom = theme
  },
  setValue: (state, { path, value }) => {
    set(state.custom, path, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
