import { invoke } from '@/util/native/invoke'
import { features } from '@/util/native/methods/features'
import { triggerOld } from '@/util/native/trigger-old'
import * as events from '@/util/native/events'
import { USER_DEVICE_AUTOMATION_EVENTS } from '@/util/constants'

export async function navigate({ url }) {
  const { NAVIGATE } = await features()

  if (!NAVIGATE) {
    await triggerOld({
      type: USER_DEVICE_AUTOMATION_EVENTS.EVALUATE_ON_DEVICE,
      payload: {
        stringifiedFunctionToEvaluate: `async () => { setTimeout(() => window.location.replace("${url}"), 500) }`,
        functionParameters: {},
        script: `return (async () => { setTimeout(() => window.location.replace("${url}"), 500) })()`
      }
    })
  } else {
    await invoke({
      type: events.NAVIGATE,
      payload: { url }
    })
  }
}
