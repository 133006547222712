import { cloneDeep, merge, set } from 'lodash-es'

const state = {
  language: window?.atomicStorage?.getItem('language') || 'en',
  defaults: {},
  custom: {}
}

const actions = {
  updateDefaultPhrases({ commit }, defaultPhrases) {
    commit('setDefaultPhrases', defaultPhrases)
  },
  updateCustomerPhrases({ commit }, customerPhrases) {
    commit('setCustomerPhrases', customerPhrases)
  },
  updateCustomization({ commit }, update) {
    commit('setCustomerPhrase', update)
  },
  updateLanguage({ commit }, language) {
    commit('setLanguage', language)
  }
}

const mutations = {
  setDefaultPhrases: (state, defaults) => {
    state.defaults = defaults
  },
  setCustomerPhrases: (state, customerPhrases) => {
    state.custom = customerPhrases
  },
  setCustomerPhrase: (state, update) => {
    set(state.custom, update.path, update.value)
  },
  setLanguage: (state, language) => {
    state.language = language
  }
}

const getters = {
  langAttribute(state, getters, rootState) {
    // For A11Y purposes, we need to set the lang attribute for when the language is not English, but the custom messaging coming from the backend are
    return rootState.messaging.customFailureTitle ||
      rootState.messaging.customFailureMessage
      ? 'en'
      : state.language
  },
  phrases(state) {
    const defaultPhrases = cloneDeep(state.defaults)
    const customerPhrases = cloneDeep(state.custom)

    return merge(defaultPhrases, customerPhrases)[state.language]
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
