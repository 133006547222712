import App from './App.vue'
import router from '@/router'
import Analytics from '@/plugins/analytics'
import Sentry from '@/plugins/sentry'
import { store } from '@/store'
import { createApp } from 'vue'
import { initFilters } from '@/util/filters'
import StoreFocusedElement from '@/directives/store-focused-element'
import * as CONSTANTS from '@/util/constants'
import i18n from '@/mixins/i18n'
import { authenticationService } from '@/machines/authentication'
import { inspect } from '@xstate/inspect'
// import VueHotjar from 'vue-hotjar-next'
import FocusTrap from '@/directives/focus-trap'

const app = createApp(App)
initFilters(app)
app.mixin(i18n)
Analytics.install(app)

if (import.meta.env.PROD) {
  Sentry.install(app, router)
}

app.config.globalProperties.$CONSTANTS = CONSTANTS

app.use(store)
app.use(router)

if (import.meta.env.VITE_APP_XSTATE_DEBUG === 'true' && import.meta.env.DEV) {
  inspect({
    iframe: false
  })
}

authenticationService.start()

app.config.globalProperties.$directives = {
  storeFocusedElement: StoreFocusedElement
}
app.use(StoreFocusedElement)
app.use(FocusTrap)

// app.use(VueHotjar, {
//   id: +import.meta.env.VITE_APP_HOTJAR_ID,
//   isProduction: import.meta.env.PROD,
//   snippetVersion: 6
// })

app.mount('#app')
