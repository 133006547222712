import { DISTRIBUTION_TYPES, TASK_FAIL_REASON, ROUTES } from '@/util/constants'
import { getTextPhrase } from '@/util/i18n'
import {
  navigateToAccountOrSearch,
  navigateToDeeplink
} from '@/util/navigation'
import { store as deprecratedStore } from '@/store'

export function processTasks(tasks) {
  tasks.map((task) => {
    task.amount = _getTaskAmount(task)
  })

  return tasks
}

export function determineTaskHistoryHeaderContent({ store }) {
  return {
    title: store.getters['i18n/phrases'].taskHistory.title,
    subtitle: store.getters['i18n/phrases'].taskHistory.subtitle
  }
}

export function determineNextPageFromTaskHistory({ store, router }) {
  return store.getters['user/userHasMultipleAccounts']
    ? router.push({ name: ROUTES.ACCOUNT })
    : store.state.main?.deeplink?.step
    ? navigateToDeeplink({ store, router })
    : navigateToAccountOrSearch({ store, router })
}

export function userFriendlyFailReasonText({ failReason, store }) {
  switch (failReason) {
    case TASK_FAIL_REASON.BAD_CREDENTIALS:
      return store.getters['i18n/phrases'].taskHistory.badCredentials
    case TASK_FAIL_REASON.CONNECTION_ERROR:
    case TASK_FAIL_REASON.DEVICE_DISCONNECTED:
    case TASK_FAIL_REASON.SESSION_TIMEOUT:
    case TASK_FAIL_REASON.DISTRIBUTION_NOT_SUPPORTED:
    case TASK_FAIL_REASON.SYSTEM_UNAVAILABLE:
      return store.getters['i18n/phrases'].taskHistory.updateFailed
    case TASK_FAIL_REASON.UNKNOWN_FAILURE:
    default:
      return store.getters['i18n/phrases'].taskHistory.unknownError
  }
}

export function failReasonWithManualFallback(failReason) {
  const taskFailReasons = [
    TASK_FAIL_REASON.ACCOUNT_LOCKOUT,
    TASK_FAIL_REASON.ACCOUNT_SETUP_INCOMPLETE,
    TASK_FAIL_REASON.ACCOUNT_UNUSABLE,
    TASK_FAIL_REASON.ENROLLED_IN_PAYCARD,
    TASK_FAIL_REASON.PRODUCT_NOT_SUPPORTED,
    TASK_FAIL_REASON.WORK_STATUS_TERMINATED,
    TASK_FAIL_REASON.ROUTING_NUMBER_NOT_SUPPORTED
  ]

  return taskFailReasons.includes(failReason)
}

function _getTaskAmount(task) {
  if (task.deposit.distributionType === DISTRIBUTION_TYPES.FIXED) {
    return `$${task.deposit.distributionAmount} of paycheck`
  } else if (task.deposit.distributionType === DISTRIBUTION_TYPES.PERCENT) {
    return `${task.deposit.distributionAmount}% of paycheck`
  } else if (task.deposit.distributionType === DISTRIBUTION_TYPES.TOTAL) {
    return getTextPhrase({
      key: 'entirePaycheck',
      namespace: 'taskHistory',
      store: deprecratedStore
    })
  } else {
    return `?`
  }
}
