export const interstitial = {
  addCardTitle: ({ customerName, companyName }) =>
    `Securely link your ${customerName} card to ${companyName}`,
  addCardDescription:
    'Scan, enter, or add a card via your mobile device wallet.',
  paymentDescription: 'After you sign in, your payment will be linked.',
  uplink: {
    title: ({ name }) => `Sign in to ${name} to connect your account`,
    description: `Once signed in, we'll start connecting your account.`,
    continue: ({ name }) => `Sign in on ${name}`
  }
}
