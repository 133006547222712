import Analytics from '@/plugins/analytics'
import {
  DEEPLINK_STEPS,
  SDK_EVENT_TYPES,
  TASK_WORKFLOW_VIEW,
  USER_COMPANY_SELECTION_STATUS
} from '@/util/constants'
import Ably from '@/plugins/ably'
import { emitSdkEvent } from '@/util/sdk'
import { last } from 'lodash-es'
import { appClose } from '@/util/app-event'

export function listenForNativeAppEvents({
  store,
  router,
  payLinkService,
  taskService
}) {
  document.body.addEventListener(
    'sdk-native-event',
    ({ detail: { name, payload } }) => {
      switch (name) {
        case 'sdk-back-button':
          return _sdkBackButtonClicked({ store, router, payload })
        case 'sdk-log':
          return _receivedSDKLog({ store, payload })
        case 'sdk-error':
          return _receivedSDKError({ store, payload })
        case 'sdk-did-enter-background':
          return _sdkEnteredBackground({ store, taskService })
        case 'sdk-did-enter-foreground':
          return _sdkEnteredForeground({ store, payLinkService })
        case 'sdk-card-was-added':
          return _sdkCardWasAdded({ store })
      }
    }
  )

  if (store.state.main.deeplink?.step !== DEEPLINK_STEPS.ADD_CARD) {
    const channel = Ably.get().channels.get(
      `${store.state.user.userData._id}:sessions`
    )
    channel.subscribe('card-added', () => {
      emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
        type: 'card-added'
      })
    })
  }
}

function _receivedSDKLog({ payload }) {
  Analytics.get().track({
    event: 'Native SDK Log',
    payload,
    internal: true
  })
}

function _receivedSDKError({ payload }) {
  Analytics.get().track({
    event: 'Native SDK Error',
    payload,
    internal: true
  })
}

function _sdkEnteredBackground({ store, taskService }) {
  Analytics.get().track({
    event: 'Native SDK Entered Background',
    internal: true
  })

  if (store.getters['taskWorkflow/taskCouldFailIfUserLeaves']) {
    taskService.postUserEvent({ type: 'sdk-did-enter-background' })
  }
}

function _sdkEnteredForeground({ store, payLinkService }) {
  Analytics.get().track({
    event: 'Native SDK Entered Foreground',
    internal: true
  })

  if (store.getters['main/isScopePayLink']) {
    /**
     * We only care about refreshing selections if the user left while tasks
     * were processing.
     */
    const shouldRefreshSelections = store.state.payLink.selections.some(
      (selection) =>
        [
          USER_COMPANY_SELECTION_STATUS.IN_PROGRESS,
          USER_COMPANY_SELECTION_STATUS.REQUIRES_USER_INPUT
        ].includes(selection.status)
    )

    if (shouldRefreshSelections) {
      payLinkService.refreshSelections()
    }
  }
}

async function _sdkCardWasAdded({ store }) {
  Analytics.get().track({
    event: 'Native SDK Card Was Added',
    internal: true
  })

  await store.dispatch('payLink/updateIsRefreshingPaymentMethods', true)

  if (
    store.state.taskWorkflow.taskWorkflowState.view ===
    TASK_WORKFLOW_VIEW.INTERSTITIAL.ADD_CARD
  ) {
    await store.dispatch('taskWorkflow/resetTaskWorkflowState', {
      initialView: TASK_WORKFLOW_VIEW.INTERSTITIAL.PAY_LINK_PRE_LOGIN
    })
  }

  await store.dispatch('user/fetchUser')

  await store.commit(
    'payLink/setPaymentMethod',
    last(store.getters['user/cardPaymentMethods'])
  )

  await store.dispatch('payLink/updateIsRefreshingPaymentMethods', false)
}

function _sdkBackButtonClicked({ store, router, payload }) {
  const url = new URL(payload.currentURL)

  const entryRoutesPaths = router
    .getRoutes()
    .filter((route) => route.meta.entryRoute)
    .map((route) => route.path)

  if (entryRoutesPaths.includes(url.pathname)) {
    appClose({ store, eventType: SDK_EVENT_TYPES.CLOSE })
  }
}
