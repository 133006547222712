export const walmartManual = {
  title: 'Set up your Walmart direct deposit',
  step1: {
    title: 'Visit this link:',
    link: 'https://hello.one.app/setup-dd'
  },
  step2: {
    title: 'Sign in to your Walmart Associate account.'
  },
  step3: {
    title: 'Select One as your direct deposit'
  },
  showAccountNumbers: 'Show account numbers'
}
