<template>
  <div class="logos mb-2">
    <Logo
      v-for="logo in logos"
      :key="logo.key"
      :imgSrc="logo.imgSrc"
      :backgroundColor="logo.backgroundColor"
      :size="48"
      :large="false"
      :borderRadius="100"
      class="logo"
    />
  </div>
  <div class="text-center mb-3" :lang="langAttribute">
    <h2 id="modalLabel">{{ title }}</h2>
    <p>{{ message }}</p>
  </div>
  <div class="action-list">
    <BaseButton
      @handleClick="handleClickTryWithManagingCompany"
      :btn-style="ATOMIC_BRANDING.BUTTON_STYLE_SOLID"
      data-test-id="tryWithManagingCompany"
      :text="
        phrases.login.signInToTitle({
          name: managingCompany.name
        })
      "
    />
    <TextButton
      @handleClick="handleClickClose"
      :text="phrases.shared.close"
      data-test-id="close"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import Logo from '@/components/Shared/LogoWrap.vue'
import { mapGetters } from 'vuex'
import { PAGES, ATOMIC_BRANDING } from '@/util/constants'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'
import { getBrandingUrl, getLogoBackgroundColor } from '@/util/branding'

export default {
  name: 'SelectionDetailModalContentManagingCompanyFound',
  components: {
    BaseButton,
    Logo,
    TextButton
  },
  emits: ['close', 'try-with-managing-company'],
  props: {
    selection: Object,
    switchTask: Object
  },
  methods: {
    handleClickTryWithManagingCompany() {
      this.$analytics.track({
        event: `Clicked Try With Managing Company From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection, {
          managingCompany: this.managingCompany.name
        })
      })
      this.$emit('try-with-managing-company', this.managingCompany)
    },
    handleClickClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    ...mapGetters('i18n', ['langAttribute']),
    ...mapGetters('payLink', ['companySelectionStatus']),
    managingCompany() {
      return this.switchTask.managedBy.company
    },
    logos() {
      return [
        {
          key: this.managingCompany._id,
          imgSrc: getBrandingUrl(this.managingCompany),
          backgroundColor: getLogoBackgroundColor(this.managingCompany)
        },
        {
          key: this.selection.company._id,
          imgSrc: getBrandingUrl(this.selection.company),
          backgroundColor: getLogoBackgroundColor(this.selection.company)
        }
      ]
    },
    title() {
      return this.phrases.detail.managedByCompany.title({
        companyName: this.selection.company.name,
        managingCompanyName: this.managingCompany.name
      })
    },
    message() {
      return this.phrases.detail.managedByCompany.message({
        managingCompanyName: this.managingCompany.name
      })
    }
  },
  created() {
    this.ATOMIC_BRANDING = ATOMIC_BRANDING
  }
}
</script>

<style lang="scss" scoped>
.logos {
  display: flex;
  justify-content: center;
}

.logo {
  box-sizing: content-box;
  border: 4px solid var(--white) !important;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .dark & {
    border-color: var(--gray-900) !important;
    box-shadow: 0px 10px 12px -4px rgba(16, 24, 40, 0.8),
      0px 4px 4px -2px rgba(16, 24, 40, 0.03);
  }
}

.logo + .logo {
  margin-left: -12px;
}
</style>
