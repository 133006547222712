import { AUTHENTICATION_METHODS } from '@/util/constants'

const state = {
  // authentication method and entity combinations that have failed authentication
  // we can use this Set to filter out options for the user
  // entities can be connectors, companies, or authenticators
  attemptedAuthentications: new Set(),
  hasSeenSocialSecurityInterstitialWithTrueAuth: false
}

const mutations = {
  addAttemptedAuthentication(state, value) {
    state.attemptedAuthentications.add(value)
  },
  setHasSeenSocialSecurityInterstitialWithTrueAuth(state, value) {
    state.hasSeenSocialSecurityInterstitialWithTrueAuth = value
  }
}

const getters = {
  hasAttemptedCoAuthWithEntity(state) {
    return (entity) =>
      state.attemptedAuthentications.has(
        `${AUTHENTICATION_METHODS.CO_AUTH}:${entity}`
      )
  },
  hasAttemptedSmartAuthWithEntity(state) {
    return (entity) =>
      state.attemptedAuthentications.has(
        `${AUTHENTICATION_METHODS.SMART_AUTH}:${entity}`
      )
  },
  hasAttemptedStandardAuthWithEntity(state) {
    return (entity) =>
      state.attemptedAuthentications.has(
        `${AUTHENTICATION_METHODS.STANDARD_AUTH}:${entity}`
      )
  },
  hasAttemptedTrueAuthWithEntity(state) {
    return (entity) =>
      state.attemptedAuthentications.has(
        `${AUTHENTICATION_METHODS.UPLINK}:${entity}`
      )
  },
  hasSeenSocialSecurityInterstitialWithTrueAuth(state) {
    return state.hasSeenSocialSecurityInterstitialWithTrueAuth
  }
}

const actions = {
  addAttemptedCoAuthAuthentication({ state }, entity) {
    state.attemptedAuthentications.add(
      `${AUTHENTICATION_METHODS.CO_AUTH}:${entity}`
    )
  },
  addAttemptedSmartAuthAuthentication({ state }, entity) {
    state.attemptedAuthentications.add(
      `${AUTHENTICATION_METHODS.SMART_AUTH}:${entity}`
    )
  },
  addAttemptedStandardAuthAuthentication({ state }, entity) {
    state.attemptedAuthentications.add(
      `${AUTHENTICATION_METHODS.STANDARD_AUTH}:${entity}`
    )
  },
  addAttemptedTrueAuthAuthentication({ state }, entity) {
    state.attemptedAuthentications.add(
      `${AUTHENTICATION_METHODS.UPLINK}:${entity}`
    )
  },
  updateHasSeenSocialSecurityInterstitialWithTrueAuth({ commit }, value) {
    commit('setHasSeenSocialSecurityInterstitialWithTrueAuth', value)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
