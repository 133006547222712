export const findCredentialsHelpModal = {
  title: 'Cómo encontrar sus credenciales',
  description:
    'Suproveedor de nómina es donde normalmente gestionas cosas como sus beneficios e información laboral.',
  step1: {
    title: 'Intenta recuperar su cuenta',
    description:
      'La mayoría de los sistemas de nómina le permiten buscar su cuenta o restablecer su contraseña.'
  },
  step2: {
    title: 'Revisa su correo personal/laboral',
    description:
      'Es probable que su empleador le haya enviado un correo de invitación al sistema de nómina. Este correo contiene detalles sobre cómo acceder a su cuenta.'
  },
  step3: {
    title: 'Pide ayuda a su empleador',
    description:
      'El departamento de recursos humanos o el gerente de nómina de su empleador podrá ayudarle a obtener acceso.'
  }
}
