import { makeRequest } from '@/util/requests'
import { createPayLinkService } from './pay-link'
import { createTaskWorkflowOrchestrator } from './task-workflow-orchestrator'
import { TaskService } from './task-service'

export const PayLinkService = createPayLinkService({ makeRequest })
export const TaskWorkflowOrchestrator = createTaskWorkflowOrchestrator()
/**
 * We're wanting to refactor our front-end services to be classes.
 * TODO: Refactor other services to be classes.
 */
export const taskService = new TaskService({ makeRequest })
