export const welcome = {
  present: {
    title: ({ customerName }) => `Conecta sus pagos a ${customerName}`,
    subtitle: `Te ayudaremos a conectar rápidamente tus cuentas para desbloquear información de transacciones detallada y gestión de facturas simplificada.`,
    button: 'Empieza'
  },
  switch: {
    title: ({ customerName }) => `Conecta sus pagos a ${customerName}`,
    subtitle: `Le aydaremos a actualizar los métodos de pago de sus pagos recurrente.`,
    button: 'Empieza'
  }
}
