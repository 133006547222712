export const sparkDriverManual = {
  title: 'Set up Spark direct deposit',
  step1: {
    title: 'Visit this link:',
    link: 'https://my.ddiwork.com/one/signup'
  },
  step2: {
    title: 'Sign in to your Spark Driver™ account.'
  },
  step3: {
    title: 'Select One as your Spark Driver™ earning method.'
  },
  showAccountNumbers: 'Show account numbers'
}
