<template>
  <div class="skeleton-template">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SkeletonTemplate'
}
</script>

<style lang="scss" scoped>
.skeleton-template {
  height: 100%;
  padding: calc(env(safe-area-inset-top) + 24px) 24px
    unquote('max(24px, env(safe-area-inset-bottom))');
  display: flex;
  flex-direction: column;
}
</style>
