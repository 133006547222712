import { cloneDeep, set } from 'lodash-es'
import {
  authenticationService,
  AUTHENTICATION_EVENTS
} from '@/machines/authentication'
import { PAGE_TRANSITIONS } from '@/util/constants'

const state = {
  flow: { steps: [] },
  currentStep: undefined,
  currentStepIdx: 0,
  visitedStepIdxs: [],
  inputTransition: PAGE_TRANSITIONS.SLIDE_LEFT,
  formData: {},
  templatedFormData: {},
  dataDestination: 'server'
}

const mutations = {
  setFlow: (state, flow) => {
    state.flow = flow
  },
  setFormData: (state, formData) => {
    state.formData = formData
  },
  setTemplatedFormData: (state, templatedFormData) => {
    state.templatedFormData = templatedFormData
  },
  setCurrentStep: (state, currentStep) => {
    state.currentStep = currentStep
  },
  setCurrentStepIdx: (state, currentStepIdx) => {
    state.currentStepIdx = currentStepIdx
  },
  setInputTransition: (state, transition) => {
    state.inputTransition = transition
  },
  addVisitedIdx: (state, idx) => {
    state.visitedStepIdxs.push(idx)
  },
  popVisitedIdx: (state) => {
    state.visitedStepIdxs.pop()
  },
  setDataDestination: (state, destination) => {
    state.dataDestination = destination
  }
}

const getters = {
  lastVisitedIdx(state) {
    return state.visitedStepIdxs[state.visitedStepIdxs.length - 1]
  }
}

const actions = {
  updateFlow({ commit, dispatch }, flow) {
    dispatch('resetFormFlow')
    commit('setFlow', flow)
    dispatch('moveForwardCurrentStep')
  },
  addFormData({ commit, state }, { key, data }) {
    commit('setFormData', set(cloneDeep(state.formData), key, data))
  },
  addTemplatedFormData({ commit, state }, { key, data }) {
    commit(
      'setTemplatedFormData',
      set(cloneDeep(state.templatedFormData), key, data)
    )
  },
  updateCurrentStepIdx({ state, commit, dispatch }, currentStepIdx) {
    commit('addVisitedIdx', state.currentStepIdx)
    commit('setCurrentStepIdx', currentStepIdx)
    if (currentStepIdx === 1) {
      dispatch('company/monitorCompanyConversion', undefined, {
        root: true
      })
    }
  },
  updateCurrentStep({ commit, state }) {
    commit('setCurrentStep', state.flow.steps[state.currentStepIdx])
  },
  updateDataDestination({ commit }, destination) {
    commit('setDataDestination', destination)
  },
  resetDataDestination({ commit }) {
    commit('setDataDestination', 'server')
  },
  moveForwardCurrentStep({ commit, dispatch }) {
    commit('setInputTransition', PAGE_TRANSITIONS.SLIDE_LEFT)
    dispatch('updateCurrentStep')
  },
  goBackToPreviousStep({ commit, getters, dispatch }) {
    commit('setCurrentStepIdx', getters.lastVisitedIdx)
    // necessary so that the state machine can understand when to delegate back logic to
    // form flow, the router, or internal
    authenticationService.send({
      type: AUTHENTICATION_EVENTS.STEP,
      stepIdx: getters.lastVisitedIdx
    })
    commit('popVisitedIdx')
    commit('setInputTransition', PAGE_TRANSITIONS.SLIDE_RIGHT)
    dispatch('updateCurrentStep')
  },
  currentStepHasError({ commit, state }) {
    commit('setCurrentStep', { ...state.currentStep, hasError: true })
  },
  resetFormFlow({ commit, rootState }, shouldResetFormData) {
    commit('setFlow', { steps: [] })
    if (
      !rootState.experiment.ldFlags.maintainCredentials ||
      shouldResetFormData
    )
      commit('setFormData', {})
    commit('setCurrentStepIdx', 0)
    authenticationService.send(AUTHENTICATION_EVENTS.RESET_STEP)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
