<template>
  <SkeletonTemplate class="d-flex">
    <div class="flex-1 d-flex fd-column">
      <!-- FocusHeading -->
      <SkeletonBlock
        shape="box"
        shapeWidth="84px"
        shapeHeight="24px"
        class="mb-4"
        :class="{ 'mt-4': hasActionBar }"
      />

      <!-- Card details title -->
      <SkeletonBlock
        shape="box"
        shapeWidth="84px"
        shapeHeight="24px"
        class="mb-1"
      />

      <!-- Card details subtitle -->
      <SkeletonBlock
        shape="box"
        shapeWidth="200px"
        shapeHeight="20px"
        class="mb-2"
      />

      <!-- Card fields -->
      <div class="card-fields mb-4">
        <SkeletonBlock shape="box" class="row" />
        <SkeletonBlock shape="box" />
        <SkeletonBlock shape="box" />
      </div>

      <!-- Secure badge -->
      <SkeletonBlock
        shape="box"
        shapeWidth="120px"
        shapeHeight="40px"
        class="mt-2 mx-auto"
      />
    </div>

    <!-- Save button -->
    <SkeletonBlock shape="box" shapeHeight="56px" class="save-button" />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AddCardSkeleton',
  components: { SkeletonBlock, SkeletonTemplate },
  computed: {
    ...mapGetters('main', ['isIOSPlatform']),
    hasActionBar() {
      return !this.isIOSPlatform
    }
  }
}
</script>

<style scoped>
.card-fields {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-auto-rows: 56px;
  gap: calc(var(--marginBase) * 2);
}

.row {
  grid-column: 1 / -1;
}
</style>
