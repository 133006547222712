export const depositPreselectedCompany = {
  single: {
    title: ({ company }) =>
      `Sign in to ${company} to update your direct deposit`,
    description: ({ company }) =>
      `Based on your past income, it looks like your employer pays you using ${company}.`
  },
  multiple: {
    title: 'Sign in to update your direct deposit',
    description: ({ count }) =>
      `We've detected ${count} income sources based on your transaction history.`
  },
  continueOnFI: ({ name }) => `Continue on ${name}`,
  iDontUseFI: ({ name }) => `I don't use ${name}`,
  searchAllEmployers: 'Search all employers'
}
