import { store } from '@/store'

export async function detokenizeCreditCard(options) {
  const {
    data: { btToken, btSessionApiKey, pageHandle }
  } = options

  const res = await fetch(`https://api.basistheory.com/tokens/${btToken}`, {
    method: 'get',
    headers: {
      'BT-API-KEY': btSessionApiKey
    },
    redirect: 'follow'
  })

  const { data } = await res.json()

  const { number, cvc } = data

  store.dispatch('tempStorage/addCardData', {
    pageHandle,
    cardData: { number, cvc, cvv: cvc }
  })
}
