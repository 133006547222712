<template>
  <span class="area-right">
    <span v-if="text.right" class="text">{{ text.right }}</span>
    <IconArrowRight
      v-if="iconRight?.visible && iconRight.icon === 'arrow'"
      aria-hidden="true"
    />
    <IconChevronRight
      v-if="iconRight?.visible && iconRight.icon === 'chevron'"
      :size="16"
      :stroke="iconRight.strokeColor"
      aria-hidden="true"
    />
  </span>
</template>

<script>
import IconArrowRight from '@/components/Icons/IconArrowRight.vue'
import IconChevronRight from '@/components/Icons/Atomic/IconChevronRight.vue'

export default {
  name: 'BaseButtonRight',
  props: {
    text: [String, Object],
    iconRight: Object,
    fontSize: String
  },
  components: {
    IconArrowRight,
    IconChevronRight
  },
  computed: {
    buttonFontSize() {
      return this.fontSize || '1.6rem'
    }
  }
}
</script>

<style lang="scss" scoped>
.area-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.text {
  transition: opacity 100ms linear;
  font-family: var(--buttonFont);
  font-size: v-bind(buttonFontSize);
  font-weight: 500;
}
</style>
