<template>
  <div class="icon-card-customer">
    <img class="logo" :src="customer.branding.logo.url" :alt="customerName" />
  </div>
</template>

<script>
import { getGlobalCSSValue } from '@/util/general'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'IconCardCustomer',
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['customerName']),
    customer() {
      return this.userData.customer
    },
    background() {
      return (
        this.customer.branding.logo.backgroundColor ||
        this.customer.branding.color ||
        getGlobalCSSValue('--white')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles intended to match the look of our credit card svgs
.icon-card-customer {
  background: v-bind(background);
  border-radius: 4px;
  width: 48px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    flex: 1;
    max-width: 18px;
  }
}
</style>
