/**
 * Hack for making the iOS keyboard to show when we want it to on demand
 */
export function prepareKeyboardFocus() {
  // create invisible dummy input to receive the focus first
  const fakeInput = document.createElement('input')
  fakeInput.setAttribute('type', 'text')
  fakeInput.style.position = 'absolute'
  fakeInput.style.opacity = 0
  fakeInput.style.height = 0
  fakeInput.style.fontSize = '16px' // disable auto zoom
  fakeInput.setAttribute('readonly', true) // Do not open the keyboard for the fake input
  fakeInput.setAttribute('tabindex', -1) // Do not allow tabbing on fake input
  fakeInput.setAttribute('aria-hidden', true) // Do not allow screen readers to see fake input

  // you may need to append to another element depending on the browser's auto
  // zoom/scroll behavior
  document.querySelector('.app-content')?.prepend(fakeInput)

  // focus so that subsequent async focus will work
  fakeInput.focus()
  setTimeout(() => fakeInput.remove(), 4000)
}
