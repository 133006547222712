export class ApiError extends Error {
  constructor(message, response) {
    super(message)
    this.name = 'ApiError'
    this.response = {
      status: response?.status,
      headers: response?.headers,
      data: JSON.stringify(response?.data)
    }
  }
}
