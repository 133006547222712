import axios from 'axios'
import { store } from '@/store'
import sleep from 'await-sleep'
import { ERROR_CODES, MODAL_VIEW } from '@/util/constants'

export async function makeRequest({
  method,
  headers,
  endpoint,
  data,
  timeout,
  baseUrl
}) {
  const client = axios.create({
    baseURL: baseUrl || store.state.environment.apiEndpoint
  })

  client.retries = 0

  return _callApi(client, {
    method,
    headers,
    data,
    url: endpoint,
    timeout
  })
}

export async function handleAPIError({ errorCode, data }) {
  await store.dispatch('main/updateGlobalErrorStatus', {
    code: errorCode,
    description: data
  })
  await store.dispatch('modal/openModal', { view: MODAL_VIEW.ERROR })
}
async function _callApi(client, { method, headers, data, url, timeout }) {
  const publicToken = store.state.main.publicToken
  const platformName = store.getters['main/platformName']
  const platformSdkVersion = store.getters['main/platformSdkVersion']

  return client({
    method,
    data,
    url,
    timeout,
    headers: {
      ['content-type']: 'application/json',
      ...headers,
      ['x-public-token']: publicToken,
      ['x-platform-name']: platformName,
      ['x-platform-sdk-version']: platformSdkVersion
    }
  }).catch((err) => {
    return _handleError(client, err)
  })
}

async function _handleError(client, err) {
  await store.dispatch('task/clearAllTaskTimers')

  if (_isTimeoutError(err)) {
    await store.dispatch('main/updateGlobalErrorStatus', {
      code: ERROR_CODES.GLOBAL_TIMEOUT
    })

    await _triggerErrorModal()
  }

  // On a 401 response, show our unauthorized message overlay
  else if (err?.response?.status === 401) {
    await store.dispatch('main/updateGlobalErrorStatus', {
      code: err.response.data.code,
      description: err.response.data.message
    })
    await _triggerErrorModal()
  }

  // On these 500 error codes, retry three times in case of network malfunction
  // After the third retry, show the 500 error overlay
  else if (_isAny500Status(err?.response?.status)) {
    if (client.retries < 3) {
      client.retries++
      await sleep(1000)

      if (typeof err.config.data === 'string') {
        err.config.data = JSON.parse(err.config.data)
      }

      return _callApi(client, err.config)
    }

    await store.dispatch('main/updateGlobalErrorStatus', {
      code: ERROR_CODES.GLOBAL_50X
    })
    await _triggerErrorModal()
  }

  throw err
}

function _isTimeoutError(err) {
  return err.message && err.message.includes('timeout')
}

async function _triggerErrorModal() {
  await store.dispatch('modal/openModal', { view: MODAL_VIEW.ERROR })
}

function _isAny500Status(status) {
  const check500 = /^5[0-9].*$/
  return check500.test(status)
}
