import { prepareLogin } from '@/util/navigation'
import { generateTaskWorkflowBrandingLogos } from '@/util/task-workflow'
import { TASK_WORKFLOW_VIEW, ROUTES, REQUEST_TYPES } from '@/util/constants'

/**
 * These functions have a lot of dependencies and dispatch actions from other
 * Vuex modules. Keeping them separate to make it easier to test actual utils.
 */

export async function navigateToTaskInterrupt({ store, selection, router }) {
  store.dispatch('task/updateTaskPrompts', {
    sections: selection.userInputRequest.sections,
    requestType: REQUEST_TYPES.MFA
  })

  await _navigateToUserInputRequest({
    store,
    router,
    selection,
    initialView: TASK_WORKFLOW_VIEW.INTERRUPT
  })
}

export async function navigateToSmartAuthDataRequest({
  store,
  selection,
  router
}) {
  store.dispatch('smartAuth/updateRequestData', selection.userInputRequest)

  await _navigateToUserInputRequest({
    store,
    router,
    selection,
    initialView: TASK_WORKFLOW_VIEW.LOGIN
  })
}

async function _navigateToUserInputRequest({
  store,
  router,
  selection,
  initialView
}) {
  store.dispatch('messaging/resetCustomMessaging')
  store.dispatch('task/updateCurrentTaskId', {
    taskId: selection.userInputRequest.taskId
  })
  store.dispatch('taskWorkflow/updateTaskWorkflowId', selection.taskWorkflowId)
  store.dispatch(
    'formFlow/updateDataDestination',
    selection.userInputRequest.dataDestination || 'server'
  )

  await prepareLogin({
    companyId: selection.company._id,
    connectorId: selection.company.connector,
    store
  })

  await store.dispatch('experiment/fetchLdFeatureFlags', {
    companyId: store.getters['company/companyId']
  })

  generateTaskWorkflowBrandingLogos({ store })

  router.push({
    name: ROUTES.TASK,
    query: { view: initialView }
  })
}
