export const percentDeposit = {
  introduction: 'porcentaje',
  graphTitleText: 'Gráfico de Porcentaje',
  ariaDescription:
    ' de sus fondos está configurado para mover a su nueva cuenta',
  distributionAmountCurrentAccountLabel: ({ currentAmount }) =>
    `la cuenta corriente tiene el ${currentAmount}% de los fondos asignados`,
  distributionAmountNewAccountLabel: ({ amount }) =>
    `la nueva cuenta tiene el ${amount}% de los fondos asignados`
}
