export const legal = {
  acceptTermsAndConditions: 'Acepto la ',
  termsAndConditions: 'términos y condiciones',
  legalDisclaimerPretext: `A continuar, acepta la `,
  actionPrivacyPolicy: 'Política de privacidad',
  actionTerms: 'Términos',
  actionTermsOfUse: 'Términos y Condiciones.',
  termsConditionsSee: 'Ver',
  termsConditionsAnd: 'y'
}
