<template>
  <div class="icon-failed-wrapper mx-auto mb-3">
    <IconExclamation :size="24" :stroke="iconFailedStroke" aria-hidden="true" />
  </div>
  <div class="text-center mb-3" :lang="langAttribute">
    <h2>
      {{ failureMessaging.title }}
    </h2>
    <p>
      {{ failureMessaging.message }}
    </p>
  </div>
  <div class="action-list">
    <BaseButton
      v-for="(button, index) in buttons"
      :key="button.dataTestId"
      :btn-style="
        index === 0
          ? ATOMIC_BRANDING.BUTTON_STYLE_SOLID
          : ATOMIC_BRANDING.BUTTON_STYLE_HOLLOW
      "
      :customer-branded="index === 0"
      :data-test-id="button.dataTestId"
      :text="button.text"
      @handleClick="button.handleClick"
    />
    <TextButton
      @handleClick="handleClickClose"
      :text="phrases.shared.close"
      data-test-id="close"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import IconExclamation from '@/components/Icons/Atomic/IconExclamation.vue'
import { generateTaskFailureMessaging } from '@/util/messaging'
import { mapGetters } from 'vuex'
import { getGlobalCSSValue } from '@/util/general'
import { PAGES, TASK_FAIL_REASON, ATOMIC_BRANDING } from '@/util/constants'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'

export default {
  name: 'SelectionDetailModalContentFailed',
  components: {
    BaseButton,
    IconExclamation,
    TextButton
  },
  emits: ['close', 'remove-selection', 'try-again'],
  props: {
    selection: Object,
    task: Object
  },
  methods: {
    handleClickRemove() {
      this.$analytics.track({
        event: `Clicked Remove Selection From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection)
      })
      this.$emit('remove-selection')
    },
    handleClickTryAgain() {
      this.$analytics.track({
        event: `Clicked Try Again From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection)
      })
      this.$emit('try-again')
    },
    handleClickClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    ...mapGetters('i18n', ['langAttribute']),
    ...mapGetters('payLink', ['companySelectionStatus']),
    failureMessaging() {
      return generateTaskFailureMessaging({
        store: this.$store,
        failReason: this.task.failReason,
        customFailureTitle: this.task.customFailureTitle,
        customFailureMessage: this.task.customFailureMessage
      })
    },
    iconFailedStroke() {
      return getGlobalCSSValue(this.useDarkMode ? '--error-300' : '--error-500')
    },
    canTryAgain() {
      return (
        this.task.failReason !==
        TASK_FAIL_REASON.SUBSCRIPTION_MANAGED_BY_PARTNER_PROVIDER
      )
    },
    buttons() {
      return [
        ...(this.canTryAgain
          ? [
              {
                dataTestId: 'try-again',
                text: this.phrases.shared.tryAgain,
                handleClick: this.handleClickTryAgain
              }
            ]
          : []),
        {
          dataTestId: 'remove',
          text: this.phrases.shared.removeSelection({
            companyName: this.selection.company.name
          }),
          handleClick: this.handleClickRemove
        }
      ]
    }
  },
  created() {
    this.ATOMIC_BRANDING = ATOMIC_BRANDING
  }
}
</script>

<style lang="scss" scoped>
.icon-failed-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--error-100);

  .dark & {
    background: var(--error-700);
  }
}
</style>
