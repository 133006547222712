<template>
  <div class="progress-bar">
    <svg
      class="layer-1"
      width="88"
      height="5"
      viewBox="0 0 88 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 0H0V2.5V5H2.5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0Z"
        fill="white"
      />
      <path
        d="M21.5 0C22.8807 0 24 1.11929 24 2.5C24 3.88071 22.8807 5 21.5 5H34.5C33.1193 5 32 3.88071 32 2.5C32 1.11929 33.1193 0 34.5 0H21.5Z"
        fill="white"
      />
      <path
        d="M53.5 0C54.8807 0 56 1.11929 56 2.5C56 3.88071 54.8807 5 53.5 5H66.5C65.1193 5 64 3.88071 64 2.5C64 1.11929 65.1193 0 66.5 0H53.5Z"
        fill="white"
      />
      <path
        d="M85.5 0C86.8807 0 88 1.11929 88 2.5C88 3.88071 86.8807 5 85.5 5H88V2.5V0H85.5Z"
        fill="white"
      />
    </svg>
    <div class="layer-2"></div>
    <div class="layer-3"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ATOMIC_BRANDING } from '@/util/constants'

export default {
  name: 'ProgressBar',
  computed: {
    ...mapState('progressBar', ['completion']),
    ...mapState('taskWorkflow', ['taskWorkflowBranding']),
    ...mapState('theme', ['custom']),
    ...mapGetters('theme', ['useDarkMode']),
    progressBarColor() {
      return (
        this.taskWorkflowBranding?.foreground?.color ||
        this.custom?.colors?.brand ||
        ATOMIC_BRANDING.BRAND_COLOR
      )
    },
    widthPercent() {
      return `${this.completion}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  position: relative;
  width: 88px;
  height: 5px;
  border-radius: 3px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}
.layer-1 {
  position: absolute;
  z-index: 3;
}
.layer-2 {
  position: absolute;
  background: v-bind(progressBarColor);
  height: 100%;
  width: v-bind(widthPercent);
  z-index: 2;
  transition: all 500ms cubic-bezier(0.12, 0, 0.39, 0) 250ms;
}
.layer-3 {
  position: absolute;
  background: var(--gray-300);
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
