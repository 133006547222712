export async function receivedInterceptedRequest({ store, request }) {
  await store.dispatch(
    'tempStorage/mergeSpiedRequestHeaders',
    request.requestHeaders
  )
  await store.dispatch(
    'tempStorage/mergeSpiedResponseHeaders',
    request.responseHeaders
  )
  await store.dispatch(
    'tempStorage/mergeInterceptedData',
    request.interceptedData
  )
}
